import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { PaymentContext } from "./PaymentContext";
import { API, Hub, graphqlOperation } from "aws-amplify";
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import { useLocation } from "react-router-dom";

// Define the GraphQL query outside of the component
const payments3sByUserID = /* GraphQL */ `
  query Payments3sByUserID($userID: String!) {
    payments3sByUserID(userID: $userID) {
      items {
        stripeCustomerId
        customer
        virtualcoach
        coachingPlatform
        coachingPlatformTier
      }
    }
  }
`;

export const PaymentProvider = ({ children }) => {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const { checkClientInviteAllowance } = useCheckClientInviteAllowance();
  const { user } = useAuthenticator((context) => [context.user]);

  const fetchUserAttributes = async (userID) => {
    try {
      const userAttributesResponse = await API.get("zoomapi", "/zoom/fetch-user-attributes", {
        queryStringParameters: {
          user_id: userID
        }
      });

      if (!userAttributesResponse) {
        return;
      }

      if (userAttributesResponse.data.access_to === 'client_portal') {
        const isCoachActiveResponse = await API.get("zoomapi", "/zoom/fetch-client-coach-status", {
          queryStringParameters: {
            user_id: userID
          }
        });

        const coachStatuses = isCoachActiveResponse;
        const hasInactiveCoach = coachStatuses.some(coach => !coach.is_coach);
        setUserData(prevData => ({
          ...prevData,
          clientHasInactiveCoach: hasInactiveCoach
        }));
      }

      setUserData(prevData => ({
        ...prevData,
        accessTo: userAttributesResponse.data.access_to || null,
        isVirtualCoach: userAttributesResponse.data.is_virtual_coach || null,
        isClient: userAttributesResponse.data.is_client || null,
        isCoach: userAttributesResponse.data.is_coach || null,
        isProfessional: userAttributesResponse.data.is_professional || null,
        coachingTier: userAttributesResponse.data.coaching_tier || null,
      }));
      
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  };

  const fetchPayments = async (userID) => {
    try {
      fetchUserAttributes(userID);

      const paymentData = await API.graphql(
        graphqlOperation(payments3sByUserID, { userID })
      );

      const clientCounter = await checkClientInviteAllowance(
        userID
      );

      const payments = paymentData.data.payments3sByUserID.items;
      if (payments && payments.length > 0) {
        const payment = payments[0];
        setUserData(prevData => ({
          ...prevData,
          paymentInfo: {
            stripeCustomerId: payment.stripeCustomerId || null,
            allowAccessToPlatform: (payment.coachingPlatform && payment.customer) || null,
            allowAccessToBot: (payment.virtualcoach && payment.customer) || null,
            isCustomer: payment.customer || null,
            coachingPlatformTier: payment.coachingPlatformTier || null,
          },
          isProfessionalTier: payment.coachingPlatformTier === "personal" || null,
          isCoachLevel1: payment.coachingPlatformTier === "tier1" || null,
          isCoachLevel2: payment.coachingPlatformTier === "tier2" || null,
          isCoachLevel3: payment.coachingPlatformTier === "tier3" || null,
          isClientCountLimitReached: clientCounter ? clientCounter.clientCount > clientCounter.clientTierAllowance : false
        }));
      } else {
        setUserData(prevData => ({
          ...prevData,
          paymentInfo: {
            allowAccessToBot: false,
            allowAccessToPlatform: false,
          },
          isPersonalTier: false,
        }));
      }
    } catch (error) {
      console.log("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchPayments(user?.attributes?.sub);
    }
  }, [user]);

  useEffect(() => {
    if (user && (location.pathname === "/platform/coach" || location.pathname === "/platform/coachee")) {
      fetchPayments(user.attributes.sub);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    const hubListener = (data) => {
      if (data.payload.event === "signIn") {
        fetchPayments(data.payload.data.attributes.sub);
      } else if (data.payload.event === "signOut") {
        // Clear the context or set to default values
        setUserData({});
      }
    };

    const removeListener = Hub.listen("auth", hubListener);

    return () => {
      // Clean up the listener
      removeListener();
    };
  }, []);

  return (
    <PaymentContext.Provider value={{userData}}>
      {children}
    </PaymentContext.Provider>
  );
};
