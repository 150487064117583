import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Footer } from "scenes/login/Footer";
import { boxStyle, buttonStyle, buttonStyleLight } from "../styles/MUIStyles";
import { Button, CircularProgress } from "@mui/material";
import { makeStyles, ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { usePayment } from "../hooks/PaymentContext";
import { useStripeCheckoutVirtualCoach } from "hooks/useStripeCheckoutVirtualCoach";
import { useStripeCheckoutCoachingPlatform } from "hooks/useStripeCheckoutCoachingPlatform";
import { Theme } from "@fullcalendar/react";

const Products = () => {
  const navigate = useNavigate();
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  console.log("user client status ", user);
  // const { allowAccessToBot, allowAccessToPlatform, allowAccessToPlatformTier } = usePayment();
  const { allowAccessToBot, allowAccessToPlatform } = usePayment();

  // Hooks Virtual Coach
  const {
    handleStripeCheckoutVirtualCoach,
    setProductIdVirtualCoach,
    loadingStripeCheckoutVirtualCoach,
    errorWithStripeCheckoutVirtualCoach,
  } = useStripeCheckoutVirtualCoach();

  // Hooks Coaching Platform
  const {
    handleStripeCheckoutCoachingPlatform,
    setProductIdCoachingPlatform,
    loadingStripeCheckoutCoachingPlatform,
    errorWithStripeCheckoutCoachingPlatform,
  } = useStripeCheckoutCoachingPlatform();

  const useStyles = makeStyles((theme) => ({
    button: {
      ...buttonStyle,
      ...buttonStyleLight,
    },
    "@keyframes pulsate": {
      "0%": {
        boxShadow: "0 0 10px #FF1493",
      },
      "50%": {
        boxShadow: "0 0 20px #FF1493",
      },
      "100%": {
        boxShadow: "0 0 10px #FF1493",
      },
    },
  }));

  // const theme = createTheme({
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 600,
  //       md: 900,
  //       lg: 1096,
  //       xl: 1920,
  //     },
  //   },
  // });

  return (
    <>
      <Grid container spacing={1} justifyContent="space-evenly">
        <Box
          justifyContent="space-evenly"
          sx={{ zIndex: "30" }}
          display={{
            xs: "block",
            sm: "block",
            md: "flex",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                ...boxStyle,
                filter: `${
                  allowAccessToPlatform === true &&
                  authStatus === "authenticated"
                    ? "none"
                    : allowAccessToPlatform === false &&
                      authStatus === "authenticated"
                    ? "grayscale(1) opacity(0.5)"
                    : "none"
                }`,
                transition: "0.5s all ease-in-out",
                "&:hover": {
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "0.5s all ease-in-out",
                  filter: "none",
                },
              }}
            >
              <Typography
                color="black"
                fontSize="18px"
                fontWeight="600"
                textAlign="left"
              >
                Your Coaching Platform
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                gap="20px"
              >
                <Typography
                  color="rgba(82, 96, 119, 1)"
                  fontSize="16px"
                  textAlign="left"
                >
                  Innovative AI powered coaching platform to optimize your
                  <br />
                  performance
                </Typography>

                {allowAccessToPlatform === true &&
                authStatus === "authenticated" &&
                user?.attributes?.zoneinfo === "coachingplatform" ? (
                  <Button
                    sx={{ ...buttonStyle }}
                    onClick={() => {
                      if (user?.attributes?.["custom:is_client"] === "true") {
                        navigate("/platform/coachee");
                      } else {
                        navigate("/platform/coach");
                      }
                    }}
                  >
                    Enter Coaching Platform
                  </Button>
                ) : allowAccessToPlatform === false &&
                  authStatus === "authenticated" ? (
                  <Button
                    sx={buttonStyle}
                    onClick={() => navigate("/priceselect")}
                  >
                    Purchase Here
                  </Button>
                ) : (
                  <Button
                    sx={buttonStyle}
                    onClick={() => navigate("/priceselect")}
                  >
                    Sign Up Here
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                ...boxStyle,
                transition: "0.5s all ease-in-out",
                "&:hover": {
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "0.5s all ease-in-out",
                  filter: "none",
                },
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                color="black"
                fontSize="18px"
                fontWeight="600"
                textAlign="left"
              >
                Join Discord Community
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                gap="20px"
              >
                <Typography
                  color="rgba(82, 96, 119, 1)"
                  fontSize="16px"
                  textAlign="left"
                >
                  Get early release news on the <br /> latest updates on
                  features of our <br /> Beta
                </Typography>
                <Box
                  justifyContent="center"
                  display={{ xs: "none", lg: "flex" }}
                >
                  <a
                    href="https://discord.gg/6g4qbQbtbd"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "7px 15px",
                      background: "black",
                      textDecoration: "none",
                    }}
                  >
                    <Box width="25px" height="25px" marginRight="15px">
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 245 240"
                        fill="white"
                      >
                        <path
                          class="st0"
                          d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                        />
                        <path
                          class="st0"
                          d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                        />
                      </svg>
                    </Box>
                    <Typography fontFamily="Montserrat">
                      Join the Discord
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Grid container spacing={1} justifyContent="space-evenly">
        <Box
          justifyContent="space-evenly"
          sx={{ zIndex: "30" }}
          display={{
            xs: "block",
            sm: "block",
            md: "flex",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                ...boxStyle,
                filter: `${
                  allowAccessToBot === true &&
                  authStatus === "authenticated" &&
                  user?.attributes?.zoneinfo === "virtualcoach"
                    ? "none"
                    : allowAccessToBot === false &&
                      authStatus === "authenticated"
                    ? "grayscale(1) opacity(0.5)"
                    : "none"
                }`,
                transition: "0.5s all ease-in-out",
                "&:hover": {
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "0.5s all ease-in-out",
                  filter: "none",
                },
              }}
            >
              <Typography
                color="black"
                fontSize="18px"
                fontWeight="600"
                textAlign="left"
              >
                Virtual Coach
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                gap="20px"
                textAlign="left"
              >
                <Typography
                  color="rgba(82, 96, 119, 1)"
                  fontSize="16px"
                  // width={380}
                >
                  Cutting edge AI powered coach offering 24/7 mentorship,
                  <br /> tailored to each user
                </Typography>

                {allowAccessToBot === true &&
                authStatus === "authenticated" &&
                user?.attributes?.zoneinfo === "virtualcoach" ? (
                  <Button
                    sx={{ ...buttonStyle }}
                    onClick={() => navigate("/virtualcoach/bot")}
                  >
                    Jump in to Virtual Coach
                  </Button>
                ) : allowAccessToBot === false &&
                  authStatus === "authenticated" ? (
                  <Button
                    sx={{ ...buttonStyle }}
                    onClick={handleStripeCheckoutVirtualCoach}
                  >
                    {loadingStripeCheckoutVirtualCoach && (
                      <CircularProgress
                        size={20}
                        thickness={4}
                        sx={{ marginRight: "5px", marginBottom: "-3px" }}
                      />
                    )}
                    {loadingStripeCheckoutVirtualCoach
                      ? "Loading Checkout..."
                      : "Purchase Here"}
                  </Button>
                ) : (
                  <Button
                    sx={buttonStyle}
                    onClick={() => navigate("/signup?p=vc")}
                  >
                    Sign Up Here
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                ...boxStyle,
                transition: "0.5s all ease-in-out",
                "&:hover": {
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "0.5s all ease-in-out",
                  filter: "none",
                },
              }}
            >
              <Typography
                color="black"
                fontSize="18px"
                fontWeight="600"
                textAlign="left"
              >
                Refer a Coach
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                gap="20px"
              >
                <Typography
                  color="rgba(82, 96, 119, 1)"
                  fontSize="16px"
                  textAlign="left"
                >
                  Have someone in mind? <br /> Share it
                </Typography>

                <Button
                  sx={{
                    ...buttonStyleLight,
                  }}
                  onClick={() => ""}
                >
                  Refer a Friend ~ Coming Soon
                </Button>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </>
  );
};

export default Products;
