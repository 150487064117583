import React, { useState } from "react";

// import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, ListItemText, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "black",
      borderColor: "#CBD0C8",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "8px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "20px",
    },
    "& .MuiSelect-icon": {
      top: 0,
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  select: {
    fontFamily: "Lexend",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "-0.02",
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "#CBD0C8",
      borderWidth: "1px",
    },
  },
  selectIcon: {
    position: "relative",
    color: "black",
    fontSize: "16px",
  },
  paper: {
    borderRadius: 8,
    maxWidth: "unset",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontFamily: "Lexend",
      fontWeight: 500,
      padding: 12,
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "-0.02",
      background: "white !important",
      color: "black",
    },
    "& li:hover": {
      background: "#F5F6F4 !important",
      color: "black",
    },
    "& li.Mui-selected": {
      color: "black",
      background: "#F5F6F4 !important",
    },
    "& li.Mui-selected:hover": {
      background: "#F5F6F4 !important",
      color: "black",
    },
  },
  checkbox: {
    color: "#A0A0A0",
    "&.Mui-checked": {
      color: "#294F4A",
    },
  },
  list_item_text: {
    fontFamily: "Lexend !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "1.5 !important",
    letterSpacing: "-0.02 !important",
    color: "black",
  },
}));

export const _ActivityFeedFilter = ({ value, handleChange, items }) => {
  const [opened, setOpened] = useState(false);
  const selectedItem = items.find((item) => item.value === value);
  const displayValue = selectedItem ? selectedItem.key : "Event Type";

  return (
    <Box
      sx={{
        padding: "8px",
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
        width: "160px",
        maxWidth: "160px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={(event) => {
          setOpened(!opened);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              color: "black",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "2px",
              lineHeight: "150%",
              textTransform: "uppercase",
            }}
          >
            {displayValue}
          </Typography>
          {value !== "none" && value !== "" && value !== null && (
            <CloseIcon
              sx={{
                color: "black",
                fontSize: "10px",
                cursor: "pointer",
                background: "#AAA",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChange("none");
              }}
            />
          )}
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? "180deg" : "0deg"})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: "30px",
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 30px)",
            borderTop: "1px solid #E0E3DE",
            margin: "0 15px 16px",
            position: "sticky",
            top: "0",
          }}
        />
        {items &&
          items.map(
            (item) =>
              item.value !== "none" &&
              item.value !== "" &&
              item.value !== null && (
                <Box
                  sx={{
                    padding: "12px",
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "1.5",
                    letterSpacing: "-0.02",
                    color: "black",
                    background: "white",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#F5F6F4",
                    },
                  }}
                  onClick={() => {
                    setOpened(false);
                    handleChange(item.value);
                  }}
                >
                  {item.key}
                </Box>
              )
          )}
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
