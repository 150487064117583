import { Box, Avatar, Tooltip, ListItemText, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Menu, MenuItem} from '@mui/material';
import { setCollapsed } from "slices/LayoutSlice";
import Logo from "./logo";
import avatar from "assets/avatar.png";
import avatarBG from "assets/avatar_bg.png";
import { useSignOut } from "hooks/useSignOut";
import { API } from "aws-amplify";

const Topbar = ({user, pictureChanged}) => {
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(true)
  const collapsed = useSelector((state) => state.layout.collapsed);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null)


  const handleSignOut = useSignOut();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchUserData = async () => {
    if (!user?.attributes?.picture) {
      setPicture(null);
      setLoading(false);
      return;
    }
  
    try {
      // const response = await API.get("zoomapi", "/zoom/get-avatar", {
      //   queryStringParameters: {
      //     user_id: user.attributes.sub
      //   },
      // });
      // let response;
      if (user?.attributes?.picture) {
        const pictureUrl = "https://d7joen7tb0xwx.cloudfront.net/public/" + user?.attributes?.picture
        setPicture(pictureUrl);
      } else {
        setPicture(null); // or set to a default image
      }
    } catch (error) {
      console.error("Failed to fetch user picture:", error);
      setPicture(null); // or set to a default image
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user, pictureChanged]);


  

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0, // Aligns the top bar to the top of the viewport
          right: 0, // Aligns the top bar to the left of the viewport
          display: { xs: "none", sm: "none", lg: "flex" },
          padding: "0px 30px",
          justifyContent: "flex-end",
          alignItems: "center",
          zIndex: 900, // Ensures the top bar is above other content
          background: "transparent"
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "24px 40px",
            justifyContent: "space-between",
            alignItems: "center",
            background: "transparent"
          }}
        >
        {loading ? (
          null
        ) : (
            <Tooltip title="Click here to sign out and adjust settings">
              <Box
                sx={{
                  background: "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  marginLeft: "-90px",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Box
                  sx={{
                    background: "white",
                    padding: "4px",
                    borderRadius: "50%",
                    width: "54px",
                    height: "54px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "46px",
                      height: "46px",
                    }}
                    src={picture || avatar}
                  />
                </Box>
              </Box>
            </Tooltip>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            sx: {
              marginLeft: {lg: "-15px", md: "0px",sm: "6px"},
              background: "#fff",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)", // Increase the blur radius by 10%
              borderRadius: "12pt"
            },
          }}
        >
          <MenuItem 
          sx = {{
            "&:hover": {
              backgroundColor: "#f5f5f5", 
            },
          }}
          onClick={() => {
              handleClose(); // Close the menu
              navigate('/settings'); // Navigate to the desired page
            }}>
              <ListItemText primary="Settings" 
                sx={{
                  color: "black",
                }}
              />
          </MenuItem>
          <MenuItem 
          sx = {{
            "&:hover": {
              backgroundColor: "#f5f5f5", 
            },
          }}
          onClick={async () => {
            try {
              handleClose();
              handleSignOut();
            } catch (error) {
              console.error('Error signing out: ', error);
            }
          }}>
            <ListItemText primary="Sign Out" 
                sx={{
                  color: "black"
                }}
              />
          </MenuItem>
        </Menu>
      </Box>
      <Box
        className="topbar"
        sx={{
          display: { xs: "flex", lg: "none" },
          padding: "0px 30px",
          width: "100vw",
          justifyContent: "space-between",
          alignItems: "center",
          background: "rgba(255, 255, 255, 0.7)", // Changed to translucent white
          backdropFilter: "blur(10px)", // Optional: Apply a blur effect to the background content
          height: "82px",
          position: "relative", // Changed to relative to allow content scrolling behind
          borderBottom: "1px solid #F5F5F5",
        }}
      >
        <MenuOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            color: "#0F0934",
            padding: "10px",
            border: "1px solid #F5F5F5",
            borderRadius: "50%",
            boxSizing: "content-box",
          }}
          onClick={() => dispatch(setCollapsed(!collapsed))}
        />
        <Box
          width="155px"
          sx={{
            transform: "translateX(-25%)",
          }}
        >
          <Logo />
        </Box>
        {loading ? (
          null
        ) : (
          <Box
            sx={{
              background: "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
              padding: "3px",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              marginLeft: "-90px",
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                background: "white",
                padding: "4px",
                borderRadius: "50%",
                width: "54px",
                height: "54px",
              }}
            >
              <Avatar
                sx={{
                  width: "46px",
                  height: "46px",
                  display: {
                    md: "block",
                  }
                }}
                src={picture || avatar}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Topbar;
