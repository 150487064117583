import React from "react";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import SessionPage from "scenes/SessionPage/SessionPage";

const ClientSessionPage = ({user, userData}) => {


  return (
    <>
        <GlobalPageComponent user={user} accessTo={userData?.accessTo}>
          <SessionPage user={user} userData={userData}/>
        </GlobalPageComponent>
    </>
  );
};

export default ClientSessionPage;
