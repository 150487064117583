import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { signIn } from "slices/UserSlice";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import googleImg from "assets/google.png";
import { toast } from "react-toastify";
import { CircularProgress, InputLabel } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useStripeCheckoutVirtualCoach } from "hooks/useStripeCheckoutVirtualCoach";
import { useStripeCheckoutVirtualCoachAnnual } from "hooks/useStripeCheckoutVirtualCoachAnnual";
import { useStripeCheckoutCoachingPlatform } from "hooks/useStripeCheckoutCoachingPlatform";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Lottie from "react-lottie";
import * as animationData from "data/dashboardAnimation.json";
import Logo from "scenes/Layout/logo";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
  headerImage: "",
};

export const theme = createTheme(themeOptions);

const googleClientId =
  "498612229346-ev4aor25mos6714nqq8hq8u1r3p9hnsq.apps.googleusercontent.com";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name Required"),
  lastName: Yup.string().required("Last Name Required"),
  email: Yup.string().email("Invalid Email Format").required("Email Required"),
  password: Yup.string().required("Password Required"),
  verificationCode: Yup.string().when("isSignUpStep", {
    is: false,
    then: Yup.string().required("Verification Code Required"),
    otherwise: Yup.string().notRequired(),
  }),
  agreedToTerms: Yup.boolean().oneOf([true], "Must agree to Terms"),
  agreedToDataUsage: Yup.boolean().oneOf([true], "Must agree to Data Usage"),
  isSignUpStep: Yup.boolean(),
});

const SignUp = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const [showPassword, setShowPassword] = useState(false);


  let title = "Sign up";

  // Fetch Origin URL (for Cognito Signup)
  const currentUrl = window.location.origin + location.pathname;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      verificationCode: "",
      isSignUpStep: true,
      virtualCoach: "true",
      agreedToDataUsage: false,
      agreedToTerms: false,
    },
    validationSchema: SignUpSchema,

    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        const {
          firstName,
          lastName,
          email,
          password,
          phone,
          verificationCode,
          isSignUpStep,
        } = values;

        if (isSignUpStep) {
          const response = await fetch(
            "https://0i1bnzenw7.execute-api.us-east-1.amazonaws.com/prod/prod",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email }),
            }
          );
          const responseData = await response.json(); // This parses the JSON response from the fetch call
          const data = JSON.parse(responseData.body); // This is necessary because your Lambda is returning a JSON string in the body field

          // Handle the response based on the action required by the Lambda function
          switch (data.actionRequired) {
            case "signInWithGoogle":
              toast.info(data.message); // Inform the user to sign in with Google
              setSubmitting(false); // Stop the submission process
              return; // Exit the function to prevent further actions
            case "signUp":
              // Proceed to sign up the user if no existing account requires different actions
              try {
                await Auth.signUp({
                  username: email,
                  password,
                  attributes: {
                    email,
                    given_name: firstName,
                    phone_number: phone,
                    family_name: lastName,
                    website: JSON.stringify(currentUrl),
                  },
                });
                formik.setFieldValue("isSignUpStep", false);
              } catch (signupError) {
                toast.error(signupError.message); // Handle errors during sign up
              }
              break;
            default:
              toast.error(
                "An unexpected response was received. Please try again."
              );
              setSubmitting(false);
              return;
          }
        } else {
          // Handle the confirmation step
          await Auth.confirmSignUp(email, verificationCode);
          console.log("User successfully signed up!");
          await dispatch(signIn({ username: email, password })).unwrap();
          navigate("/authenticating");
        }
      } catch (error) {
        toast.error("Error during processing: " + error.message);
        setSubmitting(false);
      } finally {
        setSubmitting(false); // Ensures that the submitting state is always reset
      }
    },
  });

  const handleFederatedSignIn = async () => {
    try {
      await Auth.federatedSignIn({
        provider: "Google",
      });
    } catch (error) {
      toast.error("Error registering user");
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    formik.handleChange(e);
    formik.setFieldTouched(name, true, false);
    // formik.setFieldError(name, error);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          background: "#EFF3F6",
          overflowY: "auto",
        }}
      >
        <Box
          display="flex"
          style={{ alignItems: "left", marginRight: "1200px" }}
        >
          <Logo />
        </Box>
        <GoogleOAuthProvider clientId={googleClientId}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            style={{
              position: "relative",
            }}
          >
            <CssBaseline />
            <Box
              sx={{
                marginTop: 0,
                margin: { xs: "0px 20px", lg: "0px 0px" },
                minHeight: "calc(100vh - 198px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent",
                borderRadius: "10px",
                width: "100%",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                  },
                }}
              >
                <Box
                  sx={{
                    flex: {
                      lg: "50%",
                    },
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "48px",
                      maxWidth: {
                        lg: "400px",
                      },
                      marginTop: { xs: "20px", md: "0px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "48px",
                          lineHeight: "1.17",
                          letterSpacing: "-0.02em",
                          color: "#22272F",
                          marginTop: "-30px",
                          marginBottom: "-10px",
                        }}
                      >
                        {formik.values.isSignUpStep ? title : "Verify Code"}
                      </Typography>
                      <Box
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          lineHeight: "1.5",
                          color: "#454F5F",
                          display: formik.values.isSignUpStep
                            ? "block"
                            : "none",
                        }}
                      >
                        <Typography component={"span"}>
                          Already have an account? &nbsp;
                        </Typography>
                        <Typography
                          onClick={() => navigate("/signin")}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#8E86C1",
                          }}
                          component={"span"}
                        >
                          Sign in
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={formik.handleSubmit}
                    >
                      <Box
                        color="#454F5F"
                        sx={{
                          background: "transparent",
                          display: "flex",
                          flexDirection: "column",
                          gap: "32px",
                        }}
                        position={"relative"}
                        zIndex={1}
                      >
                        {formik.values.isSignUpStep && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: {
                                  xs: "column",
                                  lg: "row",
                                },
                                gap: "16px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "16px",
                                }}
                              >
                                <InputLabel
                                  htmlFor="component-helper"
                                  sx={{
                                    color: "#454F5F",
                                    fontSize: "12px",
                                    lineHeight: "1.33",
                                    fontWeight: "500",
                                    textTransform: "uppercase",
                                    marginTop: "-20px",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  First Name
                                </InputLabel>
                                <Box
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    autoFocus
                                    value={formik.values.firstName}
                                    onChange={handleChange}
                                    error={
                                      formik.touched.firstName &&
                                      Boolean(formik.errors.firstName)
                                    }
                                    InputProps={{
                                      style: {
                                        color: "black",
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: "black",
                                      },
                                    }}
                                    sx={{
                                      color: "#22272F",
                                      background: "white",
                                      borderRadius: "16px",
                                      width: "100%",
                                      border: "1px solid #C4CAD4",
                                      "&:before, &:after": {
                                        display: "none",
                                      },
                                      "& fieldset": {
                                        border: "none !important",
                                      },
                                    }}
                                  />
                                  {formik.touched.firstName &&
                                    formik.errors.firstName && (
                                      <Typography
                                        variant="caption"
                                        color="error"
                                      >
                                        {formik.errors.firstName}
                                      </Typography>
                                    )}
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "16px",
                                }}
                              >
                                <InputLabel
                                  htmlFor="component-helper"
                                  sx={{
                                    color: "#454F5F",
                                    fontSize: "12px",
                                    lineHeight: "1.33",
                                    fontWeight: "500",
                                    textTransform: "uppercase",
                                    marginTop: {
                                      xs: "-10px",
                                      md: "-10px",
                                      lg: "-20px",
                                    },
                                    // marginTop: "-20px",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  Last Name
                                </InputLabel>
                                <Box
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    autoComplete="family-name"
                                    value={formik.values.lastName}
                                    onChange={handleChange}
                                    error={
                                      formik.touched.lastName &&
                                      Boolean(formik.errors.lastName)
                                    }
                                    InputProps={{
                                      style: {
                                        color: "#22272F",
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: "#454F5F",
                                      },
                                    }}
                                    sx={{
                                      color: "#22272F",
                                      background: "white",
                                      borderRadius: "16px",
                                      width: "100%",
                                      border: "1px solid #C4CAD4",
                                      "&:before, &:after": {
                                        display: "none",
                                      },
                                      "& fieldset": {
                                        border: "none !important",
                                      },
                                    }}
                                  />
                                  {formik.touched.lastName &&
                                    formik.errors.lastName && (
                                      <Typography
                                        variant="caption"
                                        color="error"
                                      >
                                        {formik.errors.lastName}
                                      </Typography>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                              }}
                            >
                              <InputLabel
                                htmlFor="component-helper"
                                sx={{
                                  color: "#454F5F",
                                  fontSize: "12px",
                                  lineHeight: "1.33",
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                  marginTop: "-20px",
                                  marginBottom: "-10px",
                                }}
                              >
                                Email
                              </InputLabel>
                              <Box
                                sx={{
                                  position: "relative",
                                }}
                              >
                                <TextField
                                  required
                                  fullWidth
                                  id="email"
                                  name="email"
                                  autoComplete="email"
                                  value={formik.values.email}
                                  onChange={handleChange}
                                  error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                  }
                                  InputProps={{
                                    style: {
                                      color: "#22272F",
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: "#454F5F",
                                    },
                                  }}
                                  sx={{
                                    color: "#22272F",
                                    background: "white",
                                    borderRadius: "16px",
                                    width: "100%",
                                    border: "1px solid #C4CAD4",
                                    "&:before, &:after": {
                                      display: "none",
                                    },
                                    "& fieldset": {
                                      border: "none !important",
                                    },
                                  }}
                                />
                                {formik.touched.email &&
                                  formik.errors.email && (
                                    <Typography variant="caption" color="error">
                                      {formik.errors.email}
                                    </Typography>
                                  )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                              }}
                            >
                              <InputLabel
                                htmlFor="component-helper"
                                sx={{
                                  color: "#454F5F",
                                  fontSize: "12px",
                                  lineHeight: "1.33",
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                  marginTop: "-20px",
                                  marginBottom: "-10px",
                                }}
                              >
                                Password
                              </InputLabel>
                              <Box
                                sx={{
                                  position: "relative",
                                  marginBottom: "-20px",
                                }}
                              >
                                <TextField
                                  required
                                  fullWidth
                                  name="password"
                                  type="password"
                                  id="password"
                                  autoComplete="new-password"
                                  value={formik.values.password}
                                  onChange={handleChange}
                                  error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                  }
                                  InputProps={{
                                    style: {
                                      color: "#22272F",
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: "#454F5F",
                                    },
                                  }}
                                  sx={{
                                    color: "#22272F",
                                    background: "white",
                                    borderRadius: "16px",
                                    width: "100%",
                                    border: "1px solid #C4CAD4",
                                    "&:before, &:after": {
                                      display: "none",
                                    },
                                    "& fieldset": {
                                      border: "none !important",
                                    },
                                  }}
                                />
                                {formik.touched.password &&
                                  formik.errors.password && (
                                    <Typography variant="caption" color="error">
                                      {formik.errors.password}
                                    </Typography>
                                  )}
                                {showPassword === true ? (
                                  <VisibilityOffIcon
                                    color="#454F5F"
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "16px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowPassword(false);
                                      const passwordElement =
                                        document.getElementById("password");
                                      passwordElement.setAttribute(
                                        "type",
                                        "password"
                                      );
                                    }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    color="#454F5F"
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "16px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowPassword(true);
                                      const passwordElement =
                                        document.getElementById("password");
                                      passwordElement.setAttribute(
                                        "type",
                                        "text"
                                      );
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.agreedToTerms}
                                    color="primary"
                                    onChange={handleChange}
                                    name="agreedToTerms"
                                    style={{ color: "black" }}
                                  />
                                }
                                label={
                                  <span>
                                    I agree to the&nbsp;
                                    <Link
                                      to="/terms-signup"
                                      style={{
                                        textDecoration: "",
                                        color: "black",
                                      }}
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.agreedToDataUsage}
                                    color="primary"
                                    onChange={handleChange}
                                    name="agreedToDataUsage"
                                    style={{ color: "black" }}
                                  />
                                }
                                label={
                                  <span>
                                    By continuing, I agree to and acknowledge
                                    AlterCall's use of my Data
                                  </span>
                                }
                              />
                            </Box>
                          </>
                        )}
                        {!formik.values.isSignUpStep && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                            }}
                          >
                            <InputLabel
                              htmlFor="component-helper"
                              sx={{
                                color: "#454F5F",
                                fontSize: "12px",
                                lineHeight: "1.33",
                                fontWeight: "500",
                                textTransform: "uppercase",
                              }}
                            >
                              Check Email for Verification Code
                            </InputLabel>
                            <TextField
                              required
                              fullWidth
                              id="verificationCode"
                              name="verificationCode"
                              autoComplete="verification-code"
                              value={formik.values.verificationCode}
                              onChange={handleChange}
                              error={
                                formik.touched.verificationCode &&
                                Boolean(formik.errors.verificationCode)
                              }
                              InputProps={{
                                style: {
                                  color: "#22272F",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "black",
                                },
                              }}
                              sx={{
                                color: "#22272F",
                                background: "white",
                                borderRadius: "16px",
                                width: "100%",
                                border: "1px solid #C4CAD4",
                                "&:before, &:after": {
                                  display: "none",
                                },
                                "& fieldset": {
                                  border: "none !important",
                                },
                              }}
                            />
                            {formik.touched.verificationCode &&
                              formik.errors.verificationCode && (
                                <Typography variant="caption" color="error">
                                  {formik.errors.verificationCode}
                                </Typography>
                              )}
                          </Box>
                        )}

                        <Button
                          disabled={
                            submitting || !formik.dirty || !formik.isValid
                          }
                          onClick={() => {
                            setSubmitting(true);
                            formik.handleSubmit();
                          }}
                          sx={{
                            width: "100%",
                            padding: "18px 0",
                            background: "white",
                            border: "1px solid black",
                            borderRadius: "43px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            transition: "all 0.3s",
                            "&:hover": {
                              background: "black",
                              "& *": {
                                color: "white",
                              },
                            },
                            "&:disabled": {
                              background: "#d3d3d3",
                              color: "rgba(0, 0, 0, 0.26)",
                              border: "none",
                              cursor: "not-allowed",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              color: "black",
                              fontSize: "12px",
                              fontWeight: "600",
                              letterSpacing: "0.4px",
                              textTransform: "uppercase",
                            }}
                          >
                            {submitting ? (
                              <CircularProgress
                                size={20}
                                sx={{
                                  color: "black",
                                }}
                              />
                            ) : formik.values.isSignUpStep ? (
                              "Create account"
                            ) : (
                              "Verify"
                            )}
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            display: "flex",
                            fontSize: "12px",
                            fontWeight: "600",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "-20px",
                            borderRadius: "40px",
                            border: "1px solid black",
                            backdropFilter: "blur(25px)",
                            textTransform: "none",
                            color: "black",
                            background: "white",
                            transition: "all 0.4s",
                            width: "100%",
                            padding: "8px",
                            marginBottom: "24px",
                          }}
                          onClick={handleFederatedSignIn}
                        >
                          <Box
                            sx={{
                              width: "38px",
                              height: "38px",
                              backgroundImage: `url(${googleImg})`,
                            }}
                          />
                          Sign up with Google
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      lg: "block",
                    },
                    flex: {
                      lg: "50%",
                    },
                  }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    width="100%"
                    height="auto"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Footer /> */}
        </GoogleOAuthProvider>
      </Box>
    </ThemeProvider>
  );
};

export default SignUp;
