import { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Circle = ({ id, color, score, radius, fill, strokeDashoffset }) => {
  const r = radius;
  const circ = 2 * Math.PI * r;
  const strokescore = ((100 - score) * circ) / 100;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill={fill ?? "transparent"}
      stroke={color ? color : `url(#${id})`}
      strokeWidth={"0.7rem"}
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{ transition: "stroke-dashoffset 1s ease-out" }}
    ></circle>
  );
};

const Text = ({ score, textSize }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={textSize}
      fontFamily="Poppins"
      fontWeight={300}
    >
      {isNaN(score) ? "-" : (score === 0 ? "0" : Math.round(score))}
    </text>
  );
};

const Pie = ({ score, radius, color, textSize, fillStopColor }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(2 * Math.PI * radius);
  const gradientId = `gradient-${Math.random()}`;
  const fillGradientId = `fill-gradient-${Math.random()}`;

  useEffect(() => {
    const circ = 2 * Math.PI * radius;
    const strokescore = ((100 - score) * circ) / 100;
    setStrokeDashoffset(strokescore);
  }, [score, radius]);

  return (
    <svg width={200} height={200}>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#e383eb" />
          <stop offset="40%" stopColor="#8e33b8" />
          <stop offset="60%" stopColor="#568FF3" />
          <stop offset="100%" stopColor="#62C1EB" />
        </linearGradient>
        <linearGradient id={fillGradientId}>
          <stop offset="0%" stopColor={fillStopColor ?? "#62C1EB"} stopOpacity=".2" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g transform={`rotate(90 ${"100 100"})`}>
        <Circle color="lightgrey" radius={radius} score={100} fill={`url(#${fillGradientId})`} strokeDashoffset={0} />
        <Circle id={gradientId} radius={radius} score={score} color={color} strokeDashoffset={strokeDashoffset} />
      </g>
      {isNaN(score) ? (
        <Text score="-" textSize={textSize} />
      ) : (
        <Text score={score} textSize={textSize} />
      )}
    </svg>
  );
};

const ClientLaraPieCharts = ({ laraData, loading }) => {
  const [averageScores, setAverageScores] = useState({
    "Listen score": "-",
    "Affirm score": "-",
    "Respond score": "-",
    "Ask score": "-",
    "Overall score": "-",
  });

  useEffect(() => {
    if (laraData.length > 0) {
      const validData = laraData.filter(item => item.lara && item.lara.client.length > 0);
      const aggregatedScores = validData.reduce(
        (acc, curr) => {
          acc.listen += parseFloat(curr.lara.client[0].listen_score);
          acc.affirm += parseFloat(curr.lara.client[0].affirm_score);
          acc.respond += parseFloat(curr.lara.client[0].respond_score);
          acc.ask += parseFloat(curr.lara.client[0].ask_score);
          acc.count += 1;
          return acc;
        },
        { listen: 0, affirm: 0, respond: 0, ask: 0, count: 0 }
      );

      const averageScores = {
        "Listen score": Math.round((aggregatedScores.listen / aggregatedScores.count) * 20),
        "Affirm score": Math.round((aggregatedScores.affirm / aggregatedScores.count) * 20),
        "Respond score": Math.round((aggregatedScores.respond / aggregatedScores.count) * 20),
        "Ask score": Math.round((aggregatedScores.ask / aggregatedScores.count) * 20),
        "Overall score": Math.round(
          (
            ((aggregatedScores.listen / aggregatedScores.count) +
              (aggregatedScores.affirm / aggregatedScores.count) +
              (aggregatedScores.respond / aggregatedScores.count) +
              (aggregatedScores.ask / aggregatedScores.count)) /
            4 *
            20
          )
        ),
      };

      setAverageScores(averageScores);
    }
  }, [laraData]);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress 
            sx={{
              color: "black"
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            borderRadius: "40px",
            background: "white",
            overflow: "hidden",
            minHeight: "220px",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Pie score={averageScores["Overall score"]} radius={80} textSize={"50px"} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie score={averageScores["Listen score"]} radius={60} color={"#62C1EB"} textSize={"35px"} fillStopColor={"#62C1EB"}/>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Listen
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie score={averageScores["Affirm score"]} radius={60} color={"#568FF3"} textSize={"35px"} fillStopColor={"#568FF3"}/>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Affirm
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie score={averageScores["Respond score"]} radius={60} color={"#e383eb"} textSize={"35px"} fillStopColor={"#e383eb"} />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Respond
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Pie score={averageScores["Ask score"]} radius={60} color={"#8e33b8"} textSize={"35px"} fillStopColor={"#8e33b8"} />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Ask
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ClientLaraPieCharts;
