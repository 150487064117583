import { BorderColor, Delete } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import TrophyIcon from "assets/TrophyIcon";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ToDoActionToolBar = ({ user, selected, selectedIds, setUpdatedAction, setInitialAction, setOpenEditActionModal, setOpenAddActionsToGoalModal, setOpenCompleteActionModal, setOpenDatePicker, setOpenDeleteActionModal }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "24px",
                position: "relative",
                flexWrap: {
                    xs: "wrap",
                    md: "unset",
                },
                '& > :nth-of-type(n)': {
                    flexBasis: {
                        xs: "calc(50% - 24px)",
                        md: "unset",
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    marginLeft: "16px",
                    "&:hover": {
                        background: "#F7F7F7",
                        cursor: (selected.length === 1 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => {
                    if (selected.length === 1) {
                        setUpdatedAction(selectedIds[0]);
                        setInitialAction(selectedIds[0]);
                        setOpenEditActionModal(true);
                    }
                }}
            >
                <BorderColor
                    sx={{
                        fill: (selected.length === 1 ? "black" : "#B6B6B6")
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.66",
                        color: (selected.length === 1 ? "black" : "#B6B6B6"),
                    }}
                >
                    Edit
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    "&:hover": {
                        background: "#F7F7F7",
                        cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => selected.length > 0 && setOpenAddActionsToGoalModal(true)}
            >
                <TrophyIcon
                    fill={selected.length > 0 ? "black" : "#B6B6B6"}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.66",
                        color: (selected.length > 0 ? "black" : "#B6B6B6"),
                    }}
                >
                    Assign to Goal
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    "&:hover": {
                        background: "#F7F7F7",
                        cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => selected.length > 0 && setOpenCompleteActionModal(true)}
            >
                <CheckCircleOutlineIcon
                    sx={{
                        fill: (selected.length > 0 ? "black" : "#B6B6B6")
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.66",
                        color: (selected.length > 0 ? "black" : "#B6B6B6"),
                    }}
                >
                    Mark as Completed
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    marginLeft: "0px",
                    "&:hover": {
                        background: "#F7F7F7",
                        cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => selected.length > 0 && setOpenDatePicker(true)}
            >
                <CalendarMonthIcon
                    sx={{
                        fill: (selected.length > 0 ? "black" : "#B6B6B6")
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.66",
                        color: (selected.length > 0 ? "black" : "#B6B6B6"),
                    }}
                >
                    Assign Due Date
                </Typography>
            </Box>
            {!user?.attributes?.["custom:is_client"] && (
                <Box
                    sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                            background: "#F7F7F7",
                            cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                        },
                    }}
                    onClick={() => selected.length > 0 && setOpenDeleteActionModal(true)}
                >
                    <Delete
                        sx={{
                            fill: (selected.length > 0 ? "black" : "#B6B6B6")
                        }}
                    />
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.66",
                            color: (selected.length > 0 ? "black" : "#B6B6B6"),
                        }}
                    >
                        Delete
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default ToDoActionToolBar;
