import { useState, useCallback } from "react";
import { API } from "aws-amplify";
import { fetchStripeCheckout } from "graphql/queries";

export const useStripeCheckoutVirtualCoachAnnual = () => {
  const [productId, setProductIdVirtualCoach] = useState(
    "price_1PBKrrLKxpQPkYt5JAqa7aSj"
  );
  const [
    loadingStripeCheckoutVirtualCoachAnnual,
    setLoadingStripeCheckoutVirtualCoachAnnual,
  ] = useState(false);
  const [
    errorWithStripeCheckoutVirtualCoachAnnual,
    setErrorWithStripeCheckoutVirtualCoach,
  ] = useState(null);

  const handleStripeCheckoutVirtualCoachAnnual = useCallback(async () => {
    setLoadingStripeCheckoutVirtualCoachAnnual(true);
    const stringifiedInput = JSON.stringify({ selected_product_id: productId });

    try {
      const response = await API.graphql({
        query: fetchStripeCheckout,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: stringifiedInput,
        },
      });
      const { sessionId } = JSON.parse(response.data.fetchStripeCheckout);
      window.location.href = sessionId; // Redirect to Stripe Checkout
    } catch (error) {
      console.error("Error running AlterCall checkout session:", error);
      setErrorWithStripeCheckoutVirtualCoach(error);
    } finally {
      setLoadingStripeCheckoutVirtualCoachAnnual(false);
    }
  }, [productId]);

  return {
    handleStripeCheckoutVirtualCoachAnnual,
    setProductIdVirtualCoach,
    loadingStripeCheckoutVirtualCoachAnnual,
    errorWithStripeCheckoutVirtualCoachAnnual,
  };
};
