import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

export const signIn = createAsyncThunk(
  "user/signIn222222",
  async ({ username, password }) => {
    const response = await Auth.signIn(username, password);
    // localStorage.setItem(
    //   "authRole",
    //   response.attributes["custom:is_client"] == "true" ? 2 : 1
    // );
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: { user: null, status: "idle", error: null, picture: "" },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
