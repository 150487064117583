export function formatData(data) {
    const resources = {};
    data.forEach(row => {
        const { resource_id, resource_name, page_id, page_name, section_id, section_name } = row;
        if (!resources[resource_id]) {
            resources[resource_id] = {
                resource_id,
                resource_name,
                pages: {}
            };
        }
        if (page_id && !resources[resource_id].pages[page_id]) {
            resources[resource_id].pages[page_id] = {
                page_id,
                page_name,
                sections: []
            };
        }
        if (section_id) {
            resources[resource_id].pages[page_id].sections.push({
                section_id,
                section_name
            });
        }
    });

    const response = Object.values(resources).map(resource => ({
        ...resource,
        pages: Object.values(resource.pages)
    }));

    return response;
}