import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import VideoPlayerCard from "scenes/SessionPageCards/VideoPlayerCard";
import { Avatar, Box, Button, CircularProgress, Divider, Modal, TextField, Typography } from "@mui/material";
import avatar from "assets/avatar.png";
import { useNavigate } from "react-router";

// Queries
import { fetchPresignedUrls } from "../../graphql/queries";
import ShareIcon from "components/Icons/Share.svg";
import { useSelector } from "react-redux";
import { Check, ErrorOutline, PersonAddAlt } from "@mui/icons-material";
import { toast } from "react-toastify";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import { formatDistanceToNow } from 'date-fns';
import dayjs from "dayjs";
import LaraSessionPage from "components/SessionPage/LaraSessionPage";
import SessionDetails from "components/SessionPage/SessionDetails";


const SessionPage = ({user, isProfessionalTier, userData}) => {
  const { file_name } = useParams();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const encodedFileName = encodeURIComponent(file_name);
  const [fileData, setFileData] = useState({});
  const [laraData, setLaraData] = useState({});
  const [callActions, setCallActions] = useState({})
  const [videoUrl, setVideoUrl] = useState("");
  const [transcriptUrl, setTranscriptUrl] = useState("");
  const [vttText, setVttText] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingFile, setLoadingFile] = useState(true);
  const [showClientList, setShowClientList] = useState(false);
  const [clientShared, setClientShared] = useState("");
  const [clientData, setClient] = useState([]);
  const [assignee, setAssignee] = useState({});
  const [client, setAssignClient] = useState({});
  const [clientId, setClientId] = useState("");
  const [coachId, setCoachId] = useState("");
  const [assignModal, setAssignModal] = useState(false);
  const [assignedModal, setAssignedModal] = useState(false);
  const [updatedAction, setUpdatedAction] = useState({});
  const [initialAction, setInitialAction] = useState({});
  const [quickRecap, setQuickRecap] = useState("");
  const [updatedQuickRecap, setUpdatedQuickRecap] = useState({});
  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const [openEditQuickRecapModal, setOpenEditQuickRecapModal] = useState(false);
  const [activeTab, setActiveTab] = useState("editText");
  const handleCloseEditAction = () => {
    setOpenEditActionModal(false)
    setInitialAction({});
    setActiveTab("editText");
  }
  const handleCloseEditQuickRecap = () => {
    setOpenEditQuickRecapModal(false)
  }
  const navigate = useNavigate();

  const openAssignModal = () => {
    setShowClientList(false);
    setAssignClient(assignee);
    setAssignModal(true);
  };

  const closeAssignModal = () => setAssignModal(false);
  const openAssignedModal = () => setAssignedModal(true);
  const closeAssignedModal = () => setAssignedModal(false);

  const assignCall = async (client_data) => {
    closeAssignModal();
    try {
      setShowClientList(false);
      const body = [];
        if (status === "ReadyToPublish") {
          body.push({
            id: fileData[0]?.id,
            coach_id: fileData[0]?.coach_id,
            call_name: fileData[0]?.call_name,
            client_name: client_data.name,
            client_id: client_data.id,
            zoom_date: fileData[0]?.zoom_date,
            coach_name: user?.attributes?.given_name + " " + user?.attributes?.family_name,
          });
        }
      const res = await API.put("zoomapi", "/zoom/assignCall", { body: body });
      if (res.message === "succeed") {
        // toast.success(`Call has been shared with ${client_data.name}`);
        toast(`Session has been shared with ${client_data.name}`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        fetchFileData();

      } else {
        toast.error("Error while undo assigning calls");
      }
    } catch (err) {
      console.log("Error in assign call", err);
    }
  };

  const assignModalContent = () => {
    return (
      <Modal open={assignModal} onClose={closeAssignModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to share the session with ${client.name}?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap="15px">
          <Button
              sx={{
                paddingX: "80pt",
                paddingY: "10pt",
                borderRadius: "53px",
                background: "#FFFFFF",
                color: "#C10404",
                border: "1px solid #BE0808",
                fontWeight: "bold",
                "&:hover": {
                  background: "rgba(190, 8, 8, 0.4)",
                  border: "1px solid rgba(190, 8, 8, 0.4)",
                  color: "white",
                },
            }}
              onClick={closeAssignModal}
            >
              No
            </Button>
            <Button
              sx={{
                paddingX: "80pt",
                paddingY: "10pt",
                borderRadius: "52px",
                color: "white",
                background:  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
                  cursor: "pointer"
                },
            }}
              onClick={() => assignCall(client)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const assignedModalContent = () => {
    return (
      <Modal open={assignedModal} onClose={closeAssignedModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Session has been shared with ${client.name}`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                background: "#160042",
                color: "white",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                "&:hover": {
                  background: "#10B02A",
                },
              }}
              onClick={closeAssignedModal}
            >
              <Check />
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const getLaraQuery = `query getLara($call_id: ID!) {
    getLara(call_id: $call_id) {
      call_id
      coach_id
      coachee_id
      coach_name
      coachee_name
      coach_overall_score
      coach_listen_score
      coach_listen_report
      coach_listen_area_of_improvement
      coach_affirm_score
      coach_affirm_report
      coach_affirm_area_of_improvement
      coach_respond_score
      coach_respond_report
      coach_respond_area_of_improvement
      coach_ask_score
      coach_ask_report
      coach_ask_area_of_improvement
      coachee_overall_score
      coachees {
        coachee_id
        coachee_name
        overall_score
        listen_score
        listen_report
        listen_area_of_improvement
        affirm_score
        affirm_report
        affirm_area_of_improvement
        respond_score
        respond_report
        respond_area_of_improvement
        ask_score
        ask_report
        ask_area_of_improvement
      }
    }
  }`;


  const fetchFileData = useCallback(async () => {
    try {
      setLoadingFile(true);
      const response = await API.get("zoomapi", "/zoom/fetch-call", {
        queryStringParameters: { file_name: encodedFileName },
      });
  
      if (!response?.data) {
        setLoadingFile(false);
        return;
      }

  
      const responseData = response.data[0];
      setFileData(response.data);
      setQuickRecap(responseData?.quick_recap);
      if(responseData.client && responseData.coach_id){
        setClientShared(responseData.client)
        setClientId(responseData.client_id)
        setCoachId(responseData.coach_id)
      }

      const callId = responseData.id;
      const result = await API.graphql(
        graphqlOperation(getLaraQuery, { call_id: callId })
      );


      const laraData = result.data.getLara;

      // Extracting coachData and clientData
      const coachData = {
        coach_name: laraData?.coach_name ?? "",
        coach_overall_score: laraData?.coach_overall_score ?? "",
        listen_score: laraData?.coach_listen_score ?? "",
        listen_report: laraData?.coach_listen_report ?? "",
        listen_area_of_improvement: laraData?.coach_listen_area_of_improvement ?? "",
        affirm_score: laraData?.coach_affirm_score ?? "",
        affirm_report: laraData?.coach_affirm_report ?? "",
        affirm_area_of_improvement: laraData?.coach_affirm_area_of_improvement ?? "",
        respond_score: laraData?.coach_respond_score ?? "",
        respond_report: laraData?.coach_respond_report ?? "",
        respond_area_of_improvement: laraData?.coach_respond_area_of_improvement ?? "",
        ask_score: laraData?.coach_ask_score ?? "",
        ask_report: laraData?.coach_ask_report ?? "",
        ask_area_of_improvement: laraData?.coach_ask_area_of_improvement ?? ""
      };

      const clientData = laraData?.coachees.find(
        coachee => coachee.coachee_name === responseData.client_name
      );

      const labeledData = {
        coach: coachData,
        client: clientData
      };

      setLaraData(labeledData);
  
      const vttUrl = responseData?.vtt_s3_link || "";
      const mp4Url = responseData?.mp4_s3_link || "";
  
      const fileDataObject = { vtt_s3_link: vttUrl, mp4_s3_link: mp4Url };
      const fileDataStringified = JSON.stringify(fileDataObject);
      const fileDataReStringified = JSON.stringify(fileDataStringified);
  
      const { data } = await API.graphql({
        query: fetchPresignedUrls,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: JSON.stringify(fileDataReStringified) },
      });
  
      const responseObject = JSON.parse(
        data.fetchPresignedUrls.replace(/statusCode=/g, '"statusCode":').replace(/, body=/g, ', "body":')
      );
      const bodyObject = responseObject.body;
  
      if (bodyObject) {
        const vttPresignedUrl = bodyObject.vtt_presigned;
        const mp4PresignedUrl = bodyObject.mp4_presigned;
  
        if (vttPresignedUrl) {
          setTranscriptUrl(vttPresignedUrl);
          fetch(vttPresignedUrl)
            .then((response) => response.text())
            .then((data) => setVttText(data))
            .catch((error) => console.error("Error:", error));
        }
  
        if (mp4PresignedUrl) {
          setVideoUrl(mp4PresignedUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoadingFile(false);
      setLoading(false);
    }
  }, [encodedFileName]);
  


  const fetchClientData = useCallback(async () => {
    try {
      const userId = user?.attributes?.sub;
      const response = await API.get("zoomapi", "/zoom/fetch-clients", {
        queryStringParameters: { coach_id: userId },
      });

      if (!response.client_data) {
        setLoading(false);
        return;
      }
      response.client_data.map((item, index) => {
        item.avatar = item.avatar
          ? `https://d7joen7tb0xwx.cloudfront.net/public/${item.avatar}`
          : null;
        return item;
      })
      const sortedData = response.client_data.sort(
        (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
      );
      setClient(sortedData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  }, [user?.attributes?.sub]);

  
  useEffect(() => {
    fetchFileData();
    fetchClientData();
  }, [fetchFileData, fetchClientData]);



  const clientName = fileData[0]?.client_name;
  const callName = fileData[0]?.call_name;
  const coachName = fileData[0]?.coach_name;
  const zoomDate = fileData[0]?.zoom_date;

  const fetchActions = useCallback(async () => {
    try {
      const response = await API.get("zoomapi", `/zoom/fetchActionsByCall`, {
        queryStringParameters: { call_id: fileData[0]?.id },
      });
      if (response) {
        setCallActions(response.actions);
      }
    } catch (error) {
      console.error("error fetching actions", error);
    }
  }, [fileData]);

  useEffect(() => {
    if (fileData[0]) {
      fetchActions();
    }
  }, [fileData, fetchActions]);

  //check if the user's id is either the coach or client id registered on the session
  useEffect(() => {
    if (fileData[0]) {
      const userId = user?.attributes?.sub;
      const coachId = fileData[0]?.coach_id;
      const clientId = fileData[0]?.client_id;
      if (userId !== coachId && userId !== clientId) {
        navigate(-1); // Navigate to the last page the user was on
      }
    }
  }, [fileData, user?.attributes?.sub, navigate]);

  const actionsArray = callActions;
  const summary = fileData[0]?.summary;
  const labels = summary
    ?.match(/"label": "(.*?)"/g)
    .map((label) => label.replace(/"label": "(.*?)"/, "$1"));
  const summaries = summary
    ?.match(/"summary": "(.*?)"/g)
    .map((summary) => summary.replace(/"summary": "(.*?)"/, "$1"));

  const status = fileData[0]?.status;
  const duration = fileData[0]?.duration_minutes;
  const formattedZoomDate = dayjs(zoomDate).utc().format('MM.DD.YY')

  const downloadTranscript = async () => {
    try {
      const response = await fetch(transcriptUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${callName}.vtt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  };


  const editActionModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalAction = "";
    let sortedEdits = [];
    if(initialAction && initialAction.edits && initialAction.edits[0]){
      sortedEdits = initialAction.edits
    ? [...initialAction.edits].sort((a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp))
    : [];
      originalAction = sortedEdits[0].previous_value;
    }
    return (
      <Modal
        open={openEditActionModal}
        onClose={handleCloseEditAction}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box 
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              }
            }}>
               <Box display="flex" gap="10px">
               <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "4px",
                    borderBottom: activeTab === "editText" ? "1px solid black" : "none",
                    textTransform: "uppercase",
                    boxShadow: "none",
                    "&:hover": {
                      cursor: "pointer"
                    },
                  }}
                  onClick={() => handleTabChange("editText")}
               >
                  Edit Action
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "4px",
                    borderBottom: activeTab === "editHistory" ? "1px solid black" : "none",
                    textTransform: "uppercase",
                    boxShadow: "none",
                    "&:hover": {
                      cursor: "pointer"
                    },
                  }}
                  onClick={() => handleTabChange("editHistory")}
                >
                    View History
                </Typography>
              </Box>
            {activeTab === "editHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "220px",
                  overflowY: "auto"
                }}
              >
                {sortedEdits &&
                  sortedEdits
                    .filter(edit => edit && edit.edit_id !== null)
                    .slice() 
                    .reverse() 
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black"
                          }}
                        >
                          {`${edit.new_value}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px"
                          }}
                        >
                          edited by {
                            user?.attributes?.sub === edit.edited_by_userid
                              ? "me"
                              : user?.attributes?.sub === clientId
                                ? coachName ?? "Coach"
                                : (clientShared ?? clientName) || "Client"
                          } {formatDistanceToNow(new Date(edit.edit_timestamp))} ago
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                    ))}
                 {initialAction.edits && originalAction && (
                  <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    {originalAction}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2D2D2D",
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    orginal action
                  </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editText" && (
            <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "12px",
                  lineHeight: "150%",
                  letterSpacing: "2px",
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "bold"
                }}
              >
                Action
              </Typography>
              <TextField
                required
                fullWidth
                autoFocus
                multiline
                rows={4}
                value={updatedAction.action}
                InputProps={{
                  inputProps: {
                    style: {
                      color: "black",
                    },
                  },
                  style: {
                    borderRadius: "16px",
                    border: "1px solid #C4CAD4",
                    backgroundColor: "transparent",
                  },
                }}
                onChange={(event) => {
                  setUpdatedAction((prev) => ({
                    ...prev,
                    action: event.target.value,
                  }))
                }}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="15px">
              <NegativeActionButton 
                onClick={handleCloseEditAction}
                label={"Cancel"}
              />
              <PositiveActionButton 
                onClick={saveEditAction}
                label={"Save Action"}
                disabled={updatedAction.action === initialAction.action}
              />
            </Box>
            </>
          )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const editQuickRecapModal = () => {
    return (
      <Modal
        open={openEditQuickRecapModal}
        onClose={() => setOpenEditQuickRecapModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "50%",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "36px",
              color: "black",
              fontWeight: 300
            }}
          >
            Edit Recap
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: {
              xs: "8px",
              lg: "32px",
            }
          }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "12px",
                  lineHeight: "150%",
                  letterSpacing: "2px",
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "bold"
                }}
              >
                Recap
              </Typography>
              <TextField
                required
                fullWidth
                autoFocus
                multiline
                rows={4}
                value={updatedQuickRecap}
                InputProps={{
                  inputProps: {
                    style: {
                      color: "black",
                      height: "70px"
                    },
                  },
                  style: {
                    borderRadius: "16px",
                    border: "1px solid #C4CAD4",
                    backgroundColor: "transparent",
                  },
                }}
                onChange={(event) => {
                  setUpdatedQuickRecap(event.target.value);
                }}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="15px">
              <NegativeActionButton 
                onClick={handleCloseEditQuickRecap}
                label={"Cancel"}
              />
              <PositiveActionButton 
                onClick={saveEditQuickRecap}
                label={"Save Recap"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  const saveEditAction = async () => {
    try {
      setOpenEditActionModal(false);
      const { status } = await API.put("zoomapi", "/zoom/update-action", {
        body: {
          action_id: updatedAction.action_id,
          action: updatedAction.action,
          initial_action: initialAction.action,
          edited_by_userID: user?.attributes?.sub
        },
      });
      if (status === "success") {
        toast(`Updated action successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setCallActions((prev) =>
          prev.map((prevRow) =>
            prevRow.action_id === updatedAction.action_id
              ? { ...prevRow, action: updatedAction.action }
              : prevRow
          )
        );
        setUpdatedAction("");
      }
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  const saveEditQuickRecap = async () => {
    try {
      setOpenEditActionModal(false);
      const { status } = await API.put("zoomapi", "/zoom/update-file-recap", {
        body: {
          call_id: fileData[0]?.id,
          quick_recap: updatedQuickRecap,
        },
      });
      if (status === "success") {
        toast(`Updated recap successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setQuickRecap(updatedQuickRecap);
        setOpenEditQuickRecapModal(false);
      }
    } catch (error) {
      console.error("Error updating action:", error);
      setOpenEditQuickRecapModal(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EFF3F6",
        width: "100%",
        height: "100%",
        padding: {
          xs: "16px",
          md: "32px 40px",
        },
        display: {
          xs: !collapsed ? "none" : "block",
        },
        overflowY: "auto",
      }}
    >
      {!loading && (
        <Box width={"80%"}>
          <Typography
            sx={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: {xs: "24px", md:"36px"},
              fontWeight: 300,
              marginBottom: "16px",
            }}
          >
            {callName}
          </Typography>
        </Box>
      )}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "50%",
            left: { xs: "50%", lg: "60%", xl: "58%" },
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: {
              xs: "30px",
              md: "30px",
            },
            paddingTop: {
              xs: "30px",
              md: "30px",
            },
            paddingBottom: {
              xs: "30px",
              md: "30px",
            },
            maxHeight: { md: "calc(100vh - 100px)" },
            height: "95%",
            overflow: "auto",
            width: "100%",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              gap: "30px",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Box>
            <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "24px",
                      md: "32px",
                    },
                    lineHeight: "1.2",
                    letterSpacing: "-0.02em",
                    color: "#160042",
                    fontWeight: 300
                  }}
                >
                  {"Your call had " +
                    (actionsArray.length ? actionsArray.length : "0") +
                    " actions."}
                </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px"
              }}
            >
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: {
                  xs: "14px",
                  md: "18px",
                },
                color: "#999999",
                lineHeight: "1.4",
                letterSpacing: "-0.03em",
              }}
            >
              {user?.attributes?.sub === clientId ? (
                <span>{"Coach: " + (coachName ? coachName : "")}</span>
              ) : status === "assigned" && clientShared ? (
                <Box
                  component="span"
                  sx={{
                    cursor: 'pointer',
                    color: '#007bff',
                    '&:hover': {
                      color: '#0000FF', 
                    }
                  }}
                  onClick={() => navigate(`/platform/coach/coachee-overview/${clientId}`)}
                >
                  {"Coachee: " + clientShared}
                </Box>
              ) : (
                <span>{"Participant: " + (clientName ? clientName : "")}</span>
              )}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: {
                  xs: "14px",
                  md: "18px",
                },
                color: "#999999",
                lineHeight: "1.4",
                letterSpacing: "-0.03em",
              }}
            >
              Date: {formattedZoomDate}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: {
                  xs: "14px",
                  md: "18px",
                },
                color: "#999999",
                lineHeight: "1.4",
                letterSpacing: "-0.03em",
              }}
            >
              Duration {duration} mins
            </Typography>
  
            {userData?.accessTo !== "platform" ? null : (
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: {
                      xs: "14px",
                      md: "18px",
                    },
                    color: "#999999",
                    lineHeight: "1.4",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Call Status: {status === "assigned" ? "Shared" : "Ready to Share"}
                  {status === "assigned" && user?.attributes?.sub === clientId ? " to You" : ""}
                </Typography>
                <Box>
                  {status !== "assigned" &&
                    userData.accessTo === "platform" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          transition: "all 0.3s",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          if (status === "ReadyToPublish") {
                            setShowClientList(!showClientList);
                          }
                        }}
                      >
                        <ShareIcon />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#999999",
                          }}
                        >
                          Share
                        </Typography>
                      </Box>
                    )}
                  {showClientList && (
                    <Box
                      position="absolute"
                      zIndex={2}
                      sx={{
                        right: {
                          xs: "-10px",
                          lg: "unset",
                        },
                        top: {
                          xs: "30px",
                          lg: "unset",
                        },
                        marginLeft: {xs: "0", md: "-330px"},
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        width: "430px",
                        background: "#F8F9FA",
                        padding: "16px 28px",
                        borderRadius: "8px",
                        boxShadow:
                          "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                      }}
                      onMouseLeave={() => {
                        setShowClientList(false);
                      }}
                    >
                      <Box display="flex" flexDirection="column" gap="4px">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            sx={{
                              color: "#160042",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              letterSpacing: "-0.03",
                            }}
                          >
                            Who is this session being shared with?
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap="4px">
                          <ErrorOutline
                            sx={{
                              color: "#00B11C",
                              fontSize: "11px",
                              width: "11px",
                              height: "11px",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#6B7094",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              letterSpacing: "-0.01",
                            }}
                          >
                            Once confirmed, the recipient will be notified
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          background: "white",
                          maxHeight: "180px",
                          overflowY: "auto",
                        }}
                      >
                        {clientData && clientData.length > 0 ? (
                          clientData.map((client) =>
                            client.full_name ? (
                              <Box
                                key={client.user_id}
                                display="flex"
                                gap="12px"
                                alignItems="center"
                                padding="4px 12px"
                                borderRadius="4px"
                                sx={{
                                  background:
                                    client.full_name === assignee.name
                                      ? "#F5F5F5"
                                      : "initial",
                                  "&:hover": {
                                    background: "#F5F5F5",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() => {
                                  setAssignee({
                                    id: client.user_id,
                                    name: client.full_name,
                                  });
                                }}
                              >
                                <Box
                                  sx={{
                                    background:
                                      "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                    padding: "2px",
                                    borderRadius: "50%",
                                    width: "28px",
                                    height: "28px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      background: "white",
                                      padding: "1px",
                                      borderRadius: "50%",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                      src={client.avatar || avatar}
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  color="#1B114A"
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "300",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {client.full_name}
                                </Typography>
                              </Box>
                            ) : null
                          )
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "12px",
                            }}
                          >
                            <Typography
                              color="#1B114A"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Add your first client
                            </Typography>
                            <PersonAddAlt
                              sx={{
                                color: "black",
                                fontSize: "24px",
                                width: "24px",
                                height: "24px",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/platform/coach/leadership-center")}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          sx={{
                            paddingX: "20pt",
                            paddingY: "5pt",
                            borderRadius: "53px",
                            background: "#FFFFFF",
                            color: "#C10404",
                            border: "1px solid #BE0808",
                            fontWeight: "bold",
                            "&:hover": {
                              background: "rgba(190, 8, 8, 0.4)",
                              border: "1px solid rgba(190, 8, 8, 0.4)",
                              color: "white",
                            },
                          }}
                          onClick={() => setShowClientList(false)}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "10px",
                              fontWeight: "bold",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            Cancel
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            paddingX: "20pt",
                            paddingY: "5pt",
                            borderRadius: "52px",
                            color: "white",
                            background:
                              "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:disabled": {
                              backgroundColor: "#DEDEDE",
                              color: "#B7B7B7",
                            },
                            "&[disabled]": {
                              backgroundColor: "#DEDEDE !important",
                              color: "#B7B7B7 !important",
                            },
                          }}
                          disabled={!assignee.id}
                          onClick={openAssignModal}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "white",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            Confirm
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "30px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                gap: "20px",
                maxHeight: {xs: "100%", md: "50%"},
                marginTop: "30px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: {xs: "100%" , md: "50%"}
                }}
              >
                <VideoPlayerCard videoUrl={videoUrl}/>
                <Button
                sx={{
                  fontSize: "14px",
                  color: "#B3B3B3",
                  textTransform: "none",
                  minWidth: "unset",
                  display: {xs: "flex", md: "none"}
                }}
                onClick={() => downloadTranscript()}
              >
                Download transcript
              </Button>
              </Box>
              <Box
                sx={{
                  width: {xs: "100%" , md: "50%"},
                  maxHeight: "100%",
                  overflow: "auto",
                  background: "#EFF3F6",
                  borderRadius: "12px",
                  padding: "10px",
                  minHeight: "300px"
                }}
              >
                <SessionDetails 
                  user={user}
                  fileData={fileData}
                  quickRecap={quickRecap} 
                  setQuickRecap={setQuickRecap}
                  setCallActions={setCallActions}
                  actionsArray={actionsArray} 
                  labels={labels} 
                  summaries={summaries} 
                  clientName={clientName}
                  coachName={coachName}
                  clientShared={clientShared}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: {xs: "30px", lg: "0px"},
                width: "100%",
                justifyContent: 'center', 
                alignItems: 'center',
              }}
            >
              {loadingFile || !laraData ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress 
                    sx={{
                      color: "black"
                    }}
                  />
                </Box>  
              ) : (
                <LaraSessionPage downloadTranscript={downloadTranscript} laraData={laraData}/>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {editActionModal()}
      {editQuickRecapModal()}
      {assignModalContent()}
      {assignedModalContent()}
    </Box>
  );
};

export default SessionPage;

