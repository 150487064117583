import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { API, Storage} from "aws-amplify";
import { useSelector } from "react-redux";
import avatar from "assets/avatar.png";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import ClientCards from "components/CoachClientCenter/ClientCards";
import ActivityFeed from "components/CoachClientCenter/ActivityFeed";
import AddClientButton from "components/CoachClientCenter/AddClientButton";
import AddClientCenter from "components/CoachClientCenter/AddClientCenter";
import { usePayment } from "hooks/PaymentContext";
import PersonalTierUpgrade from "components/CoachClientCenter/PersonalTierUpgrade";
import ClientLimitReached from "components/ClientLimitReachedModal";

const ClientCenter = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [clientData, setClientData] = useState([]);
  const [clientStatusArray, setClientStatusArray] = useState([]);
  const [clientPicture, setClientPicture] = useState([]);
  const [filterEventItems, setFilterEventItems] = useState([{"key": "Event Type", "value": "none"}]);
  const [filterClientItems, setFilterClientItems] = useState([{"key": "Coachee", "value": "none"}]);
  const [filterParticipantItems, setFilterParticipantItems] = useState([{"key": "Participant", "value": "none"}]);
  const [loading, setLoading] = useState(true);
  const [loadingActivityData, setLoadingActivityData] = useState(false);
  const [menuShow, setMenuShow] = useState([]);
  const [activityData, setActivityData] = useState([]);


  const fetchClientData = async () => {
    setLoading(true);
    try {
      let response;
      const userId = user?.attributes?.sub;

      response = await API.get("zoomapi", "/zoom/fetch-client-by-coach-test", {
        queryStringParameters: {
          coach_id: userId,
        },
      });

      if (!response.client_data && !response.client_status_objects) {
        setLoading(false);
        return;
      }

      const data = response.client_data;
      const client_status_array = response.client_status_objects;
      const pictureData = [];
      data.map(async (row, index) => {
        row.avatar = row.avatar ? "https://d7joen7tb0xwx.cloudfront.net/public/" + row.avatar : null
        setMenuShow((prev) => [...prev, false]);
      });
      client_status_array.map(async(row, index) => {
        row.avatar = row.avatar ? "https://d7joen7tb0xwx.cloudfront.net/public/" + row.avatar : null
        setMenuShow((prev) => [...prev, false]);
      })


      setClientData(data);
      setClientStatusArray(client_status_array);
      setClientPicture(pictureData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }finally{
      setLoading(false)
    }
  };

  const fetchActivityData = async () => {
    setLoadingActivityData(true)
    try {
      const userId = user?.attributes?.sub;
      const response = await API.get("zoomapi", "/zoom/activityFeed", {
        queryStringParameters: {
          coach_id: userId,
        },
      });
      response.length > 0 &&
        response.sort(
          (a, b) => new Date(b.date_added) - new Date(a.date_added)
        );
      const eventItems = response.length === 0 ? [] : response.map((row) => row.event_type);
      const uniqueEvents = [...new Set(eventItems)];
      const formattedEvents = uniqueEvents.length === 1 && uniqueEvents[0] === null ? [] : uniqueEvents.map((row) => ({
        key: row.replace(/_/g, ' '), // Replace underscores with spaces
        value: row
      }));
      setFilterEventItems([{"key": "Event Type", "value": "none"}, ...formattedEvents]);

      const clientItems = response.length === 0 ? [] : response
      .filter(row => row.client_name ?? row.client)
      .map(row =>  row.client_name ?? row.client);
    
      const uniqueClients = [...new Set(clientItems)];
      const formattedClients = uniqueClients.length === 1 && uniqueClients[0] === null ? [] : uniqueClients.map(row => ({
        key: row,
        value: row
      }));
      setFilterClientItems([{ "key": "Coachee", "value": "none" }, ...formattedClients]);    

      const participantItems = response.length === 0 ? [] : response.filter(row => row.participant_name).map((row) => row.participant_name);
      const uniqueParticipants = [...new Set(participantItems)];
      const formattedParticipants = uniqueParticipants.length === 1 && uniqueParticipants[0] === null ? [] : uniqueParticipants.map((row) => ({
        key: row,
        value: row
      }));
      setFilterParticipantItems([{"key": "Participant", "value": "none"}, ...formattedParticipants]);

      setActivityData(response);
    } catch (error) {
      console.error("Error fetching activity data:", error);
    } finally{
      setLoadingActivityData(false)
    }
  };

  useEffect(() => {
    fetchClientData();
    fetchActivityData();
  }, []);

  if(userData.accessTo === 'platform_professional')
    return(
      <GlobalPageComponent user={user} accessTo={userData.accessTo}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PersonalTierUpgrade />
        </Box>
      </GlobalPageComponent>
      )

  return loading ? (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="EFF3F6"
    >
      <CircularProgress />
    </Box>
    </GlobalPageComponent>
  ) : (
    <>
    <GlobalPageComponent user={user}>
    <ClientLimitReached user={user} userData={userData}/>
    <Box
      sx={{
        backgroundColor: "#EFF3F6",
        width: "100%",
        padding: {
          xs: "16px",
          md: "32px 40px",
        },
        display: {
          xs: !collapsed ? "none" : "block",
        },
        overflowY: "auto"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {xs:"column", md:"row"},
          alignItems: "center",
          paddingBottom: "24px",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "36px",
            fontFamily: "Poppins",
            fontWeight: 300,
          }}
        >
          Leadership Center
        </Typography>
        {clientStatusArray.length !== 0 &&
          //button to add clients when there are current clients (on the top right corner)
          <AddClientButton 
            user={user}
            loading={loading}
            setLoading={setLoading}
            fetchClientData={fetchClientData}
          />
        }
      </Box>
      {clientStatusArray.length === 0 ? (
          <AddClientCenter 
            user={user}
            loading={loading}
            setLoading={setLoading}
            fetchClientData={fetchClientData}
          /> 
      ) : (
        // Render this block when clientData length is not 0
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: {
              xs: "column",
              lg: "row-reverse",
            },
            minHeight: "90%"
          }}
        >
          <ClientCards 
            user={user}
            clientData={clientData}
            clientStatusArray={clientStatusArray}
            loading={loading}
            setLoading={setLoading}
            fetchClientData={fetchClientData}
            avatar={avatar}
            clientPicture={clientPicture}
          />
          <ActivityFeed 
            activityData={activityData}
            avatar={avatar}
            clientPicture={clientPicture}
            filterEventItems={filterEventItems}
            filterClientItems={filterClientItems}
            filterParticipantItems={filterParticipantItems}
            loading={loadingActivityData}
          />
        </Box>
      )}
    </Box>                         
    </GlobalPageComponent>
    </>
  );
};

export default ClientCenter;
