import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const ClientHasInactiveCoachModal = ({ clientHasInactiveCoach, isLocked }) => {
  const [open, setOpen] = useState(clientHasInactiveCoach);

  useEffect(() => {
    setOpen(clientHasInactiveCoach);
  }, [clientHasInactiveCoach]);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      hideBackdrop={isLocked ? true : false}
      style={{ position: 'initial' }}
      aria-labelledby="inactive-coach-modal-title"
      aria-describedby="inactive-coach-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isLocked ? 600 : 400,
          height: isLocked ? '80%' : 'auto',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: '25px',
          zIndex: 9999, // Set a high zIndex here
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Center content horizontally
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: 'black',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            Inactive Coach Notification
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center', // Center the description
          }}
        >
          <Typography
            id="modal-description"
            sx={{
              color: 'black',
            }}
          >
            Your Coach has cancelled their subscription. <br />
            <br />
            Please upgrade to our 
            <a
              href="https://billing.stripe.com/p/login/dR6eWJ2bkfuobja000"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
              }}
            >
            Professional Tier 
            </a>
            in order to keep access to your recordings, goals, and actions.
          </Typography>
        </Box>
        {!isLocked && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button onClick={handleClose} disabled={isLocked}>
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ClientHasInactiveCoachModal;
