import React from "react";

function LaraIcon({ color }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5419 4.20658C12.7625 4.94766 12.3405 5.72721 11.5995 5.94777C8.89171 6.75363 6.75363 8.89171 5.94777 11.5995C5.72721 12.3405 4.94766 12.7625 4.20658 12.5419C3.46551 12.3214 3.04354 11.5418 3.2641 10.8008C4.33969 7.18671 7.18671 4.33969 10.8008 3.2641C11.5418 3.04354 12.3214 3.46551 12.5419 4.20658ZM15.4583 4.20658C15.6788 3.46551 16.4584 3.04354 17.1995 3.2641C20.8135 4.33969 23.6605 7.18671 24.7361 10.8008C24.9567 11.5418 24.5347 12.3214 23.7936 12.5419C23.0525 12.7625 22.273 12.3405 22.0524 11.5995C21.2466 8.89171 19.1085 6.75363 16.4008 5.94777C15.6597 5.72721 15.2377 4.94766 15.4583 4.20658ZM4.20658 15.4583C4.94766 15.2377 5.72721 15.6597 5.94777 16.4008C6.75363 19.1085 8.89171 21.2466 11.5995 22.0524C12.3405 22.273 12.7625 23.0525 12.5419 23.7936C12.3214 24.5347 11.5418 24.9567 10.8008 24.7361C7.18671 23.6605 4.33969 20.8135 3.2641 17.1995C3.04354 16.4584 3.46551 15.6788 4.20658 15.4583ZM23.7936 15.4583C24.5347 15.6788 24.9567 16.4584 24.7361 17.1995C23.6605 20.8135 20.8135 23.6605 17.1995 24.7361C16.4584 24.9567 15.6788 24.5347 15.4583 23.7936C15.2377 23.0525 15.6597 22.273 16.4008 22.0524C19.1085 21.2466 21.2466 19.1085 22.0524 16.4008C22.273 15.6597 23.0525 15.2377 23.7936 15.4583Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.8C13.2268 2.8 12.6 3.4268 12.6 4.2C12.6 4.9732 13.2268 5.6 14 5.6C14.7732 5.6 15.4 4.9732 15.4 4.2C15.4 3.4268 14.7732 2.8 14 2.8ZM9.8 4.2C9.8 1.8804 11.6804 0 14 0C16.3196 0 18.2 1.8804 18.2 4.2C18.2 6.5196 16.3196 8.4 14 8.4C11.6804 8.4 9.8 6.5196 9.8 4.2ZM4.2 12.6C3.4268 12.6 2.8 13.2268 2.8 14C2.8 14.7732 3.4268 15.4 4.2 15.4C4.9732 15.4 5.6 14.7732 5.6 14C5.6 13.2268 4.9732 12.6 4.2 12.6ZM0 14C0 11.6804 1.8804 9.8 4.2 9.8C6.5196 9.8 8.4 11.6804 8.4 14C8.4 16.3196 6.5196 18.2 4.2 18.2C1.8804 18.2 0 16.3196 0 14ZM19.6 14C19.6 11.6804 21.4804 9.8 23.8 9.8C26.1196 9.8 28 11.6804 28 14C28 16.3196 26.1196 18.2 23.8 18.2C21.4804 18.2 19.6 16.3196 19.6 14ZM23.8 12.6C23.0268 12.6 22.4 13.2268 22.4 14C22.4 14.7732 23.0268 15.4 23.8 15.4C24.5732 15.4 25.2 14.7732 25.2 14C25.2 13.2268 24.5732 12.6 23.8 12.6ZM14 22.4C13.2268 22.4 12.6 23.0268 12.6 23.8C12.6 24.5732 13.2268 25.2 14 25.2C14.7732 25.2 15.4 24.5732 15.4 23.8C15.4 23.0268 14.7732 22.4 14 22.4ZM9.8 23.8C9.8 21.4804 11.6804 19.6 14 19.6C16.3196 19.6 18.2 21.4804 18.2 23.8C18.2 26.1196 16.3196 28 14 28C11.6804 28 9.8 26.1196 9.8 23.8Z"
        fill={color}
      />
    </svg>
  );
}

export default LaraIcon;
