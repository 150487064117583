//this add client is for when there are zero current clients

import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { useState } from "react";
import vector from "assets/Vector2.png";
import AddIcon from "@mui/icons-material/Add";
import { Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";
import { useIncrementClientCount } from "hooks/useIncrementClientCount";
import { API } from "aws-amplify";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";

const AddClientCenter = ({ user, loading, setLoading, fetchClientData }) => {
    const [openAddClientModal, setOpenAddClientModal] = useState(false);
    const [loadingAddClient, setLoadingAddClient] = useState(false);
    const { checkClientInviteAllowance, loadingCounter, error } = useCheckClientInviteAllowance();
    const { incrementClientCount, loadingIncrement, errorIncrement } = useIncrementClientCount();
    const handleSubscriptionClick = () => {
      window.open('https://billing.stripe.com/p/login/dR6eWJ2bkfuobja000', '_blank');
    };
    const customToast = ({ closeToast }) => (
      <div onClick={handleSubscriptionClick} style={{ cursor: 'pointer' }}>
        Client invitation limit reached. Please{" "}
        <span
          style={{
            color: '#007bff',
            textDecoration: 'underline',
            cursor: 'pointer', 
            transition: 'color 0.3s', 
          }}
          onMouseOver={(e) => { e.target.style.color = '#0000FF'; }} 
          onMouseOut={(e) => { e.target.style.color = '#007bff'; }} 
        >
          upgrade
        </span>
        {" "}to invite more clients.
      </div>
    );
    const handleClose = () => {
      setFieldValue("first_name", ""); 
      setFieldValue("last_name", ""); 
      setFieldValue("client_email", ""); 
      setFieldValue("occupation", "");
      setFieldValue("phone_number", "");
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("zip_code", "");
      setOpenAddClientModal(false)
    }
    const { errors, touched, getFieldProps, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        client_email: "",
        occupation: "",
        phone_number: "",
        city: "",
        state: "",
        zip_code: "",
        coach_id: user?.attributes?.sub,
      },
      validationSchema: Yup.object().shape({
        first_name: openAddClientModal
          ? Yup.string().required("required")
          : null,
        last_name: openAddClientModal
          ? Yup.string().required("required")
          : null,
        client_email: openAddClientModal
          ? Yup.string().email("invalid email").required("required")
          : null,
      }),
      onSubmit: async (values) => {
        setLoading(true);
        setLoadingAddClient(true);
        try {
          const clientCounter = await checkClientInviteAllowance(user?.attributes?.sub);
          if (clientCounter.clientCount >= clientCounter.clientTierAllowance) {
            // Handle the error: client count exceeds allowance
            toast.error(customToast);
            setLoading(false);
            setLoadingAddClient(false);
            return;
          }
          if (openAddClientModal) {
            setOpenAddClientModal(false);
            const body = {
              coach_id: user?.attributes?.sub,
              id: uuid(),
              client_id: uuid(),
              coach_name: user?.attributes?.given_name + " " + user?.attributes?.family_name,
              coach_email: user?.attributes?.email,
              client_name: values.first_name + " " + values.last_name,
              client_first_name: values.first_name,
              client_last_name: values.last_name,
              call_name: "",
              zoom_date: new Date(Date.now()),
              duration_minutes: 0,
              client_email: values.client_email,
              client_occupation: values.occupation,
              client_phone: values.phone_number,
              client_city: values.city,
              client_state: values.state,
              client_zip_code: values.zip_code
            };
            setLoading(true);
            setLoadingAddClient(true);
            const response = await API.put("zoomapi", "/zoom/add_client", {
              body: body,
            });
            fetchClientData();
            setLoadingAddClient(false);
          }
          // increment clientCount in DB after successful client creation
          try {
            const { clientTierAllowance, clientCount } =
              await incrementClientCount(user?.attributes?.sub);
          } catch (error) {
            console.error("Failed to increment client count:", error);
          }
          toast(`Success! Your coachee, ${values.first_name}, was sent an invitation through email.`, {
            hideProgressBar: true,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });
        } catch (error) {
          if (error.response.data.message) {
            console.log("error: ", error);
            toast.error(error.response.data.message);
        } else {
            // If no specific error message received, show a generic error
            toast.error("An error occurred. Please try again.");
        }
        } finally {
          setLoading(false);
          setLoadingAddClient(false);
          setFieldValue("first_name", ""); 
          setFieldValue("last_name", ""); 
          setFieldValue("client_email", ""); 
          setFieldValue("occupation", "");
          setFieldValue("phone_number", "");
          setFieldValue("city", "");
          setFieldValue("state", "");
          setFieldValue("zip_code", "");
        }
      },      
    });

    const addClientModal = (
      <Modal
        open={openAddClientModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "white",
            color: "black",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            maxWidth: "calc(100% - 40px)",
            maxHeight: {
              xs: "calc(100% - 50px)",
              md: "80%",
            },
            overflowY: "hidden",
            gap: {
              xs: "24px",
              md: "unset",
            },
            boxShadow: 24,
            borderRadius: "30px",
            padding: {
              xs: "24px",
              md: "64px",
            },
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: {
              md: "space-between",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
              }
            }}
          >
            <Typography
               sx={{
                fontFamily: "Lexend",
                maxWidth: {
                  md: "350px",
                },
                fontSize: "36px",
                color: "black",
              }}
            >
              Add Your Coachee Information
            </Typography>
            <Typography
              sx={{
                maxWidth: {
                  lg: "280px",
                },
                fontSize: "14px",
                lineHeight: "1.5",
                letterSpacing: "0.3px",
                color: "black",
              }}
            >
              Create a new coachee profile to add them to your coachee roster.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: {
                xs: "100%",
                lg: "50%",
              },
              maxWidth: {
                xs: "100%",
                lg: "340px",
              },
            }}
          >
            <Box
              sx={{
                overflowY: "auto",
                paddingY: "16px",
                paddingRight: "16px"
              }}
            >
              <form>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        First Name
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        autoFocus
                        error={touched.first_name && Boolean(errors.first_name)}
                        {...getFieldProps("first_name")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                        focused={touched.first_name}
                      />
                      {touched.first_name && errors.first_name && 
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#BE0808",
                            letterSpacing: "2px",
                            fontSize: "8px",
                            fontWeight: "bold",
                            textTransform: "uppercase"       
                          }}
                        >
                          {touched.first_name && errors.first_name}
                        </Typography>
                      }
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        Last Name
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        autoFocus
                        error={touched.last_name && Boolean(errors.last_name)}
                        {...getFieldProps("last_name")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                        focused={touched.last_name}
                      />
                      {touched.last_name && errors.last_name && 
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#BE0808",
                            letterSpacing: "2px",
                            fontSize: "8px",
                            fontWeight: "bold",
                            textTransform: "uppercase"       
                          }}
                        >
                          {touched.last_name && errors.last_name}
                        </Typography>
                      }
                    </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "uppercase"
                      }}
                    >
                      Email
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      autoFocus
                      error={touched.client_email && Boolean(errors.client_email)}
                      {...getFieldProps("client_email")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                      focused={touched.client_email}
                    />
                    {touched.client_email && errors.client_email && 
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#BE0808",
                          letterSpacing: "2px",
                          fontSize: "8px",
                          fontWeight: "bold",
                          textTransform: "uppercase"       
                        }}
                      >
                        {touched.client_email && errors.client_email}
                      </Typography>
                    }
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "uppercase"
                      }}
                    >
                      Phone
                    </Typography>
                    <TextField
                      fullWidth
                      autoFocus
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      {...getFieldProps("phone_number")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                      focused={touched.phone_number}
                    />
                    {touched.phone_number && errors.phone_number && 
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#BE0808",
                          letterSpacing: "2px",
                          fontSize: "8px",
                          fontWeight: "bold",
                          textTransform: "uppercase"       
                        }}
                      >
                        {touched.phone_number && errors.phone_number}
                      </Typography>
                    }
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "uppercase"
                      }}
                    >
                      Occupation
                    </Typography>
                    <TextField
                      fullWidth
                      autoFocus
                      error={touched.occupation && Boolean(errors.occupation)}
                      {...getFieldProps("occupation")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                      focused={touched.occupation}
                    />
                    {touched.occupation && errors.occupation && 
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#BE0808",
                          letterSpacing: "2px",
                          fontSize: "8px",
                          fontWeight: "bold",
                          textTransform: "uppercase"       
                        }}
                      >
                        {touched.occupation && errors.occupation}
                      </Typography>
                    }
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "24px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        City
                      </Typography>
                      <TextField
                        fullWidth
                        autoFocus
                        error={touched.city && Boolean(errors.city)}
                        {...getFieldProps("city")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                        focused={touched.city}
                      />
                      {touched.city && errors.city && 
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#BE0808",
                            letterSpacing: "2px",
                            fontSize: "8px",
                            fontWeight: "bold",
                            textTransform: "uppercase"       
                          }}
                        >
                          {touched.city && errors.city}
                        </Typography>
                      }
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        State
                      </Typography>
                      <TextField
                        fullWidth
                        autoFocus
                        error={touched.state && Boolean(errors.state)}
                        helperText={touched.state && errors.state}
                        {...getFieldProps("state")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                        focused={touched.state}
                      />
                      {touched.state && errors.state && 
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#BE0808",
                            letterSpacing: "2px",
                            fontSize: "8px",
                            fontWeight: "bold",
                            textTransform: "uppercase"       
                          }}
                        >
                          {touched.state && errors.state}
                        </Typography>
                      }
                    </Box>
                  </Box>
                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase"
                        }}
                      >
                        Zip Code
                      </Typography>
                      <TextField
                        fullWidth
                        autoFocus
                        error={touched.zip_code && Boolean(errors.zip_code)}
                        {...getFieldProps("zip_code")}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                        focused={touched.zip_code}
                      />
                      {touched.zip_code && errors.zip_code && 
                        <Typography
                          variant="caption"
                          color="error"
                        >
                          {touched.city && errors.zip_code}
                        </Typography>
                      }
                    </Box>
                </Box>
              </form>
            </Box>
              <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton 
                    onClick={handleClose}
                    label={"Cancel"}
                  />
                  <PositiveActionButton 
                    onClick={(e) => {
                        e.preventDefault();
                        setLoadingAddClient(true);
                        handleSubmit();
                    }}
                    label="Add Coachee"
                    loading={loadingAddClient}
                    />
              </Box>
          </Box>
        </Box>
      </Modal>
    );

    return(
        <>
        {addClientModal}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "50%",
            left: {xs: "50%", lg: "60%", xl:"58%"},
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: "840px",
              maxWidth: {
                xs: "calc(100% - 32px)",
                md: "calc(100% - 40px)",
              },
              padding: "56px",
              background:
                  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
              borderRadius: "40px",
              display: "flex",
              alignItems: {
                xs: "flex-start",
              },
              flexDirection: {
                xs: "column",
              },
              justifyContent: "space-between",
              gap: {
                xs: "40px",
                md: "0",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  xl: "50%",
                },
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "300",
                  fontSize: "32px",
                  fontFamily: "Montserrat",
                }}
              >
                Add Your First Coachee
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  lineHeight: "1.5",
                  letterSpacing: "0.3px",
                }}
              >
                Create a new coachee profile to add them to your coachee roster.
              </Typography>
              <Box
              sx={{
                width: {
                  xs: "100%",
                  xl: "50%",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "flex-start",
                },
                zIndex: "1",
                marginTop: "100px"
              }}
            >
              <Button
                onClick={() => setOpenAddClientModal(true)}
                sx={{
                  width: "218px",
                  padding: "10px 0",
                  background: "white",
                  border: "1px solid white",
                  borderRadius: "52px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  transition: "all 0.3s",
                  "&:hover": {
                    background: "black",
                    "& *": {
                      color: "white",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "#262626",
                    fontSize: "10px",
                    fontWeight: "700",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    textTransform: "uppercase",
                  }}
                >
                  Add a Coachee
                </Typography>
              </Button>
            </Box>
            </Box>
            <Box
              component="img"
              src={vector}
              alt="vector"
              width={{ xs: "380px" }}
              sx={{
                position: "absolute",
                marginLeft: "450px",
                bottom: -5,
                zIndex: "0",
                display: {xs:"none", md:"inline"}
              }}
            />
          </Box>
        </Box>
        </>
    )
}

export default AddClientCenter