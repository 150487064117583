import React from "react";

function Dashboard({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M15.1667 8.37254C15.1667 11.6667 14.5833 11.6667 9.33333 11.6667C4.08333 11.6667 3.5 11.6667 3.5 8.16666C3.5 4.66666 4.08333 4.66666 9.33333 4.66666C14.5833 4.66665 15.1667 4.66665 15.1667 8.37254Z"
        fill={color}
        stroke={color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8333 19.6275C12.8333 16.3333 13.4167 16.3333 18.6667 16.3333C23.9167 16.3333 24.5 16.3333 24.5 19.8333C24.5 23.3333 23.9167 23.3333 18.6667 23.3333C13.4167 23.3333 12.8333 23.3333 12.8333 19.6275Z"
        fill={color}
        stroke={color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 8.37254C24.5 11.6667 24.0333 11.6667 22.1667 11.6667C20.3 11.6667 19.8333 11.6667 19.8333 8.16666C19.8333 4.66666 20.3 4.66666 22.1667 4.66666C24.2083 4.66666 24.5 4.66666 24.5 8.37254Z"
        fill={color}
        stroke={color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.49999 19.6275C3.49999 16.3333 3.96666 16.3333 5.83332 16.3333C7.69999 16.3333 8.16665 16.3333 8.16666 19.8333C8.16666 23.3333 7.69999 23.3333 5.83332 23.3333C3.79166 23.3333 3.49999 23.3333 3.49999 19.6275Z"
        fill={color}
        stroke={color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dashboard;
