import { Box, Button, Divider, Typography } from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useSelector } from "react-redux";
import { _CoachLaraFilter, _CoachLaraFilterForClient } from "components/CoachLaraAnalytics/CoachLaraFilter";
import CoachLaraPieCharts from "components/CoachLaraAnalytics/CoachLaraPieCharts";
import CoachLaraLineChart from "components/CoachLaraAnalytics/CoachLaraLineChart";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getLaraByCallIdQuery = `query getLara($call_id: ID!) {
  getLara(call_id: $call_id) {
    call_id
    coach_id
    coachee_id
    coach_name
    coachee_name
    coach_overall_score
    coach_listen_score
    coach_listen_report
    coach_listen_area_of_improvement
    coach_affirm_score
    coach_affirm_report
    coach_affirm_area_of_improvement
    coach_respond_score
    coach_respond_report
    coach_respond_area_of_improvement
    coach_ask_score
    coach_ask_report
    coach_ask_area_of_improvement
    coachee_overall_score
    coachees {
      coachee_id
      coachee_name
      overall_score
      listen_score
      listen_report
      listen_area_of_improvement
      affirm_score
      affirm_report
      affirm_area_of_improvement
      respond_score
      respond_report
      respond_area_of_improvement
      ask_score
      ask_report
      ask_area_of_improvement
    }
  }
}`;

const CoachLaraAnalytics = ({ user, userData }) => {
  const query = useQuery();
  const clientNameQuery = query.get("clientName");
  const [laraChartFileData, setLaraChartFileData] = useState([]);
  const [filteredLaraPiesFileData, setFilteredLaraPiesFileData] = useState([]);
  const [filteredLaraLineChartFileData, setFilteredLaraLineChartFileData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Filters for LaraPies
  const [filterCoachLaraPiesItems, setFilterCoachLaraPiesItems] = useState([{ key: "Coach", value: "none" }]);
  const [filterCoachLaraPies, setFilterCoachLaraPies] = useState("none");
  const [filterClientLaraPiesItems, setFilterClientLaraPiesItems] = useState([{ key: "Client", value: "none" }]);
  const [filterClientLaraPies, setFilterClientLaraPies] = useState("none");
  const [piesChartLaraCount, setPiesChartLaraCount] = useState("-");

  // Filters for LaraLineChart
  const [filterCoachLaraLineItems, setFilterCoachLaraLineItems] = useState([{ key: "Coach", value: "none" }]);
  const [filterCoachLaraLine, setFilterCoachLaraLine] = useState("none");
  const [filterClientLaraLineItems, setFilterClientLaraLineItems] = useState([{ key: "Client", value: "none" }]);
  const [filterClientLaraLine, setFilterClientLaraLine] = useState("none");
  const [lineChartLaraCount, setLineChartLaraCount] = useState("-")

  const fetchCallData = async () => {
    setLoading(true);
    try {

      let response = [];
      const coachResponse = await API.get("zoomapi", "/zoom/fetch-data", {
        queryStringParameters: { coach_id: user?.attributes?.sub },
      });

      if (coachResponse && coachResponse.data) {
        response = coachResponse.data;
      }

      if (userData.isClient) {
        const clientCallsResponse = await API.get("zoomapi", "/zoom/fetch-coach", {
          queryStringParameters: { client_id: user?.attributes?.sub },
        });

        if (clientCallsResponse && clientCallsResponse.data) {
          response = [...(response || []), ...clientCallsResponse.data];
        }
      }

      console.log("response for lara: ", response)
    
      if (response.length === 0) {
        return;
      }

      response.sort((a, b) => new Date(b.zoom_date) - new Date(a.zoom_date));

      let parsedChartFileData = await Promise.all(
        response.map(async (row) => {
          let filteredLara = {
            coach: [],
            client: []
          };
  
          try {
            const laraResult = await API.graphql(
              graphqlOperation(getLaraByCallIdQuery, { call_id: row.id })
            );
  
            const laraData = laraResult.data.getLara;
  
            // Check for both coach and client
            if (laraData.coach_id === row.coach_id) {
              filteredLara.coach.push({
                listen_score: laraData.coach_listen_score,
                affirm_score: laraData.coach_affirm_score,
                respond_score: laraData.coach_respond_score,
                ask_score: laraData.coach_ask_score,
              });
            }
  
            const clientLara = laraData.coachees.find(
              (c) => c.coachee_name === row.client_name
            );

  
            if (clientLara) {
              filteredLara.client.push({
                listen_score: clientLara.listen_score,
                affirm_score: clientLara.affirm_score,
                respond_score: clientLara.respond_score,
                ask_score: clientLara.ask_score,
              });
            }
  
            row.coachLaraData = filteredLara.coach;
            row.clientLaraData = filteredLara.client;
  
            // Calculate overall score for each row
            if (row.clientLaraData.length > 0) {
              row.clientOverallScore = Math.round(
                ((row.clientLaraData[0].listen_score + row.clientLaraData[0].affirm_score + row.clientLaraData[0].respond_score + row.clientLaraData[0].ask_score) / 4) * 20
              );
            }
  
            if (row.coachLaraData.length > 0) {
              row.coachOverallScore = Math.round(
                ((row.coachLaraData[0].listen_score + row.coachLaraData[0].affirm_score + row.coachLaraData[0].respond_score + row.coachLaraData[0].ask_score) / 4) * 20
              );
            }
          } catch (error) {
            console.error("Failed to fetch LARA data for call_id:", row.id, error);
          }
  
          return { ...row, lara: filteredLara };
        })
      );
  
  

      setLaraChartFileData(parsedChartFileData);

      const coachItems = response
        .filter((row) => row.coach_name !== null)
        .map((row) => row.coach_name);

      const uniqueCoach = [...new Set(coachItems)];
      const formattedCoach = uniqueCoach.map((row) => ({
        key: row,
        value: row,
      }));
      setFilterCoachLaraPiesItems([{ key: "Coach", value: "none" }, ...formattedCoach]);
      setFilterCoachLaraLineItems([{ key: "Coach", value: "none" }, ...formattedCoach]);

      const clientItems = response
        .filter((row) => row.client !== null)
        .map((row) => row.client);

      const uniqueClients = [...new Set(clientItems)];
      const formattedClients = uniqueClients.map((row) => ({
        key: row,
        value: row,
      }));
      setFilterClientLaraPiesItems([{ key: "Client", value: "none" }, ...formattedClients]);
      setFilterClientLaraLineItems([{ key: "Client", value: "none" }, ...formattedClients]);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCallData();
  }, []);

  useEffect(() => {
    let filteredPiesData = laraChartFileData;
    if (filterCoachLaraPies !== "none") {
      filteredPiesData = filteredPiesData.filter((item) => item.coach_name === filterCoachLaraPies);
    }
    if (filterClientLaraPies !== "none") {
      filteredPiesData = filteredPiesData.filter((item) => item.client === filterClientLaraPies);
    }
  
    // Calculate the number of valid files where lara is not null
    const validFilesCount = filteredPiesData.reduce((count, item) => {
      return item.lara !== null ? count + 1 : count;
    }, 0);
  
    setFilteredLaraPiesFileData(filteredPiesData);
    setPiesChartLaraCount(validFilesCount); // Assuming setPiesChartLaraCount is the correct state setter
  }, [filterCoachLaraPies, filterClientLaraPies, laraChartFileData]);
  

  useEffect(() => {
    let filteredLineData = laraChartFileData;
    if (filterCoachLaraLine !== "none") {
      filteredLineData = filteredLineData.filter((item) => item.coach_name === filterCoachLaraLine);
    }
    if (filterClientLaraLine !== "none") {
      filteredLineData = filteredLineData.filter((item) => item.client === filterClientLaraLine);
    }
  
    // Calculate the number of valid files where lara is not null
    const validFilesCount = filteredLineData.reduce((count, item) => {
      return item.lara !== null ? count + 1 : count;
    }, 0);
  
    setFilteredLaraLineChartFileData(filteredLineData);
    setLineChartLaraCount(validFilesCount); // Assuming setLineChartLaraCount is the correct state setter
  }, [filterCoachLaraLine, filterClientLaraLine, laraChartFileData]);
  

  useEffect(() => {
    if (clientNameQuery) {
      setFilterClientLaraPies(clientNameQuery);
      setFilterClientLaraLine(clientNameQuery);
    }
  }, [clientNameQuery]);

  const handleResetLaraPiesFilters = () => {
    setFilterCoachLaraPies("none");
    setFilterClientLaraPies("none");
  };

  const handleResetLaraChartFilters = () => {
    setFilterCoachLaraLine("none");
    setFilterClientLaraLine("none");
  };

  const handleCoachLaraPiesFilterChange = (value) => {
    setFilterCoachLaraPies(value);
  };

  const handleClientLaraPiesFilterChange = (value) => {
    if (value === "by client") {
      setFilterClientLaraPies("by client");
    } else {
      setFilterClientLaraPies(value);
    }
  };

  const handleCoachLaraLineFilterChange = (value) => {
    setFilterCoachLaraLine(value);
  };

  const handleClientLaraLineFilterChange = (value) => {
    if (value === "by client") {
      setFilterClientLaraLine("by client");
    } else {
      setFilterClientLaraLine(value);
    }
  };

  const collapsed = useSelector((state) => state.layout.collapsed);

  const handleClick = () => {
    window.open("/blog?title=Enhancing%20Communication%20and%20Coaching%20Through%20the%20Power%20of%20the%20LARA%20Framework&id=21#:~:text=The%20LARA%20score%20is%20calculated%20based", "_blank");
  };

  return (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <ClientLimitReached user={user} userData={userData}/>
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: {
            xs: "16px",
            md: "32px 40px",
          },
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "36px",
              fontWeight: 300,
              marginBottom: "16px",
            }}
          >
            LARA Analytics
          </Typography>
          <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: {
                      xs: "unset",
                      md: "200px",
                    },
                    zIndex: 1100,
                    position: "relative",
                    paddingBottom: "24px",
                    alignSelf: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <Button
                    sx={{
                      display: " flex",
                      width: {
                        xs: "100%",
                        lg: "220px",
                      },
                      padding: "10px 8px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: " 10px",
                      borderRadius: "52px",
                      border: "1px solid black",
                      borderColor: "black",
                      background: "white",
                      opacity: 1,
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={handleClick}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "black",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        letterSpacing: "2px",
                        lineHeight: "1.5",
                        textTransform: "uppercase",
                      }}
                    >
                      Learn More
                    </Typography>
                    {ArrowForwardBlack()}
                  </Button>
                </Box>
        </Box>
        <Box
          sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: {
              xs: "30px",
              md: "50px",
            },
            paddingY: {
              xs: "30px",
              md: "30px",
            },
            maxHeight: { md: "calc(100vh - 100px)" },
            height: "95%",
            overflow: "auto",
            width: "100%",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                Overall LARA Score
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: {xs: "0", md: "10px"}
                  }}
                >
                  Sessions: {piesChartLaraCount}
                </Typography>
                <_CoachLaraFilter
                  value={filterCoachLaraPies}
                  items={filterCoachLaraPiesItems}
                  handleChange={(e) => handleCoachLaraPiesFilterChange(e)}
                />
                <_CoachLaraFilterForClient
                  value={filterClientLaraPies}
                  items={filterClientLaraPiesItems}
                  handleChange={(e) => handleClientLaraPiesFilterChange(e)}
                  type = "client"
                />
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F7F7F7",
                    },
                  }}
                  onClick={handleResetLaraPiesFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>
            <CoachLaraPieCharts laraData={filteredLaraPiesFileData} loading={loading} filterCoachLaraPies={filterCoachLaraPies} filterClientLaraPies={filterClientLaraPies}/>
            <Divider color={"#DDE5ED"} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                Score Comparison
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: {xs: "0", md: "10px"}
                  }}
                >
                  Sessions: {lineChartLaraCount}
                </Typography>
                <_CoachLaraFilter
                  value={filterCoachLaraLine}
                  items={filterCoachLaraLineItems}
                  handleChange={(e) => handleCoachLaraLineFilterChange(e)}
                />
                <_CoachLaraFilterForClient
                  value={filterClientLaraLine}
                  items={filterClientLaraLineItems}
                  handleChange={(e) => handleClientLaraLineFilterChange(e)}
                  type = "client"
                />
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#F7F7F7",
                    },
                  }}
                  onClick={handleResetLaraChartFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CoachLaraLineChart laraData={filteredLaraLineChartFileData} loading={loading} filterCoachLaraLine={filterCoachLaraLine} filterClientLaraLine={filterClientLaraLine}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default CoachLaraAnalytics;
