import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import "../../index.css";
import { useLocation, useNavigate } from "react-router-dom";

import Logo2 from "scenes/Layout/logohorizontal";
import Logo from "scenes/Layout/logo";
import logoImg from "assets/logo.png";


import { ReactComponent as LogoSquare } from "assets/AltercallLogoSquare.svg";
import { getAuthRole } from "helpers/authHelper";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import AvatarForHeader from "components/Header/AvatarOfAuthedUser";
import { useSignOut } from "hooks/useSignOut";

import AppsIcon from "@mui/icons-material/Apps";
import GlobalSearchbar from "components/GlobalSearchbar/GlobalSearchbar";

import { navItems } from "../GlobalSidebar/NavigationItems.jsx";

export const Header = ({
  type,
  hideLogo,
  hideGlobalSearchbar,
  hasRightSidebar,
  typeOfNav,
}) => {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = useSignOut();

  const goToASectionOfHomePage = (sectionClassName) => {
    navigate(`/?sectionClassName=${sectionClassName}`);
  };

  const navigate = useNavigate();
  const location = useLocation();
  // const isLoginRoute =
  //   location.pathname === "/coaching-signin" ||
  //   location.pathname === "/" ||
  //   location.pathname === "/signin";

  const isVirtualSignup = location.pathname === "/virtual-signup";
  const isCoachingSignup = location.pathname === "/signup";

  // Hamburger Menu (Mobile)
  const [rightSideBar, setRightSideBar] = useState({
    right: false,
  });
  const [leftSideBar, setLeftSideBar] = useState({
    left: false,
  });

  const toggleRightSidebar = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setRightSideBar({ ...rightSideBar, [anchor]: open });
  };

  const toggleLeftSidebar = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLeftSideBar({ ...leftSideBar, [anchor]: open });
  };

  // Sidebar Nav Items
  const items = navItems[typeOfNav] || [];
  const handleItemClick = (item) => {
    if (item.url) {
      if (item.needsHardRefresh) {
        window.location.href = item.url;
      } else {
        navigate(item.url);
      }
    } else if (item.action === "handleSignOut") {
      handleSignOut();
    }
  };

  const mobileNavItemsNOAUTH = [
    {
      id: 0,
      title: "Benefits",
      url: "/benefits",
      action: null,
    },
    {
      id: 1,
      title: "Products",
      url: "/products",
      action: null,
    },
    {
      id: 2,
      title: "Pricing",
      url: "/pricing",
      action: null,
    },
    {
      id: 3,
      title: "Sign Up",
      url: "/signup",
      action: null,
    },
    {
      id: 4,
      title: "Join the Discord",
      url: "https://discord.gg/6g4qbQbtbd",
      action: null,
    },
    {
      id: 5,
      title: "Sign In",
      url: "/signin",
      action: null,
    },
  ];



  const renderNavItem = (item) => {
    const content = (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          cursor: "pointer",
          borderRadius: "10px",
          width: "100%",
          height: "60px",
          border: "1px solid #b3b3b389",
          marginTop: "10px",
          boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.15)",
          "&:hover": {
            background:
              "linear-gradient(330deg, #242e8c 13.07%, #03093a 43.61%, #D8659B 89.22%)",
            "& .MuiTypography-root": {
              color: "white",
            },
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              sm: "15px",
              md: "20px",
              lg: "20px",
            },
            fontWeight: 600,
            color: "black",
            textTransform: "none",
          }}
        >
          {item.title ? item.title : item.text}
        </Typography>
      </Box>
    );

    if (item.url) {
      return (
        <a href={item.url} key={item.id} style={{ textDecoration: "none" }}>
          {content}
        </a>
      );
    } else if (item.action) {
      return (
        <div
          onClick={item.action}
          key={item.id}
          style={{ textDecoration: "none" }}
        >
          {content}
        </div>
      );
    }
  };

  return (
    <Box
      id="HeaderContainer"
      sx={{
        zIndex: 1000,
        height: "8vh",
        borderBottom: {
          xs: "1px solid #DCDCDC",
          sm: "1px solid #DCDCDC",
          md: "none",
          // lg: "1px solid #DCDCDC",
          // lg: "none",
        },
        justifyContent: {
          xs: "end",
          sm: "end",
          md: "end",
          lg: !user ? "end" : "space-between",
        },
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        padding: {
          xs: "20px 30px",
          lg: "20px 60px",
        },
        backgroundColor: "#FFF",
        width: "100vw",
        position: "sticky",
        top: 0,
      }}
    >
      <Box
        sx={{
          display: user ? "" : "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <IconButton
            id="LeftSidebar"
            onClick={toggleLeftSidebar("left", true)}
            sx={{
              display: {
                xs: "",
                sm: "",
                md: "",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <MenuIcon
              sx={{
                width: "45px",
                height: "45px",
                color: "gray",
                transition: "0.2s all ease-in-out",
                cursor: "pointer",
                padding: "8px",
                "&:hover": {
                  filter: "brightness(1.3)",
                  transition: "0.2s all ease-in-out",
                  borderRadius: "360px",
                  boxShadow: "0 0 10px 3px #61EEF2",
                },
              }}
            />
          </IconButton>

          {!hideLogo && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                marginTop: "40px",
              }}
            >
              <Logo2 />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        id="LogoBoxBig"
        sx={{
          display: !user ? "" : "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {!hideLogo && (
            <Box
              sx={{
                display: {
                  xs: !user ? "flex" : "none",
                  sm: !user ? "flex" : "none",
                  md: !user ? "flex" : "none",
                  lg: "flex",
                  xl: "flex",
                },
                left: "0px",
                position: !user ? "absolute" : "relative",
                top: !user && "20px",
                height: "65px",
                marginTop: "20px",
              }}
            >
              <Logo2 />
            </Box>
          )}
        </Box>
      </Box>

      <Drawer
        anchor={"left"}
        open={leftSideBar["left"]}
        onClose={toggleLeftSidebar("left", false)}
      >
        <Box
          sx={{
            width: {
              xs: "100vw",
              sm: "50vw",
              md: "50vw",
              lg: "40vw",
              xl: "30vw",
            },
            minWidth: {
              md: "350px",
            },
            maxWidth: "500px",
            height: "100%",
            background:
              "linear-gradient(130deg, #ffffff 13.07%,  #ededed 89.22%)",
          }}
        >
          <HighlightOffIcon
            sx={{
              fontSize: "50pt",
              color: "#C1C1C1",
              marginTop: "10px",
              right: "10px",
              position: "absolute",
              cursor: "pointer",
              "&:hover": {
                color: "#878787",
              },
            }}
            onClick={toggleLeftSidebar("left", false)}
          />
          <Box
            sx={{
              width: "100%",
              top: "50%",
              transform: "translateY(-50%)",
              position: "absolute",
              paddingLeft: "20%",
              paddingRight: "20%",
            }}
          >
            {!user
              ? mobileNavItemsNOAUTH?.map(renderNavItem)
              : user
                ? items?.map(renderNavItem)
                : null}
          </Box>
          <Box
            component="img"
            src={logoImg}
            alt="logo"
            height={{ xs: "40px", lg: "60px" }}
            sx={{
              bottom: "20px",
              position: "absolute",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </Box>
      </Drawer>

      <Box
        id="LogoBox"
        sx={{
          display: {
            xs: !user ? "none" : "flex",
            sm: !user ? "none" : "flex",
            md: hasRightSidebar === true ? "none" : !user ? "none" : "flex",
            lg: "none",
            xl: "none",
          },
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "calc(100% - 115px)",
          left: !user ? "20px" : "auto",
          position: !user ? "absolute" : "relative",
          top: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: !user ? "0px" : "",
            xl: !user ? "0px" : "",
          },
        }}
      >
        {user ? <LogoSquare height="40px" width="40px" /> : <Logo />}
      </Box>

      {user && hideGlobalSearchbar !== true && <GlobalSearchbar />}

      <Box
        id="HamburgerMenu"
        right="20px"
        position="absolute"
        display={{
          xs: "inherit",
          sm: "inherit",
          md: "inherit",
          lg: `${isCoachingSignup ? "inherit" : "none"}`,
        }}
      >
        {user && (
          <Box
            sx={{
              display: {
                xs: "inherit",
                sm: "inherit",
                md: "inherit",
                lg: "inherit",
              },
            }}
          >
            <AvatarForHeader
              initialToShow={
                user?.attributes.given_name
                  ? user.attributes.given_name.charAt(0).toUpperCase()
                  : ""
              }
            />
          </Box>
        )}

        <Drawer
          anchor={"right"}
          open={rightSideBar["right"]}
          onClose={toggleRightSidebar("right", false)}
        >
          <Box
            sx={{
              width: {
                xs: "100vw",
                sm: "50vw",
                md: "50vw",
                lg: "40vw",
                xl: "30vw",
              },
              minWidth: {
                md: "350px",
              },
              maxWidth: "500px",
              height: "100%",
              background:
                "linear-gradient(130deg, #ffffff 13.07%,  #ededed 89.22%)",
            }}
          >
            <HighlightOffIcon
              sx={{
                fontSize: "50pt",
                color: "#C1C1C1",
                marginTop: "10px",
                right: "10px",
                position: "absolute",
                cursor: "pointer",
                "&:hover": {
                  color: "#878787",
                },
              }}
              onClick={toggleRightSidebar("right", false)}
            />
            <Box
              sx={{
                width: "100%",
                top: "50%",
                transform: "translateY(-50%)",
                position: "absolute",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              {!user && mobileNavItemsNOAUTH?.map(renderNavItem)}
            </Box>
            <Box
              component="img"
              src={logoImg}
              alt="logo"
              height={{ xs: "40px", lg: "60px" }}
              sx={{
                bottom: "20px",
                position: "absolute",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          </Box>
        </Drawer>
      </Box>

      <Box className="right-header" display={"flex"} alignItems={"center"}>
        <Button
          // onClick={scrollToHowWeHelp}
          onClick={() => goToASectionOfHomePage("benefits")}
          sx={{
            marginLeft: "20px",
            color: "black",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          Benefits
        </Button>

        <Button
          onClick={() => goToASectionOfHomePage("products")}
          sx={{
            marginLeft: "20px",
            color: "black",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          Products
        </Button>

        <Button
          onClick={() => goToASectionOfHomePage("pricing")}
          sx={{
            marginLeft: "20px",
            color: "black",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          Pricing
        </Button>

        <Button
          onClick={() => {
            navigate("/blogs");
          }}
          sx={{
            marginLeft: "20px",
            color: "black",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          Blog
        </Button>

        <Button
          onClick={() => {
            navigate("/resources");
          }}
          sx={{
            marginLeft: "20px",
            color: "black",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          Resources
        </Button>

        {!isVirtualSignup &&
          location.pathname !== "/bot" &&
          type !== "platform" && (
            <a
              href="https://discord.gg/6g4qbQbtbd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "100px",
                  alignItems: "center",
                  padding: "7px 15px",
                  background: "black",
                  textDecoration: "none",
                  width: "182px",
                  height: { xs: "30px", md: "40px" },
                  marginLeft: "20px",
                  transition: "0.5s all ease-in-out",
                  "&:hover": {
                    filter: "brightness(2)",
                    transition: "0.5s all ease-in-out",
                    cursor: "pointer",
                  },
                }}
              >
                <Box
                  sx={{
                    width: { xs: "20px", md: "25px" },
                    height: { xs: "20px", md: "25px" },
                    marginRight: { xs: "8px", md: "15px" },
                    borderRadius: "43px",
                  }}
                >
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 245 240"
                    fill="white"
                  >
                    <path
                      className="st0"
                      d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                    />
                    <path
                      className="st0"
                      d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                    />
                  </svg>
                </Box>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '14px !important',
                    fontFamily: 'Lexend !important',
                    color: 'inherit',
                    lineHeight: 'normal !important',
                  }}
                >
                  Join the Discord
                </Typography>              </Box>
            </a>
          )}
        <Button
          onClick={() => {
            window.location.href = "/signup";
          }}
          sx={{
            fontSize: "16px",
            marginLeft: "20px",
            marginRight: "20px",
            borderRadius: "43px",
            backdropFilter: "blur(25px)",
            textTransform: "none",
            padding: "0px",
            background:
              "linear-gradient(to left, #000000 23.07%, #578FF2 99.22%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            border: "1px solid #d3d3d3",
            display: { xs: "none", md: "block" },
            height: {
              xs: "30px",
              md: "40px",
            },
            lineHeight: "1.3",
            width: "120px",
            transition: "0.5s all ease-in-out",
            "&:hover": {
              filter: "brightness(2)",
              transition: "0.5s all ease-in-out",
            },
            fontFamily: "Lexend",
          }}
        >
          Sign Up
        </Button>

        <Button
          onClick={() => {
            navigate("/signin");
          }}
          sx={{
            height: { xs: "30px", md: "40px" },
            fontSize: "16px",
            marginLeft: { xs: "10px", md: "0px" },
            marginRight: "5px",
            borderRadius: "43px",
            backdropFilter: "blur(25px)",
            color: "white",
            textTransform: "none",
            padding: "0px",
            background:
              "linear-gradient(330deg, #0076BA 21%, #03093A 67%, #000000 100%)",
            display: "block",
            width: "120px",
            transition: "0.5s all ease-in-out",
            "&:hover": {
              filter: "brightness(2)",
              transition: "0.5s all ease-in-out",
            },
            fontFamily: "Lexend",
          }}
        >
          {location.pathname === "/signin" &&
            location.pathname === "coaching-signin"
            ? "Sign Up"
            : "Sign In"}
        </Button>
      </Box>
    </Box>
  );
};
