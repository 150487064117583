import React from "react";

function AssignIcon({ color }) {
  if (color === "disabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4961 8.47548C18.0484 8.47548 18.4961 8.02776 18.4961 7.47548L18.4961 6.47548L19.4961 6.47548C20.0484 6.47548 20.4961 6.02776 20.4961 5.47548C20.4961 4.92319 20.0484 4.47548 19.4961 4.47548L18.4961 4.47548L18.4961 3.47548C18.4961 2.92319 18.0484 2.47548 17.4961 2.47548C16.9438 2.47548 16.4961 2.92319 16.4961 3.47548L16.4961 4.47548L15.4961 4.47548C14.9438 4.47548 14.4961 4.92319 14.4961 5.47548C14.4961 6.02776 14.9438 6.47548 15.4961 6.47548L16.4961 6.47548L16.4961 7.47548C16.4961 8.02776 16.9438 8.47548 17.4961 8.47548Z" fill="#A6A6A6" />
        <path d="M20.4961 19.4755C20.4961 18.9232 20.0484 18.4755 19.4961 18.4755L18.4961 18.4755L18.4961 17.4755C18.4961 16.9232 18.0484 16.4755 17.4961 16.4755C16.9438 16.4755 16.4961 16.9232 16.4961 17.4755L16.4961 18.4755L15.4961 18.4755C14.9438 18.4755 14.4961 18.9232 14.4961 19.4755C14.4961 20.0278 14.9438 20.4755 15.4961 20.4755L16.4961 20.4755L16.4961 21.4755C16.4961 22.0278 16.9438 22.4755 17.4961 22.4755C18.0484 22.4755 18.4961 22.0278 18.4961 21.4755L18.4961 20.4755L19.4961 20.4755C20.0484 20.4755 20.4961 20.0278 20.4961 19.4755Z" fill="#A6A6A6" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.08975 7.67337C9.34488 6.0735 11.6475 6.07349 11.9026 7.67337C12.1811 9.41998 13.5508 10.7898 15.2974 11.0683C16.8972 11.3235 16.8972 13.6259 15.2974 13.8811C13.5508 14.1596 12.1811 15.5294 11.9026 17.276C11.6475 18.8759 9.34487 18.8759 9.08974 17.276C8.81121 15.5294 7.44152 14.1596 5.69497 13.8811C4.09515 13.6259 4.09518 11.3235 5.69498 11.0683C7.44152 10.7898 8.81121 9.41998 9.08975 7.67337ZM10.4962 9.75706C9.90783 10.9312 8.95282 11.8863 7.77866 12.4747C8.95282 13.0631 9.90784 14.0182 10.4962 15.1923C11.0845 14.0182 12.0395 13.0631 13.2137 12.4747C12.0395 11.8863 11.0845 10.9312 10.4962 9.75706Z" fill="#A6A6A6" />
      </svg>

    )
  } else if (color === "enabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4961 8.63367C18.0484 8.63367 18.4961 8.18595 18.4961 7.63367L18.4961 6.63367L19.4961 6.63367C20.0484 6.63367 20.4961 6.18595 20.4961 5.63367C20.4961 5.08138 20.0484 4.63367 19.4961 4.63367L18.4961 4.63367L18.4961 3.63367C18.4961 3.08138 18.0484 2.63367 17.4961 2.63367C16.9438 2.63367 16.4961 3.08138 16.4961 3.63367L16.4961 4.63367L15.4961 4.63367C14.9438 4.63367 14.4961 5.08138 14.4961 5.63367C14.4961 6.18595 14.9438 6.63367 15.4961 6.63367L16.4961 6.63367L16.4961 7.63367C16.4961 8.18595 16.9438 8.63367 17.4961 8.63367Z" fill="#252525" />
        <path d="M20.4961 19.6337C20.4961 19.0814 20.0484 18.6337 19.4961 18.6337L18.4961 18.6337L18.4961 17.6337C18.4961 17.0814 18.0484 16.6337 17.4961 16.6337C16.9438 16.6337 16.4961 17.0814 16.4961 17.6337L16.4961 18.6337L15.4961 18.6337C14.9438 18.6337 14.4961 19.0814 14.4961 19.6337C14.4961 20.186 14.9438 20.6337 15.4961 20.6337L16.4961 20.6337L16.4961 21.6337C16.4961 22.186 16.9438 22.6337 17.4961 22.6337C18.0484 22.6337 18.4961 22.186 18.4961 21.6337L18.4961 20.6337L19.4961 20.6337C20.0484 20.6337 20.4961 20.186 20.4961 19.6337Z" fill="#252525" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.08975 7.83156C9.34488 6.23169 11.6475 6.23168 11.9026 7.83156C12.1811 9.57817 13.5508 10.9479 15.2974 11.2265C16.8972 11.4816 16.8972 13.7841 15.2974 14.0393C13.5508 14.3178 12.1811 15.6876 11.9026 17.4342C11.6475 19.0341 9.34487 19.0341 9.08974 17.4342C8.81121 15.6876 7.44152 14.3178 5.69497 14.0393C4.09515 13.7841 4.09518 11.4816 5.69498 11.2265C7.44152 10.9479 8.81121 9.57817 9.08975 7.83156ZM10.4962 9.91525C9.90783 11.0894 8.95282 12.0445 7.77866 12.6329C8.95282 13.2213 9.90784 14.1764 10.4962 15.3505C11.0845 14.1764 12.0395 13.2213 13.2137 12.6329C12.0395 12.0445 11.0845 11.0894 10.4962 9.91525Z" fill="#252525" />
      </svg>
    )
  } else {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4961 8.05458C18.0484 8.05458 18.4961 7.60687 18.4961 7.05458L18.4961 6.05458L19.4961 6.05458C20.0484 6.05458 20.4961 5.60687 20.4961 5.05458C20.4961 4.5023 20.0484 4.05458 19.4961 4.05458L18.4961 4.05458L18.4961 3.05458C18.4961 2.5023 18.0484 2.05458 17.4961 2.05458C16.9438 2.05458 16.4961 2.5023 16.4961 3.05458L16.4961 4.05458L15.4961 4.05458C14.9438 4.05458 14.4961 4.5023 14.4961 5.05458C14.4961 5.60687 14.9438 6.05458 15.4961 6.05458L16.4961 6.05458L16.4961 7.05458C16.4961 7.60687 16.9438 8.05458 17.4961 8.05458Z" fill="url(#paint0_linear_564_6590)" />
        <path d="M20.4961 19.0546C20.4961 18.5023 20.0484 18.0546 19.4961 18.0546L18.4961 18.0546L18.4961 17.0546C18.4961 16.5023 18.0484 16.0546 17.4961 16.0546C16.9438 16.0546 16.4961 16.5023 16.4961 17.0546L16.4961 18.0546L15.4961 18.0546C14.9438 18.0546 14.4961 18.5023 14.4961 19.0546C14.4961 19.6069 14.9438 20.0546 15.4961 20.0546L16.4961 20.0546L16.4961 21.0546C16.4961 21.6069 16.9438 22.0546 17.4961 22.0546C18.0484 22.0546 18.4961 21.6069 18.4961 21.0546L18.4961 20.0546L19.4961 20.0546C20.0484 20.0546 20.4961 19.6069 20.4961 19.0546Z" fill="url(#paint1_linear_564_6590)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.08975 7.25247C9.34488 5.6526 11.6475 5.6526 11.9026 7.25247C12.1811 8.99908 13.5508 10.3689 15.2974 10.6474C16.8972 10.9026 16.8972 13.205 15.2974 13.4602C13.5508 13.7387 12.1811 15.1085 11.9026 16.8551C11.6475 18.455 9.34487 18.455 9.08974 16.8551C8.81121 15.1085 7.44152 13.7387 5.69497 13.4602C4.09515 13.205 4.09518 10.9026 5.69498 10.6474C7.44152 10.3689 8.81121 8.99908 9.08975 7.25247ZM10.4962 9.33616C9.90783 10.5103 8.95282 11.4654 7.77866 12.0538C8.95282 12.6422 9.90784 13.5973 10.4962 14.7715C11.0845 13.5973 12.0395 12.6422 13.2137 12.0538C12.0395 11.4654 11.0845 10.5103 10.4962 9.33616Z" fill="url(#paint2_linear_564_6590)" />
        <defs>
          <linearGradient id="paint0_linear_564_6590" x1="19.3634" y1="21.2845" x2="12.1761" y2="19.9277" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
          <linearGradient id="paint1_linear_564_6590" x1="19.3634" y1="21.2845" x2="12.1761" y2="19.9277" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
          <linearGradient id="paint2_linear_564_6590" x1="14.2315" y1="17.5929" x2="3.56521" y2="10.8812" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default AssignIcon;