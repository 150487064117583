import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export function useSignOut() {
  const navigate = useNavigate();

  const handleSignOut = useCallback(async () => {
    try {
      await Auth.signOut();
        //   navigate("/");
        window.location.href = "/signin";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  }, []);

  return handleSignOut;
}
