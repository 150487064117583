import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useStripeCheckoutCoachingPlatform } from "hooks/useStripeCheckoutCoachingPlatform";
import { useStripeCheckoutVirtualCoach } from "hooks/useStripeCheckoutVirtualCoach";
import { useStripeCheckoutVirtualCoachAnnual } from "hooks/useStripeCheckoutVirtualCoachAnnual";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "scenes/Layout/logo";
import ClientHasInactiveCoachModal from "./ClientHasInactiveCoach/ClientHasInactiveCoachModal";

const ProductChoose = ({ user, clientHasInactiveCoach }) => {
  const navigate = useNavigate();
  const [selectedPricing, setSelectedPricing] = useState("Monthly");

  const {
    handleStripeCheckoutVirtualCoach,
    setProductIdVirtualCoach,
    loadingStripeCheckoutVirtualCoach,
    errorWithStripeCheckoutVirtualCoach,
  } = useStripeCheckoutVirtualCoach();

  const {
    handleStripeCheckoutVirtualCoachAnnual,
    setProductIdVirtualCoachAnnual,
    loadingStripeCheckoutVirtualCoachAnnual,
    setLoadingStripeCheckoutVirtualCoachAnnual,
    errorWithStripeCheckoutVirtualCoachAnnual,
  } = useStripeCheckoutVirtualCoachAnnual();

  // Personal Subs
  const [
    loadingStripeCheckoutCoachingPlatformPersonal,
    setLoadingStripeCheckoutCoachingPlatformPersonal,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatformPersonalAnnual,
    setLoadingStripeCheckoutCoachingPlatformPersonalAnnual,
  ] = useState(false);

  // Tier 1 Subs
  const [
    loadingStripeCheckoutCoachingPlatform1,
    setLoadingStripeCheckoutCoachingPlatform1,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform1Annual,
    setLoadingStripeCheckoutCoachingPlatform1Annual,
  ] = useState(false);

  // Tier 2 Subs
  const [
    loadingStripeCheckoutCoachingPlatform2,
    setLoadingStripeCheckoutCoachingPlatform2,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform2Annual,
    setLoadingStripeCheckoutCoachingPlatform2Annual,
  ] = useState(false);

  // Tier 3 Subs
  const [
    loadingStripeCheckoutCoachingPlatform3,
    setLoadingStripeCheckoutCoachingPlatform3,
  ] = useState(false);
  const [
    loadingStripeCheckoutCoachingPlatform3Annual,
    setLoadingStripeCheckoutCoachingPlatform3Annual,
  ] = useState(false);

  // Hooks Coaching Platform
  const {
    handleStripeCheckoutCoachingPlatform,
    setProductIdCoachingPlatform,
    // loadingStripeCheckoutCoachingPlatform,
    errorWithStripeCheckoutCoachingPlatform,
  } = useStripeCheckoutCoachingPlatform();

  const [loadingEnterprise, setLoadingEnterprise] = useState(false);

  const handleCoachingPlatformClick = async (coachingPlatformProductId) => {
    await handleStripeCheckoutCoachingPlatform(coachingPlatformProductId);
  };

  const plans = [
    {
      name: "Virtual Coach",
      monthlyPrice: "$49",
      annualPrice: "$490",
      description: "Gain access to our cutting edge AI powered virtual coach",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              handleStripeCheckoutVirtualCoach();
            }
          : () => {
              handleStripeCheckoutVirtualCoachAnnual();
            },
      loading:
        loadingStripeCheckoutVirtualCoach ||
        loadingStripeCheckoutVirtualCoachAnnual, // Combine loading states
      error:
        errorWithStripeCheckoutVirtualCoach ||
        errorWithStripeCheckoutVirtualCoachAnnual, // Display any error
      annualLoading: loadingStripeCheckoutVirtualCoachAnnual,
    },
    {
      name: "Professional",
      monthlyPrice: "$99",
      annualPrice: "$990",
      description:
        "Optimize your own performance by improving your productivity and organization",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              setLoadingStripeCheckoutCoachingPlatformPersonal(true);
              handleCoachingPlatformClick("price_1OyblKLKxpQPkYt5aHNWNzzc");
            }
          : () => {
              setLoadingStripeCheckoutCoachingPlatformPersonalAnnual(true);
              handleCoachingPlatformClick("price_1PE2jFLKxpQPkYt5LyraVYUZ");
            },
      loading: loadingStripeCheckoutCoachingPlatformPersonal,
      annualLoading: loadingStripeCheckoutCoachingPlatformPersonalAnnual,
      error: errorWithStripeCheckoutCoachingPlatform,
    },
    {
      name: "Coaching Tier 1",
      monthlyPrice: "$149",
      annualPrice: "$1490",
      description: "1-3 Clients: Become an AI powered coach",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              setLoadingStripeCheckoutCoachingPlatform1(true);
              handleStripeCheckoutCoachingPlatform(
                "price_1OgAnTLKxpQPkYt5fPl1R57a"
              );
            }
          : () => {
              setLoadingStripeCheckoutCoachingPlatform1Annual(true);
              handleStripeCheckoutCoachingPlatform(
                "price_1PE2ewLKxpQPkYt5Tj2tmaGQ"
              );
            },
      loading: loadingStripeCheckoutCoachingPlatform1,
      annualLoading: loadingStripeCheckoutCoachingPlatform1Annual,
      error: errorWithStripeCheckoutCoachingPlatform,
    },
    {
      name: "Coaching Tier 2",
      monthlyPrice: "$399",
      annualPrice: "$3990",
      description:
        "4-10 Clients: Ideal for growing an AI powered coaching practice",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              setLoadingStripeCheckoutCoachingPlatform2(true);
              handleStripeCheckoutCoachingPlatform(
                "price_1OgAnJLKxpQPkYt5rFmZ857w"
              );
            }
          : () => {
              setLoadingStripeCheckoutCoachingPlatform2Annual(true);
              handleStripeCheckoutCoachingPlatform(
                "price_1PE2fjLKxpQPkYt5CcU07yB0"
              );
            },
      loading: loadingStripeCheckoutCoachingPlatform2,
      annualLoading: loadingStripeCheckoutCoachingPlatform2Annual,
      error: errorWithStripeCheckoutCoachingPlatform,
    },
    {
      name: "Coaching Tier 3",
      monthlyPrice: "$799",
      annualPrice: "$7990",
      description:
        "11-25 Clients: Ideal for scaling & retaining coaching clients",
      handleButtonClick:
        selectedPricing === "Monthly"
          ? () => {
              setLoadingStripeCheckoutCoachingPlatform3(true);
              handleStripeCheckoutCoachingPlatform(
                "price_1OgAn4LKxpQPkYt5NHVF9L6a"
              );
            }
          : () => {
              setLoadingStripeCheckoutCoachingPlatform3Annual(true);
              handleCoachingPlatformClick("price_1PE2h2LKxpQPkYt5io97sR7t");
            },
      loading: loadingStripeCheckoutCoachingPlatform3,
      annualLoading: loadingStripeCheckoutCoachingPlatform3Annual,
      error: errorWithStripeCheckoutCoachingPlatform,
    },
    {
      name: "Enterprise",
      monthlyPrice: "Inquire",
      annualPrice: "Inquire",
      description:
        "26+ Clients: An advanced platform for large organizations with multiple executives & coaches",
      handleButtonClick: () => {
        setLoadingEnterprise(true);
        window.location.href =
          "https://calendly.com/altercallai/aai-enterprise-demo";
      },
      loading: loadingEnterprise,
      annualLoading: loadingEnterprise,
    },
  ];

  return (
    <>
      <ClientHasInactiveCoachModal clientHasInactiveCoach={clientHasInactiveCoach}/>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          background: "#EFF3F6",
        }}
      >
        <Box
          display="flex"
          style={{
            alignItems: "left",
            marginRight: "1200px",
          }}
        >
          <Logo />
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            overflowX: { lg: "auto", md: "auto", xs: "hidden" },
            justifyContent: "center",
            transform: "scale(0.7)",
            marginTop: { lg: "-200px", md: "-200px", xs: "-400px" },
            marginBottom: { lg: "-200px", md: "-200px", xs: "-400px" },
          }}
        >
          <Box
            textAlign={"center"}
            marginBottom={{
              xs: "30px",
              md: "70px",
            }}
            padding={{
              xs: "0px 20px",
              md: "auto",
            }}
            marginRight={{
              xs: "0px",
            }}
          >
            <Typography
              className="pricing"
              color="black"
              textAlign={"center"}
              lineHeight={"67.2px"}
              fontWeight={300}
              letterSpacing={"-1.12px"}
              marginBottom={{
                xs: "10px",
                md: "20px",
              }}
              fontSize={{
                xs: "24px",
                md: "66px",
              }}
            >
              Start using AlterCall AI{" "}
              <span style={{ color: "#568FF3" }}>today</span>.
            </Typography>
            <Typography
              className="pricing"
              color="black"
              textAlign={"center"}
              lineHeight={"67.2px"}
              fontWeight={300}
              letterSpacing={"-1.12px"}
              marginBottom={{
                xs: "10px",
                md: "20px",
              }}
              fontSize={{
                xs: "14px",
                md: "26px",
              }}
            >
              *If you are in our Beta Group, pick your plan, enter your promo
              code on the next page, and you will get your first month free
            </Typography>
            <Box
              sx={{
                marginTop: "40px",
                display: "inline-flex",
                height: "94px",
                padding: "30px 5px 30px 5px",
                alignItems: "center",
                gap: "12px",
                flexShrink: 0,
                borderRadius: "18px",
                border: "2px solid #DDE5ED",
                marginBottom: { lg: "-30px", md: "-30px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "209px",
                  height: "80px",
                  background:
                    selectedPricing === "Monthly" ? "#DDE5ED" : "transparent",
                  borderRadius: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  transition:
                    "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => setSelectedPricing("Monthly")}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "194px",
                    height: "33px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0D162F",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "32.69px",
                    }}
                  >
                    Monthly Pricing
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "209px",
                  height: "80px",
                  background:
                    selectedPricing === "Annual" ? "#DDE5ED" : "transparent",
                  borderRadius: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => setSelectedPricing("Annual")}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "194px",
                    height: "33px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0D162F",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "32.69px",
                      transition:
                        "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                  >
                    Annual Pricing
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingBottom: "40px",
              paddingX: "40px",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                gap: "210px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#969696",
                  textTransform: "uppercase",
                  marginLeft: "150px",
                  fontSize: "18px",
                }}
              >
                Price
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#969696",
                  textTransform: "uppercase",
                  fontSize: "18px",
                }}
              >
                Includes
              </Typography>
            </Box>
            {/* {selectedPricing === "Annual" && (
                    <Box
                        sx={{
                            display: {xs:"none", md:"flex"},
                            width: "100%",
                            justifyContent: "center",
                            gap: "210px",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                color: "#568FF3",
                                textTransform: "uppercase",
                                marginLeft: "100px",
                                fontSize: "18px"
                            }}
                        >
                            Save over 15%
                        </Typography>
                    </Box>
                    )} */}
            {plans.map((plan) => (
              <Box
                key={plan.name}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: "10px", md: "0px" },
                    background: "rgba(221, 229, 237, 0.2)",
                    padding: "30px 39px 29px 29px",
                    borderRadius: "18px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0D162F",
                      fontSize: "24px",
                      fontWeight: 400,
                      lineHeight: "32.69px",
                      width: { md: "226px" },
                      height: "33px",
                    }}
                  >
                    {plan.name}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (selectedPricing === "Monthly" && !plan.loading) {
                        plan.handleButtonClick();
                      } else if (
                        selectedPricing === "Annual" &&
                        !plan.annualLoading
                      ) {
                        plan.handleButtonClick();
                      }
                    }}
                    disabled={
                      selectedPricing === "Monthly"
                        ? plan.loading
                        : plan.annualLoading
                    }
                    sx={{
                      padding: "10px 20px",
                      width: "180px",
                      height: "35px",
                      border: "1px solid black",
                      borderRadius: "52px",
                      background:
                        plan.name === "Enterprise"
                          ? "transparent"
                          : "linear-gradient(88deg, #000 -6.51%, #535353 111.7%)",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        cursor: "pointer",
                        background:
                          plan.name !== "Enterprise" &&
                          "linear-gradient(135deg, rgba(7, 7, 7, 0.8) 0%, rgba(102, 102, 102, 0.8) 100%)",
                      },
                    }}
                  >
                    {plan.loading || plan.annualLoading ? (
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    ) : (
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "10px",
                          color: plan.name === "Enterprise" ? "black" : "white",
                          textTransform: "uppercase",
                          fontWeight: 700,
                          lineHeight: "150%",
                          letterSpacing: "2px",
                        }}
                      >
                        {plan.name === "Enterprise"
                          ? "Contact Us"
                          : "Choose this plan"}
                      </Typography>
                    )}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: selectedPricing === "Monthly" ? "flex" : "none",
                    width: "200px",
                    height: "43px",
                    justifyContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize:
                        plan.monthlyPrice === "Inquire" ? "24px" : "42px",
                      fontWeight: "300px",
                      lineHeight: "43.58px",
                      textAlign: "center",
                    }}
                  >
                    {plan.monthlyPrice}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: selectedPricing === "Annual" ? "flex" : "none",
                    width: "200px",
                    height: "43px",
                    justifyContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize:
                        plan.annualPrice === "Inquire" ? "24px" : "42px",
                      fontWeight: "300px",
                      lineHeight: "43.58px",
                      textAlign: "center",
                    }}
                  >
                    {plan.annualPrice}
                    {selectedPricing === "Annual" &&
                      plan.annualPrice === "$490" && (
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Lexend",
                            color: "#568FF3",
                            whiteSpace: "nowrap",
                            marginBottom: "5px",
                            marginTop: {
                              lg: "-90px",
                              md: "-90px",
                              xs: "-230px",
                            },
                          }}
                        >
                          2 MONTHS FREE
                        </Typography>
                      )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginLeft: { md: "40px" },
                    width: { md: "600px" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "22px",
                      fontWeight: "300px",
                      lineHeight: "43.58px",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    {plan.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductChoose;
