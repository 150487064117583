import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import CoachDashboard from "./pages/platform/coach/CoachDashboard";
import SignUpPage from "./pages/signup.jsx";
import PasswordReset from "./scenes/PasswordReset";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsConfig from "./aws-exports";
import TermsPage from "./scenes/SignUp/TermsPage";
import ClientSignUp from "./scenes/ClientSignup";
import ClientDashboard from "./pages/platform/client/ClientDashboard.jsx";
import { ToastContainer } from "react-toastify";
import Settings from "pages/settings";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { isLocalhost } from "helpers/envHelper";
import Terms from "./scenes/Terms";
import Support from "./scenes/Support";
import PrivacyPolicy from "scenes/PrivacyPolicy";
import Actions from "scenes/Actions/Actions";
import VirtualSignup from "scenes/SignUp/virtualCoach";
import VirtualSignIn from "scenes/login/virtualCoach";
import VcPaymentSuccessPage from "pages/vcPaymentSuccess";
import CpPlatformPaymentSuccessPage from "pages/cpPlatformPaymentSuccess";
import CoachingLoginPage from "scenes/SignIn-Universal";
import CoachingSignUp from "scenes/CoachingSignUp";
import { Authenticator } from "@aws-amplify/ui-react";
import { PaymentProvider } from "hooks/PaymentProvider";
import UserGuide from "pages/userGuide";
import ClientCenter from "pages/platform/coach/CoachClientCenter";
import RetuneBotPage from "./pages/bot";
import CoachVirtualCoachPage from "pages/platform/coach/CoachVirtualCoach";
import ClientVirtualCoachPage from "pages/platform/client/ClientVirtualCoach";
import ClientSessionPage from "pages/platform/client/ClientSession";
import CoachSessionPage from "pages/platform/coach/CoachSession";
import CancelPaymentPage from "pages/cancelPaymentPage";
import PricingTablePage from "pages/pricingTablePage";
import ClientOverview from "pages/platform/coach/CoachClientOverview";
import HomePage from "./pages";
import { usePayment } from "hooks/PaymentContext";
import AuthenticatingLoader from "pages/authenticatingLoader";
import Blogs from "scenes/Blogs/Blogs";
import SingleBlog from "scenes/Blogs/SingleBlog";
import InviteAcceptance from "pages/platform/client/InviteAcceptance";
import AddNewBlog from "scenes/Blogs/AddNewBlog";
import Resources from "./scenes/Resources/ResourceContainer";
import ResourcePage from "scenes/Resources/ResourcePage";
import ResourceEditingPage from "scenes/Resources/ResourceEditingPage";
import CoachLaraAnalytics from "pages/platform/coach/CoachLaraAnalytics";
import ClientLaraAnalytics from "pages/platform/client/ClientLaraAnalytics";
//trigger-build-change-delete this if your reading this
const [localRedirectSignIn, productionRedirectSignIn] = [
  "http://localhost:3000/authenticating/",
  "https://altercall.ai/authenticating/",
];
const [localRedirectSignOut, productionRedirectSignOut] = [
  "http://localhost:3000/signin/",
  "https://altercall.ai/signin/",
];

// const updatedAwsConfig = {
//   ...awsConfig,
//   oauth: {
//     ...awsConfig.oauth,
//     redirectSignIn: isLocalhost
//       ? window.location.pathname === "/login"
//         ? "http://localhost:3000/platform/coach/"
//         : localRedirectSignIn
//       : window.location.pathname === "/login"
//       ? "https://altercall.ai/platform/coach/"
//       : productionRedirectSignIn,
//     redirectSignOut: isLocalhost
//       ? localRedirectSignOut
//       : productionRedirectSignOut,
//   },
// };

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

const RoutesWithPayment = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const routesAllowed = [
      "/signup",
      "/signup-client",
      "/terms-signup",
      "/terms-login",
      "/support",
      "/privacy-policy",
      "/signin",
      "/resetpassword",
      "/virtual-signup",
      "/virtual-signin",
      "/bot",
      "/priceselect",
      "/resources",
      "/resources/page",
      "/blog",
      "/blogs",
      "/signin-client-accept",
      "/add-new-blog",
      "/add-new-resource",
      "/edit-resources"
    ];

    const checkAuthStatus = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setIsAuthenticated(true);
          setUserInfo(user);
        }
        if (user?.attributes?.["custom:is_client"] === "true") {
          setIsClient(true);
        }
      } catch (error) {
        if (window.location.pathname === "/signin-client-accept") {
          return
        }
        navigate("/")
      }
    };

    const updateUserState = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (!user) {
          setIsAuthenticated(false);
          setIsClient(false);
          setUserInfo(null);
          navigate("/");
        }
      } catch (error) {
        console.error("Error updating user state:", error);
      }
    };

    // Subscribe to sign-out events
    const unsubscribe = Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signOut") {
        updateUserState();
      }
      if (event === "signIn") {
        checkAuthStatus()
      }
    });

    // Initial check of authentication status
    const blogRouteRegex = /^\/blogs\/([1-9]|[1-9][0-9]|[1-4][0-9][0-9]|500)$/; // Regex to check if the route is something like /blog/
    if (
      routesAllowed.indexOf(window.location.pathname) < 0 && //* Adjusted conditional check
      !blogRouteRegex.test(window.location.pathname) //* Adjusted conditional check
    ) {
      checkAuthStatus();
    } else if (window.location.pathname === "/signin-client-accept") {
      checkAuthStatus(); // Always check auth status for this specific route
    }

    return () => {
      unsubscribe();
    };

  }, [navigate]);

  const { userData } = usePayment();


  return (
    <Routes>
      {/* Global */}
      <Route path="/" element={<HomePage user={userInfo} userData={userData} />} />
      <Route path="/support" element={<Support />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-signup" element={<TermsPage />} />
      <Route path="/terms-login" element={<Terms />} />
      <Route path="/blogs" element={<Blogs user={userInfo} userData={userData} />} />
      <Route path="/blog" element={<SingleBlog user={userInfo} userData={userData} />} />
      <Route path="/add-new-blog" element={<AddNewBlog />} />

      <Route path="/edit-resources" element={<ResourceEditingPage />} />

      <Route path="/resources" element={<Resources user={userInfo} userData={userData} />} >
        <Route index element={< ResourcePage />} />
        {/* <Route path='page' element={< ResourcePage />} /> */}
      </Route>

      {/* Auth Actions */}
      <Route path="/authenticating" element={<AuthenticatingLoader user={userInfo} />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/resetpassword" element={<PasswordReset />} />
      <Route path="/signup-client" element={<ClientSignUp />} />
      <Route path="/signin-client-accept" element={<InviteAcceptance user={userInfo} userData={userData} />} />
      <Route path="/signin" element={<CoachingLoginPage />} />
      <Route path="/coaching-signin" element={<CoachingLoginPage />} />
      <Route path="/virtual-signup" element={<VirtualSignup />} />
      <Route path="/virtual-signin" element={<VirtualSignIn />} />

      {/* Logged In */}
      <Route path="/products" element={<CoachingSignUp />} />
      <Route path="/coaching-signup" element={<CoachingSignUp />} />
      <Route
        path="/settings"
        element={isAuthenticated && <Settings user={userInfo} userData={userData} />}
      />

      {/* Payment Actions */}
      <Route path="/payment-cancellation" element={<CancelPaymentPage />} />
      <Route path="/vc-payment-success" element={<VcPaymentSuccessPage />} />
      <Route path="/cp-payment-success" element={<CpPlatformPaymentSuccessPage />} />
      <Route path="/priceselect" element={<PricingTablePage />}></Route>

      {/* Virtual Coach */}
      <Route
        path="/virtualcoach/bot"
        element={
          isAuthenticated &&
          userData.accessTo === 'virtual_coach' && (
            <RetuneBotPage user={userInfo} userData={userData} />
          )
        }
      />
      <Route path="/user_guide" element={<UserGuide />} />



      {/* Coaching Platform */}
      <Route
        path="/platform/coach"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <CoachDashboard user={userInfo} userData={userData} />
          )
        }
      />
      {/* <Route
        path="/platform/professional"
        element={
          accessTo === 'platform_professional' &&
          isAuthenticated &&
          allowAccessToPlatform && (
            <CoachDashboard user={userInfo} />
          )
        }
      /> */}
      <Route
        path="/platform/coach/leadership-center"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <ClientCenter user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coach/lara-analytics"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <CoachLaraAnalytics user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coach/coachee-overview/:client_id"
        element={
          userData.accessTo === 'platform' &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <ClientOverview user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coach/session/:file_name"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated && userData.paymentInfo?.isCustomer && (
            <CoachSessionPage user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coach/actions"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <Actions user={userInfo} userData={userData} />
          )
        }
      />

      <Route
        path="/platform/coach/virtualcoach"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <CoachVirtualCoachPage user={userInfo} userData={userData} />
          )
        }
      />

      {/* Client */}
      <Route
        path="/platform/coachee"
        element={
          isClient && isAuthenticated && (
            <ClientDashboard user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coachee/session/:file_name"
        element={
          isClient && isAuthenticated && (
            <ClientSessionPage user={userInfo} userData={userData}/>
          )
        }
      />
      <Route
        path="/platform/coachee/lara-analytics"
        element={
          isClient &&
          isAuthenticated && (
            <ClientLaraAnalytics user={userInfo} userData={userData} />
          )
        }
      />

      <Route
        path="/platform/coachee/actions"
        element={
          (isClient || (isAuthenticated && userData.paymentInfo?.isCustomer)) && (
            <Actions user={userInfo} userData={userData} />
          )
        }
      />

      <Route
        path="/platform/coachee/virtualcoach"
        element={
          isClient && isAuthenticated && (
            <ClientVirtualCoachPage user={userInfo} userData={userData} />
          )
        }
      />
    </Routes>
  );
};

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    // Add Mouseflow script
    const script = document.createElement("script");
    script.src = "https://cdn.mouseflow.com/projects/d04bc01d-98c6-450b-9c20-e481573aa184.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Authenticator.Provider>
      <PaymentProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer />
                <div className="app">
                  <main style={{ width: "100%" }}>
                    <RoutesWithPayment />
                  </main>
                </div>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </PersistGate>
        </ReduxProvider>
      </PaymentProvider>
    </Authenticator.Provider>
  );
}

export default App;
