import { Box, Button, Modal, Typography } from "@mui/material";
import Logo from "scenes/Layout/logo";
import ArrowForward from "./Icons/ArrowForward.svg";
import { API } from "aws-amplify";
import { useSignOut } from "hooks/useSignOut";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeclineButton from "./DeclineButton";
import ConfirmButton from "./ConfirmButton";

const FailedPayment = ( {user, stripeCustomerId, userData} ) => {
    const handleSignOut = useSignOut();
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const navigate = useNavigate();

    const handleCloseDeactivateModal = () => {
        setOpenDeactivateModal(false)
      };
    
      const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
      };

      const handleDeactivate = async () => {
        try {
          setOpenDeactivateModal(false);
          await API.put("zoomapi", "/zoom/deactivate-account", {
            queryStringParameters: {
              user_id: user?.attributes?.sub,
              stripe_customer_id: stripeCustomerId
            },
          });
          handleSignOut();
        } catch (error) {
          console.log("error signing out: ", error);
        }
      };
    
      const handleDelete = async () => {
        try {
          setOpenDeactivateModal(false);
          await API.put("zoomapi", "/zoom/delete-account", {
            queryStringParameters: {
              user_id: user?.attributes?.sub,
              stripe_customer_id: stripeCustomerId
            },
          });
          navigate("/signin");
        } catch (error) {
          console.log("error signing out: ", error);
        }
      };

    return(
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                    background: "#EFF3F6",
                    justifyContent: "center",
                    alignItems: "center"
                    }}
            >
                <Logo />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "50px",
                        gap: "20px"
                    }}
                >
                    <Typography
                        sx={{
                            color: "black",
                            fontSize: "26px",
                            textAlign: "center"
                        }}
                    >
                        Failed payment
                        <br/>
                        Manage your subscription here:
                    </Typography>
                    <a
                        href="https://billing.stripe.com/p/login/dR6eWJ2bkfuobja000"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Button
                          sx={{
                            display: "flex",
                            width: {
                              xs: "100%",
                              md: "unset",
                            },
                            paddingX: "20px",
                            paddingY: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "43px",
                            background:
                              "linear-gradient(135deg, #070707 0%, #666666 100%)",
                            transition: "all 0.3s",
                            "&:hover": {
                              cursor: "pointer",
                              background:
                                "linear-gradient(135deg, rgba(7, 7, 7, 0.8) 0%, rgba(102, 102, 102, 0.8) 100%)",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "white",
                              fontSize: "10px",
                              fontStyle: "normal",
                              lineHeight: "150%",
                              fontWeight: "bold",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            Manage My Subscription
                          </Typography>
                        </Button>
                      </a>
                      <Typography
                        sx={{
                            fontSize: "14px",
                            color: "gray",
                            textDecoration: "underline",
                            cursor: "pointer", 
                            "&:hover": {
                              color: "blue", 
                              textDecoration: "underline" 
                            }
                          }}
                          onClick={() => window.location.reload()} 
                      >
                        Return to platform
                      </Typography>
                      <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                      >
                        <Typography
                            sx={{
                                color: "black",
                                fontSize: "26px",
                                marginTop: "50px"
                            }}
                        >
                            Manage Account
                        </Typography>
                        {userData.access_to === "platform" && (
                        <>
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Your account will be suspended until you re-subscribe.
                        </Typography>
                        <Button
                          sx={{
                            display: " flex",
                            width: {
                              xs: "100%",
                              md: "220px",
                            },
                            padding: "10px 8px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "52px",
                            border: "1px solid #BC0000",
                            background: "transparent",
                            transition: "all 0.3s",
                            "& svg *": {
                              stroke: "#BE0808",
                            },
                            "&:hover": {
                              cursor: "pointer",
                              background: "#BC0000",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                            "&:hover svg *": {
                              stroke: "white",
                            },
                          }}
                          onClick={() => setOpenDeactivateModal(true)}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              letterSpacing: "2px",
                              lineHeight: "1.5",
                              textTransform: "uppercase",
                            }}
                          >
                            Deactivate Account
                          </Typography>
                          {ArrowForward()}
                        </Button>
                        </>
                        )}
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            color: "black",
                            marginTop: "10px"
                          }}
                        >
                          Your account will be deleted.
                        </Typography>
                        <Button
                          sx={{
                            display: " flex",
                            width: {
                              xs: "100%",
                              md: "220px",
                            },
                            padding: "10px 8px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "52px",
                            border: "1px solid #BC0000",
                            background: "transparent",
                            transition: "all 0.3s",
                            "& svg *": {
                              stroke: "#BE0808",
                            },
                            "&:hover": {
                              cursor: "pointer",
                              background: "#BC0000",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                            "&:hover svg *": {
                              stroke: "white",
                            },
                          }}
                          onClick={() => setOpenDeleteModal(true)}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              letterSpacing: "2px",
                              lineHeight: "1.5",
                              textTransform: "uppercase",
                            }}
                          >
                            Delete Account
                          </Typography>
                          {ArrowForward()}
                        </Button>
                    </Box>
                </Box>
                <>
                </>
            </Box>
            <Modal
              open={openDeactivateModal}
              onClose={() => setOpenDeactivateModal(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  background: "white",
                  border: "2px solid white",
                  boxShadow: 24,
                  padding: "20px 20px 10px",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "0 auto",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Box>
                  <Typography color="black" fontWeight="400">
                  <strong>Are you sure?</strong>
                  <br/>
                  Your subscription will end at the end of the current billing period.
                  <br/>
                  You will lose access to the subscription platform at that time.
                  <br/>
                  Any clients will lose access to the client portal.
                  </Typography>
                </Box>
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "column-reverse", md: "row"},
                        gap: {xs: "8px", md: "15px"},
                        justifyContent: "center"
                    }}
                >
                  <DeclineButton 
                    onClick={handleCloseDeactivateModal}
                    label={"No"}
                  />
                  <ConfirmButton 
                    onClick={handleDeactivate}
                    label={"Yes"}
                  />
                </Box>
              </Box>
            </Modal>
            <Modal
              open={openDeleteModal}
              onClose={() => setOpenDeleteModal(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  background: "white",
                  border: "2px solid white",
                  boxShadow: 24,
                  padding: "20px 20px 10px",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "0 auto",
                  maxWidth: "calc(100% - 40px)",
                }}
              >
                <Box>
                  <Typography color="black" fontWeight="400">
                    
                       <strong>Are you sure?</strong>
                       <br/>
                       Your account will be deleted immediately and your subscription will be cancelled immediately.
                       <br/>
                       Related data will be kept for 7 days.
                       <br/>
                       Any clients will lose access to the client portal.

                  </Typography>
                </Box>
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "column-reverse", md: "row"},
                        gap: {xs: "8px", md: "15px"},
                        justifyContent: "center"
                    }}
                >
                    <DeclineButton 
                    onClick={handleCloseDeleteModal}
                    label={"No"}
                  />
                  <ConfirmButton 
                    onClick={handleDelete}
                    label={"Yes"}
                  />
                </Box>
              </Box>
            </Modal>
        </>
    )
}

export default FailedPayment;