import { useCallback, useState, useEffect } from 'react';
import { API } from 'aws-amplify';

export function useGetCoachesForUser(user_id) {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCoaches = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const coachesResponse = await API.get("zoomapi", "/zoom/fetch-coaches", {
        queryStringParameters: { user_id }
      });
      setCoaches(coachesResponse.coaches);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [user_id]);

  useEffect(() => {
    if (user_id) {
      getCoaches();
    }
  }, [user_id, getCoaches]);

  return { coaches, loading, error };
}