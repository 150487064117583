import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import blogSearchTag from "../../assets/blog-search-tag.png";
import { Header } from "components/GlobalHeader/GlobalHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "components/Footer/Footer";
import { API } from "aws-amplify";

const SingleBlog = ({ user, allowAccessToBot, allowAccessToPlatform, }) => {


    const [id, setId] = useState("")
    const [loading, setLoading] = useState(false)
    const [blog, setBlog] = useState({})

    const navigate = useNavigate()

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const blogId = queryParams.get("id");
        setId(blogId);
    }, [location.search]);


    useEffect(() => {

        async function fetchBlog() {
            if (!id) return;
            try {
                setLoading(true)
                const response = await API.get("zoomapi", `/zoom/blog/${id}`);
                setBlog(response)
                setLoading(false)
            } catch (error) {
                console.log(error);
            }
        }

        fetchBlog()

    }, [id])

    function formatDate(dateInTzFormat) {
        const date = new Date(dateInTzFormat);
        const formattedDate = format(date, "MMMM dd, yyyy")
        return formattedDate;
    }

    return (
        <Box color={'black'} >

            <Header
                user={user}
                allowAccessToBot={allowAccessToBot}
                allowAccessToPlatform={allowAccessToPlatform}
                hasRightSidebar={false}
                hideGlobalSearchbar={true}
            />



            <Box width={{ xs: "88%", lg: "45%" }} mx={'auto'} pb="100px" >

                {/* Blog here */}
                {
                    loading ? <Box
                        sx={{
                            position: "absolute",
                            top: "20%",
                            left: "50%",
                            width: "100vh"
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Box>
                        :
                        <Box>
                            <Typography textAlign={{ xs: 'center', lg: "left" }} width="100%" fontSize={{ xs: "28px", lg: "42px" }} lineHeight={{ xs: "34px", lg: "46px" }} > {blog?.title} </Typography>

                            {
                                blog.tag && <Box display="flex" gap="15px" mt="20px" justifyContent={{ xs: "center", lg: "start" }} >
                                    {blog?.tags?.map((t, index) => <Box key={index} border="1px solid #0076BA" borderRadius="8px" height="36px" display="inline-flex" gap="15px" alignItems="center" px="15px">

                                        <Box component="img" width="21px" height="21px" src={blogSearchTag}></Box>
                                        <Typography fontSize="14px">{t}</Typography>

                                    </Box>)}
                                </Box>
                            }

                            <Typography textAlign={{ xs: 'center', lg: "left" }} color="#949494" fontSize="14px" mt="34px">
                                Date of Publish:  {blog.date && formatDate(blog.date)}
                            </Typography>

                            <Box component="img" width="100%" my="50px" borderRadius={{ xs: "14px", lg: '0' }}
                                src={process.env.REACT_APP_BlogImgBaseUrl + blog.thumbnailurl}></Box>


                            <Box style={{ fontFamily: "Poppins", textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: blog.bloghtml }}></Box>



                        </Box>

                }



                {/* Stay Updated With Us and Signup field Box */}
                <Box mt={"170px"} border={"3px solid #F2EFE6"} py={'30px'} px={"20px"} height={{ lg: "207px" }} borderRadius={'20px'} sx={{ background: "linear-gradient(to bottom, #DDE5ED 25%, #DDE5ED 100%)" }} >

                    <Box component="div" display="flex" gap="15px" fontFamily={'Poppins'}>
                        <Typography fontSize={{ xs: "28px", lg: "32px" }} fontWeight="300" > Stay Updated</Typography>
                        <Typography fontSize={{ xs: "28px", lg: "32px" }} fontWeight="300" color="#0076BA"> With Us</Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={{ xs: 'column', lg: "row" }} gap={'20px'} justifyContent={'start'} alignItems={'baseline'} mt={"30px"} >
                        <TextField label="you@email.com " variant="standard" style={{ width: "80%", }} />
                        <Button
                            onClick={() => navigate("/signup")}
                            sx={{
                                height: { xs: "30px", md: "40px" },
                                fontSize: "16px",
                                borderRadius: "43px",
                                backdropFilter: "blur(25px)",
                                color: "white",
                                textTransform: "none",
                                padding: "0px",
                                background:
                                    "linear-gradient(330deg, #0076BA 21%, #03093A 67%, #000000 100%)",
                                display: "block",
                                width: "120px",
                                transition: "0.5s all ease-in-out",
                                "&:hover": {
                                    filter: "brightness(2)",
                                    transition: "0.5s all ease-in-out",
                                },
                                fontFamily: "Lexend",
                            }}
                        >
                            Sign Up
                        </Button>

                    </Box>
                </Box>

            </Box>



            <Footer></Footer>

        </Box>
    );
};

export default SingleBlog;
