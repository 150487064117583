import { Button, Typography } from "@mui/material";
import { useState } from "react";
import ClientAddActionModal from "./ClientAddActionModal";


const ClientAddActionButton = ( {user, loading, setLoading, fetchUserData} ) => {
    const [openAddActionModal, setOpenAddActionModal] = useState(false);
    return(
      <>
        <Button
          onClick={() => setOpenAddActionModal(true)}
          sx={{
            paddingX: "30px",
            paddingY: "10px",
            background: "white",
            border: "1px solid black",
            color: "black",
            borderRadius: "43px",
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s",
            "&:hover": {
              background: "rgba(242, 242, 242, 0.4)",
              border: "1px solid #D5D5D5",
              color: "#6D6D6D",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "10px",
              lineHeight: "1.5",
              fontWeight: 600,
              letterSpacing: "2px",
              textTransform: "uppercase",
            }}
          >
            Add an action
          </Typography>
        </Button>
        <ClientAddActionModal 
          user={user} 
          openAddActionModal={openAddActionModal}
          setOpenAddActionModal={setOpenAddActionModal}
          loading={loading}
          setLoading={setLoading}
          fetchUserData={fetchUserData}
        />
      </>
    )
}

export default ClientAddActionButton;