import { useState, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "graphql/mutations"; // Ensure this is pointing to your mutations

export const useIncrementClientCount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const incrementClientCount = useCallback(async (userID) => {
    setLoading(true);

    const variables = {
      userID: userID,
    };

    try {
      const response = await API.graphql(
        graphqlOperation(mutations.incrementClientCount, { userID })
      );

      // Assuming your GraphQL response structure, adjust as necessary
      const { clientTierAllowance, clientCount } =
        response.data.incrementClientCount;

      // Here you can return the response or handle it as needed
      return { clientTierAllowance, clientCount };
    } catch (error) {
      console.error("Error incrementing client count:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    incrementClientCount,
    loading,
    error,
  };
};
