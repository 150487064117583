/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fetchStripeCheckout = /* GraphQL */ `
  query FetchStripeCheckout($input: AWSJSON!) {
    fetchStripeCheckout(input: $input)
  }
`;
export const fetchPresignedUrls = /* GraphQL */ `
  query FetchPresignedUrls($input: AWSJSON!) {
    fetchPresignedUrls(input: $input)
  }
`;
export const fetchStripeCoachingPlatformTier = /* GraphQL */ `
  query FetchStripeCoachingPlatformTier($input: AWSJSON!) {
    fetchStripeCoachingPlatformTier(input: $input)
  }
`;
export const checkClientInviteAllowance = /* GraphQL */ `
  query checkClientInviteAllowance($userID: String!) {
    checkClientInviteAllowance(userID: $userID) {
      clientTierAllowance
      clientCount
    }
  }
`;
export const getPayments = /* GraphQL */ `
  query GetPayments($userID: String!) {
    getPayments(userID: $userID) {
      userID
      customer
      membershipType
      purchasedOn
      virtualcoach
      coachingPlatform
      coachingPlatformTier
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $userID: String
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayments(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        customer
        membershipType
        purchasedOn
        virtualcoach
        coachingPlatform
        coachingPlatformTier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
