/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayments = /* GraphQL */ `
  mutation CreatePayments(
    $input: CreatePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    createPayments(input: $input, condition: $condition) {
      userID
      customer
      membershipType
      purchasedOn
      virtualcoach
      coachingPlatform
      coachingPlatformTier
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayments = /* GraphQL */ `
  mutation UpdatePayments(
    $input: UpdatePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    updatePayments(input: $input, condition: $condition) {
      userID
      customer
      membershipType
      purchasedOn
      virtualcoach
      coachingPlatform
      coachingPlatformTier
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayments = /* GraphQL */ `
  mutation DeletePayments(
    $input: DeletePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    deletePayments(input: $input, condition: $condition) {
      userID
      customer
      membershipType
      purchasedOn
      virtualcoach
      coachingPlatform
      coachingPlatformTier
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const incrementClientCount = /* GraphQL */ `
  mutation IncrementClientCount($userID: String!) {
    incrementClientCount(userID: $userID) {
      clientTierAllowance
      clientCount
    }
  }
`;
export const decrementClientCount = /* GraphQL */ `
  mutation DecrementClientCount($userID: String!) {
    decrementClientCount(userID: $userID) {
      clientTierAllowance
      clientCount
    }
  }
`;
