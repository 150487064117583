import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logoImg from "assets/logohorizontal.svg";
import logoMobile from "assets/logo-mobile.svg";

const Logo2 = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        if (user) {
          if (user.attributes?.["custom:is_client"] === "true") {
            navigate("/platform/coachee");
          } else {
            navigate("/platform/coach");
          }
        } else {
          navigate("/");
        }
      }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        cursor: "pointer",
      }}
    >
      <Box
        component="img"
        src={logoImg}
        alt="logo"
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          transform: "scale(0.5)",
          marginTop: "-70px",
        }}
      />
      <Box
        component="img"
        src={logoMobile}
        alt="mobile-logo"
        sx={{
          display: {
            xs: "flex",
            md: "none",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            marginLeft: "10px",
          },
        }}
      ></Box>
    </Box>
  );
};

export default Logo2;
