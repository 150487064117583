import React from "react";

function RefreshIcon({ color }) {
  if (color === "disabled") {
    return (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.197 5.02122C13.9252 3.23496 11.8399 2.07401 9.48416 2.07606C5.61817 2.07942 2.48689 5.21615 2.49025 9.08214C2.49361 12.9481 5.63034 16.0794 9.49633 16.0761C13.3623 16.0727 16.4936 12.936 16.4902 9.06997C16.4898 8.51769 16.9371 8.06958 17.4894 8.0691C18.0417 8.06862 18.4898 8.51595 18.4902 9.06823C18.4946 14.0388 14.4686 18.0717 9.49807 18.0761C4.52751 18.0804 0.49457 14.0544 0.49025 9.08388C0.48593 4.11332 4.51186 0.0803788 9.48243 0.076059C12.3106 0.0736011 14.835 1.37673 16.4853 3.41272L16.4833 1.06998C16.4828 0.517691 16.9301 0.0695864 17.4824 0.0691064C18.0347 0.0686264 18.4828 0.515952 18.4833 1.06824L18.4876 6.06824C18.4879 6.33345 18.3827 6.5879 18.1954 6.7756C18.008 6.9633 17.7537 7.06887 17.4885 7.0691L12.4885 7.07345C11.9362 7.07393 11.4881 6.6266 11.4876 6.07432C11.4872 5.52203 11.9345 5.07393 12.4868 5.07345L15.2349 5.07106C15.2218 5.05495 15.2092 5.03833 15.197 5.02122Z" fill="#A6A6A6" />
      </svg>
    )
  } else if (color === "enabled") {
    return (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.197 5.12569C13.9252 3.33942 11.8399 2.17848 9.48416 2.18053C5.61817 2.18389 2.48689 5.32062 2.49025 9.18661C2.49361 13.0526 5.63034 16.1839 9.49633 16.1805C13.3623 16.1772 16.4936 13.0404 16.4902 9.17444C16.4898 8.62216 16.9371 8.17405 17.4894 8.17357C18.0417 8.17309 18.4898 8.62042 18.4902 9.1727C18.4946 14.1433 14.4686 18.1762 9.49807 18.1805C4.52751 18.1848 0.49457 14.1589 0.49025 9.18835C0.48593 4.21779 4.51186 0.184848 9.48243 0.180528C12.3106 0.17807 14.835 1.4812 16.4853 3.51719L16.4833 1.17444C16.4828 0.62216 16.9301 0.174056 17.4824 0.173576C18.0347 0.173096 18.4828 0.620422 18.4833 1.17271L18.4876 6.1727C18.4879 6.43792 18.3827 6.69237 18.1954 6.88007C18.008 7.06776 17.7537 7.17334 17.4885 7.17357L12.4885 7.17792C11.9362 7.1784 11.4881 6.73107 11.4876 6.17879C11.4872 5.6265 11.9345 5.1784 12.4868 5.17792L15.2349 5.17553C15.2218 5.15942 15.2092 5.1428 15.197 5.12569Z" fill="black" />
      </svg>
    )
  } else {
    return (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.197 5.54658C13.9252 3.76032 11.8399 2.59937 9.48416 2.60142C5.61817 2.60478 2.48689 5.74151 2.49025 9.6075C2.49361 13.4735 5.63034 16.6048 9.49633 16.6014C13.3623 16.5981 16.4936 13.4613 16.4902 9.59533C16.4898 9.04305 16.9371 8.59494 17.4894 8.59446C18.0417 8.59398 18.4898 9.04131 18.4902 9.59359C18.4946 14.5642 14.4686 18.5971 9.49807 18.6014C4.52751 18.6057 0.49457 14.5798 0.49025 9.60924C0.48593 4.63868 4.51186 0.605739 9.48243 0.601419C12.3106 0.598961 14.835 1.90209 16.4853 3.93808L16.4833 1.59534C16.4828 1.04305 16.9301 0.594946 17.4824 0.594466C18.0347 0.593987 18.4828 1.04131 18.4833 1.5936L18.4876 6.5936C18.4879 6.85881 18.3827 7.11326 18.1954 7.30096C18.008 7.48866 17.7537 7.59423 17.4885 7.59446L12.4885 7.59881C11.9362 7.59929 11.4881 7.15196 11.4876 6.59968C11.4872 6.04739 11.9345 5.59929 12.4868 5.59881L15.2349 5.59642C15.2218 5.58031 15.2092 5.56369 15.197 5.54658Z" fill="url(#paint0_linear_564_6800)" />
        <defs>
          <linearGradient id="paint0_linear_564_6800" x1="3.89554" y1="17.9132" x2="19.8831" y2="7.83333" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default RefreshIcon;