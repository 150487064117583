import React from 'react';

const TrophyIcon = ({ fill = "#B6B6B6" }) => (
  <svg width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0994 11.3213L8.5994 11.3213L8.5994 14.3213L7.0994 14.3213C6.68518 14.3213 6.3494 14.6571 6.3494 15.0713C6.3494 15.4855 6.68518 15.8213 7.0994 15.8213L8.5994 15.8213L10.0994 15.8213L11.5994 15.8213C12.0136 15.8213 12.3494 15.4855 12.3494 15.0713C12.3494 14.6571 12.0136 14.3213 11.5994 14.3213L10.0994 14.3213L10.0994 11.3213Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.20654 4.57129C2.90472 4.57129 1.8494 5.62662 1.8494 6.92843C1.8494 8.52611 3.14457 9.82129 4.74225 9.82129L6.3494 9.82129L6.3494 8.32129L4.74225 8.32129C3.973 8.32129 3.3494 7.69769 3.3494 6.92843C3.3494 6.45504 3.73315 6.07129 4.20654 6.07129L6.3494 6.07129L6.3494 4.57129L4.20654 4.57129ZM14.4923 4.57129C15.7941 4.57129 16.8494 5.62662 16.8494 6.92843C16.8494 8.52611 15.5542 9.82129 13.9565 9.82129L12.3494 9.82129L12.3494 8.32129L13.9565 8.32129C14.7258 8.32129 15.3494 7.69769 15.3494 6.92843C15.3494 6.45504 14.9656 6.07129 14.4923 6.07129L12.3494 6.07129L12.3494 4.57129L14.4923 4.57129Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.8494 6.30206C4.8494 4.93197 5.96007 3.82129 7.33016 3.82129L11.3686 3.82129C12.7387 3.82129 13.8494 4.93197 13.8494 6.30206L13.8494 8.32129C13.8494 10.8066 11.8347 12.8213 9.3494 12.8213C6.86411 12.8213 4.8494 10.8066 4.8494 8.32129L4.8494 6.30206ZM7.33016 5.32129C6.7885 5.32129 6.3494 5.76039 6.3494 6.30206L6.3494 8.32129C6.3494 9.97814 7.69254 11.3213 9.3494 11.3213C11.0062 11.3213 12.3494 9.97814 12.3494 8.32129L12.3494 6.30206C12.3494 5.76039 11.9103 5.32129 11.3686 5.32129L7.33016 5.32129Z" fill={fill} />
  </svg>
);

export default TrophyIcon;
