import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from 'chart.js';
import { useState, useMemo, useCallback } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

const MetricsComponent = ({ actions, completedActionsSum, overdueActionsSum, todoActionsSum, sessionsSum }) => {
  const [selectedFilter, setSelectedFilter] = useState("Weeks");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleFilterChange = useCallback((filter) => {
    setSelectedFilter(filter);
    handleClose();
  }, [handleClose]);

  const {
    completedActionsPerWeek,
    averageCompletedActionsPerWeek,
    completedActionsPerMonth,
    averageCompletedActionsPerMonth,
    assignedActionsPerWeek,
    assignedActionsPerMonth,
    weekLabels,
    monthLabels,
    weeksTrimmed,
    monthsTrimmed
  } = useMemo(() => {
    let completedActionsPerWeek = [0];
    let averageCompletedActionsPerWeek = 0;
    let completedActionsPerMonth = [0];
    let averageCompletedActionsPerMonth = 0;
    let assignedActionsPerWeek = [0];
    let assignedActionsPerMonth = [0];
    let totalWeeks = 0;
    let months = 0;
    let earliestDate;
    const endDate = new Date();

    if (actions && actions.length > 0) {
      earliestDate = new Date(actions.reduce((min, action) => {
        const date = action.call_date ? new Date(action.call_date) : new Date(action.created_at);
        return date < min ? date : min;
      }, new Date(actions[0].call_date || actions[0].created_at)));

      const timeDifference = endDate - earliestDate;
      totalWeeks = Math.max(1, Math.ceil(timeDifference / (7 * 24 * 60 * 60 * 1000)));

      assignedActionsPerWeek = Array.from({ length: totalWeeks }, () => 0);
      completedActionsPerWeek = Array.from({ length: totalWeeks }, () => 0);

      actions.forEach(action => {
        const actionDate = action.call_date ? new Date(action.call_date) : new Date(action.created_at);
        for (let i = 0; i < totalWeeks; i++) {
          const weekStart = new Date(earliestDate.getTime() + (i * 7 * 24 * 60 * 60 * 1000));
          const weekEnd = new Date(earliestDate.getTime() + ((i + 1) * 7 * 24 * 60 * 60 * 1000));
          if (actionDate >= weekStart && actionDate < weekEnd) {
            assignedActionsPerWeek[i] += 1;
            break;
          }
        }
      });

      months = Math.max(1, (endDate.getFullYear() - earliestDate.getFullYear()) * 12 + (endDate.getMonth() - earliestDate.getMonth()) + 1);
      const amountsByMonth = actions.reduce((acc, action) => {
        const date = action.call_date ? new Date(action.call_date) : new Date(action.created_at);
        const year = date.getFullYear();
        const month = date.getMonth();
        const monthKey = `${year}-${month + 1}`;
        acc[monthKey] = (acc[monthKey] || 0) + 1;
        return acc;
      }, {});

      assignedActionsPerMonth = Array.from({ length: months }, (_, i) => {
        const date = new Date(earliestDate.getFullYear(), earliestDate.getMonth() + i, 1);
        const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
        return amountsByMonth[monthKey] || 0;
      });

      const completedActions = actions.filter(action => action.status === "complete");

      if (completedActions.length > 0) {
        completedActionsPerWeek = Array.from({ length: totalWeeks }, () => 0);
        completedActions.forEach(action => {
          const completedDate = new Date(action.completed_at);
          for (let i = 0; i < totalWeeks; i++) {
            const weekStart = new Date(earliestDate.getTime() + (i * 7 * 24 * 60 * 60 * 1000));
            const weekEnd = new Date(earliestDate.getTime() + ((i + 1) * 7 * 24 * 60 * 60 * 1000));
            if (completedDate >= weekStart && completedDate < weekEnd) {
              completedActionsPerWeek[i] += 1;
              break;
            }
          }
        });

        averageCompletedActionsPerWeek = completedActions.length / totalWeeks;

        const completedAmountsByMonth = completedActions.reduce((acc, action) => {
          const date = new Date(action.completed_at);
          const year = date.getFullYear();
          const month = date.getMonth();
          const monthKey = `${year}-${month + 1}`;
          acc[monthKey] = (acc[monthKey] || 0) + 1;
          return acc;
        }, {});

        completedActionsPerMonth = Array.from({ length: months }, (_, i) => {
          const date = new Date(earliestDate.getFullYear(), earliestDate.getMonth() + i, 1);
          const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
          return completedAmountsByMonth[monthKey] || 0;
        });

        averageCompletedActionsPerMonth = completedActionsPerMonth.reduce((sum, value) => sum + value, 0) / months;
      }
    }

    let weekLabels = Array.from({ length: totalWeeks }, (_, index) => {
      const startDate = new Date(earliestDate.getTime() + (index * 7 * 24 * 60 * 60 * 1000));
      return `${startDate.getMonth() + 1}/${startDate.getDate()}`;
    });

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let monthLabels = Array.from({ length: months }, (_, index) => {
      const date = new Date(earliestDate.getFullYear(), earliestDate.getMonth() + index, 1);
      return monthNames[date.getMonth()];
    });

    const weeksTrimmed = assignedActionsPerWeek.length > 10;
    const monthsTrimmed = assignedActionsPerMonth.length > 10;

    if (weeksTrimmed) {
      assignedActionsPerWeek = assignedActionsPerWeek.slice(-10);
      completedActionsPerWeek = completedActionsPerWeek.slice(-10);
      weekLabels = weekLabels.slice(-10);
    }

    if (monthsTrimmed) {
      assignedActionsPerMonth = assignedActionsPerMonth.slice(-10);
      completedActionsPerMonth = completedActionsPerMonth.slice(-10);
      monthLabels = monthLabels.slice(-10);
    }

    return {
      completedActionsPerWeek,
      averageCompletedActionsPerWeek,
      completedActionsPerMonth,
      averageCompletedActionsPerMonth,
      assignedActionsPerWeek,
      assignedActionsPerMonth,
      weekLabels,
      monthLabels,
      weeksTrimmed,
      monthsTrimmed
    };
  }, [actions]);

  const weekOptions = useMemo(() => ({
    scales: {
      x: {
        grid: {
          display: false,
        },
        min: 0,
        beginAtZero: true,
        title: {
          display: true,
          text: !weeksTrimmed ? 'TIME (WEEKS)' : 'TIME (LAST 10 WEEKS)',
          font: {
            family: 'Lexend',
            size: "10px",
            color: '#202226',
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        min: 0,
        beginAtZero: true,
        grid: {
          display: true,
          color: '#F1F1F1',
          drawTicks: true,
          drawBorder: false,
        },
        ticks: {
          padding: 10,
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null;
          },
        },
      },
    },
    elements: {
      line: {
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 3,
          boxHeight: 3,
          font: {
            size: 10,
            family: 'Lexend',
            style: 'normal',
            color: "#202226",
          },
        },
      },
    },
    fill: true,
    tension: 0.4,
  }), [weeksTrimmed]);

  const monthOptions = useMemo(() => ({
    scales: {
      x: {
        grid: {
          display: false,
        },
        min: 0,
        beginAtZero: true,
        title: {
          display: true,
          text: !monthsTrimmed ? 'TIME (MONTHS)' : 'TIME (LAST 10 MONTHS)',
          font: {
            family: 'Lexend',
            size: "10px",
            color: '#202226',
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        min: 0,
        beginAtZero: true,
        grid: {
          display: true,
          color: '#F1F1F1',
          drawTicks: true,
          drawBorder: false,
        },
        ticks: {
          padding: 10,
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null;
          },
        },
      },
    },
    elements: {
      line: {
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 3,
          boxHeight: 3,
          font: {
            size: 10,
            family: 'Lexend',
            style: 'normal',
            color: "#202226",
          },
        },
      },
    },
    fill: true,
    tension: 0.4,
  }), [monthsTrimmed]);

  const weekData = useMemo(() => ({
    labels: weekLabels,
    datasets: [
      {
        label: 'COMPLETED',
        data: completedActionsPerWeek,
        borderColor: '#568FF3',
        backgroundColor: '#568FF3',
        borderWidth: 5,
        pointRadius: 0,
      },
      {
        label: 'ASSIGNED',
        data: assignedActionsPerWeek,
        borderColor: '#DDE5ED',
        backgroundColor: '#DDE5ED',
        borderWidth: 5,
        pointRadius: 0,
      },
    ],
  }), [weekLabels, completedActionsPerWeek, assignedActionsPerWeek]);

  const monthData = useMemo(() => ({
    labels: monthLabels,
    datasets: [
      {
        label: 'COMPLETED',
        data: completedActionsPerMonth,
        borderColor: '#568FF3',
        backgroundColor: '#568FF3',
        borderWidth: 5,
        pointRadius: 0,
      },
      {
        label: 'ASSIGNED',
        data: assignedActionsPerMonth,
        borderColor: '#DDE5ED',
        backgroundColor: '#DDE5ED',
        borderWidth: 5,
        pointRadius: 0,
      },
    ],
  }), [monthLabels, completedActionsPerMonth, assignedActionsPerMonth]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "hidden" }}>
      <Box sx={{ display: "flex", padding: "20px", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Typography sx={{ fontSize: "24px", fontFamily: "Lexend", fontWeight: 500, color: "black" }}>
            Actions Tracker
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Typography sx={{ fontFamily: "Lexend", color: "#B6B6B6" }}>
            {"Filter by "} <span>{selectedFilter}</span>
          </Typography>
          <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
            <KeyboardArrowDown sx={{ fill: "#93989A" }} />
          </IconButton>
          <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{
            sx: {
              display: "flex",
              background: "#fff",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
              borderRadius: "12pt",
              paddingY: "6px",
              width: "80px",
              marginTop: "-15px",
              zIndex: "50"
            },
          }}>
            <MenuItem sx={{ color: "black", textAlign: "center", justifyContent: "center", width: "100%", gap: "4px", "&:hover": { backgroundColor: "#f5f5f5" } }} onClick={() => handleFilterChange("Weeks")}>
              {selectedFilter === "Weeks" && <Box sx={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: "black" }} />}
              Weeks
            </MenuItem>
            <MenuItem sx={{ color: "black", textAlign: "center", justifyContent: "center", width: "100%", gap: "4px", "&:hover": { backgroundColor: "#f5f5f5" } }} onClick={() => handleFilterChange("Months")}>
              {selectedFilter === "Months" && <Box sx={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: "black" }} />}
              Months
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "0px 40px", height: "300px", maxWidth: "650px" }}>
        {selectedFilter === "Weeks" && <Line options={weekOptions} data={weekData} />}
        {selectedFilter === "Months" && <Line options={monthOptions} data={monthData} />}
      </Box>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", padding: "0px 40px", alignItems: "center", gap: "20px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: 600, color: "black" }}>Weekly Completed</Typography>
          <Typography sx={{ fontFamily: "Poppins", fontSize: "40px", lineHeight: "26px", fontWeight: "light", color: "rgba(32, 34, 38, 0.7)" }}>
            {averageCompletedActionsPerWeek.toFixed(2)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: 600, color: "black" }}>Monthly Completed</Typography>
          <Typography sx={{ fontFamily: "Poppins", fontSize: "40px", lineHeight: "26px", fontWeight: "light", color: "rgba(32, 34, 38, 0.7)" }}>
            {averageCompletedActionsPerMonth.toFixed(2)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: 600, color: "black" }}>All-Time Completed</Typography>
          <Typography sx={{ fontFamily: "Poppins", fontSize: "40px", lineHeight: "26px", fontWeight: "light", color: "rgba(32, 34, 38, 0.7)" }}>
            {completedActionsSum}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", padding: "20px", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", marginTop: "20px", overflow: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", paddingRight: "20px", borderRight: "1px solid #D4D4D4" }}>
          <Typography sx={{ fontSize: "40px", fontWeight: "300", color: "black", lineHeight: 1 }}>
            {sessionsSum ?? "-"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: "600", lineHeight: "1.2", color: "black", textAlign: "center" }}>
            Coaching<br />Sessions
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingX: "20px", alignItems: "center", borderRight: "1px solid #D4D4D4" }}>
          <Typography sx={{ fontSize: "40px", fontWeight: "300", color: "black", lineHeight: 1 }}>
            {todoActionsSum ?? "-"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: "600", lineHeight: "1.2", color: "black", textAlign: "center" }}>
            Actions<br />To-Do
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingX: "20px", alignItems: "center", borderRight: "1px solid #D4D4D4" }}>
          <Typography sx={{ fontSize: "40px", fontWeight: "300", color: "black", lineHeight: 1 }}>
            {completedActionsSum ?? "-"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: "600", lineHeight: "1.2", color: "black", textAlign: "center" }}>
            Actions<br />Completed
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingX: "20px", alignItems: "center" }}>
          <Typography sx={{ fontSize: "40px", fontWeight: "300", color: "black", lineHeight: 1 }}>
            {overdueActionsSum ?? "-"}
          </Typography>
          <Typography sx={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: "600", lineHeight: "1.2", color: "black", textAlign: "center" }}>
            Actions<br />Overdue
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MetricsComponent;
