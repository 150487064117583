import { BorderColor, Check, ErrorOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  TextField,
  Tooltip,
  Typography,
  linearProgressClasses,
  tooltipClasses,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "@emotion/styled";
import ArrowForward from "components/Icons/ArrowForward.svg";
import AddGoal from "../../components/CoachClientOverview/AddGoal";
import PositiveActionButton from "components/PositiveActionButton";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ClientAddGoal from "./ClientAddGoal";
import NegativeActionButton from "components/NegativeActionButton";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import { formatDistanceToNow } from "date-fns";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const ClientGoalCard = ({ user, fetchClientGoalsData, goals, setGoals }) => {
  const [openCompleteGoalModal, setOpenCompleteGoalModal] = useState(false);
  const [completeGoal, setCompleteGoal] = useState({});
  const [coaches, setCoaches] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [editGoal, setEditGoal] = useState({});
  const [openEditGoalModal, setOpenEditGoalModal] = useState(false);
  const [openExploreGoalModal, setOpenExploreGoalModal] = useState(false);
  const [editGoalDate, setEditGoalDate] = useState("");
  const [clientData, setClientData] = useState([]);
  const [tabs, setTabs] = useState("todo");
  const [initialGoalDate, setInitialGoalDate] = useState("");
  const [activeTab, setActiveTab] = useState("editGoal");
  const [loading, setLoading] = useState(false);
  const handleTabClick = (tab) => {
    setTabs(tab);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.common.white,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundImage: "linear-gradient(to right, #016096, white)",
    },
  }));

  const handleEditClose = () => {
    setOpenEditGoalModal(false);
    setActiveTab("editGoal");
    setEditGoalDate("");
    setInitialGoalDate("");
  };

  const handleExploreGoalModalClose = () => {
    setSelectedGoal(null);
    setOpenExploreGoalModal(false);
  };

  const handleCompleteClose = () => {
    setOpenCompleteGoalModal(false);
  };
  // const { authStatus, user } = useAuthenticator((context) => [
  //   context.authStatus,
  //   context.user,
  // ]);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await API.get(
        "zoomapi",
        "/zoom/fetch-client-data-test",
        {
          queryStringParameters: {
            client_id: user?.attributes?.sub,
          },
        }
      );
      if (!response.client_data) {
        setLoading(false);
        return;
      }

      const data = response.client_data;
      const coaches = response.coaches;
      setClientData(data);
      setCoaches(coaches);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  useEffect(() => {
    if (user?.attributes?.sub) {
      fetchClientData();
      fetchClientGoalsData();
    }
  }, [user]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: "24px",
      backgroundColor: "#F0F0F0",
      color: "#202226",
      maxWidth: 330,
      fontSize: "12px",
      borderRadius: "25px",
    },
  }));

  const formik5 = useFormik({
    initialValues: {
      goal_title: editGoal.title,
      goal_description: editGoal.description,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      goal_title: Yup.string().required("required"),
      goal_description: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          goal_id: editGoal.goal_id,
          client_id: clientData.user_id,
          title: values.goal_title,
          description: values.goal_description,
          goal_type: null,
          due_date: editGoalDate ? editGoalDate : null,
          created_by_userID: user?.attributes?.sub,
          created_by_name:
            user?.attributes?.given_name + " " + user?.attributes?.family_name,
          edited_by_userID: user?.attributes?.sub,
          edited_by_name:
            user?.attributes?.given_name + " " + user?.attributes?.family_name,
          initial_title: formik5.initialValues.goal_title,
          initial_description: formik5.initialValues.goal_description,
          initial_due_date: initialGoalDate,
          add: false,
        };
        setLoading(true);
        const response = await API.put(
          "zoomapi",
          "/zoom/save-client-goal-test",
          {
            body: body,
          }
        );
        setEditGoal({});
        setEditGoalDate("");
        await fetchClientGoalsData();
        setOpenEditGoalModal(false);
        handleExploreGoalModalClose();
        setLoading(false);
      } catch (err) {
        console.log("Error in update", err.message);
        toast.error(err.message);
        setLoading(false);
      }
    },
  });

  const completeGoalModal = () => {
    return (
      <Modal
        open={openCompleteGoalModal}
        onClose={() => setOpenCompleteGoalModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected goals as completed?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCompleteClose} label="No" />
            <ConfirmButton onClick={completeGoals} label="Yes" />
          </Box>
        </Box>
      </Modal>
    );
  };

  const editGoalModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalGoal = {};
    let sortedEdits = [];
    if (editGoal && editGoal.edits && editGoal.edits.length > 0) {
      sortedEdits = [...editGoal.edits].sort(
        (a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp)
      );
      originalGoal = {
        title: sortedEdits[0].previous_title,
        description: sortedEdits[0].previous_description,
        due_date: sortedEdits[0].previous_due_date,
        created_by: editGoal.created_by_name,
      };
    } else {
      originalGoal = {
        title: editGoal.title,
        description: editGoal.description,
        due_date: editGoal.due_date,
        created_by: editGoal.created_by_name,
      };
    }

    return (
      <Modal
        open={openEditGoalModal}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        justifyContent="center"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
              },
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: {
                xs: "8px",
                md: "16px",
              },
            }}
          >
            <Box display="flex" gap="10px">
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editGoal" ? "1px solid black" : "none",
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editGoal")}
              >
                Edit Goal
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "viewHistory" ? "1px solid black" : "none",
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("viewHistory")}
              >
                View History
              </Typography>
            </Box>
          </Box>
          {activeTab === "viewHistory" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                height: "432px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {sortedEdits &&
                sortedEdits
                  .slice()
                  .reverse()
                  .map((edit, index) => (
                    <Box key={index} sx={{ gap: "2px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "black",
                        }}
                      >
                        {`${edit.new_title}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {`${edit.new_description}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "black",
                        }}
                      >
                        Due on{" "}
                        {`${dayjs(edit.new_due_date).format("MM-DD-YYYY")}`}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2D2D2D",
                          fontSize: "12px",
                          marginLeft: "10px",
                          marginBottom: "6px",
                        }}
                      >
                        edited by{" "}
                        {edit.edited_by_userid === user?.attributes?.sub
                          ? "me"
                          : edit.edited_by_name}{" "}
                        {formatDistanceToNow(new Date(edit.edit_timestamp))} ago
                      </Typography>
                      <Divider color="#d3d3d3" />
                    </Box>
                  ))}
              {editGoal.edits && originalGoal && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    {`${originalGoal.title}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {`${originalGoal.description}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    Due on{" "}
                    {`${dayjs(originalGoal.due_date).format("MM-DD-YYYY")}`}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2D2D2D",
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    orginal goal by {originalGoal.created_by}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {activeTab === "editGoal" && (
            <Box
              sx={{
                width: {
                  xs: "100%",
                },
              }}
            >
              <form onSubmit={formik5.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "22px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "uppercase",
                      }}
                    >
                      Title
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      autoFocus
                      error={
                        formik5.touched.goal_title &&
                        Boolean(formik5.errors.goal_title)
                      }
                      {...formik5.getFieldProps("goal_title")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                    />
                    {formik5.touched.goal_title &&
                      formik5.errors.goal_title && (
                        <Typography variant="caption" color="error">
                          {formik5.errors.goal_title}
                        </Typography>
                      )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "uppercase",
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      autoFocus
                      multiline
                      rows={4}
                      error={
                        formik5.touched.goal_description &&
                        Boolean(formik5.errors.goal_description)
                      }
                      {...formik5.getFieldProps("goal_description")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                    />
                    {formik5.touched.goal_description &&
                      formik5.errors.goal_description && (
                        <Typography variant="caption" color="error">
                          {formik5.errors.goal_description}
                        </Typography>
                      )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "uppercase",
                      }}
                    >
                      Due Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          editGoal.due_date &&
                          dayjs(editGoal.due_date).isValid()
                            ? dayjs(editGoal.due_date)
                            : null
                        }
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = dayjs(date)
                              .utc()
                              .format("YYYY-MM-DD");
                            setEditGoalDate(formattedDate);
                          }
                        }}
                        sx={{
                          borderRadius: "16px",
                          border: "1px solid #C4CAD4",
                          "& svg": {
                            color: "#22272F",
                          },
                          "& input": {
                            color: "#22272F",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box
                  marginTop="42px"
                  display="flex"
                  justifyContent="flex-end"
                  gap="15px"
                >
                  <NegativeActionButton
                    onClick={handleEditClose}
                    label={"Cancel"}
                  />
                  <PositiveActionButton
                    onClick={formik5.handleSubmit}
                    loading={loading}
                    label={"Save Goal"}
                    disabled={
                      editGoalDate === initialGoalDate &&
                      formik5.values.goal_title === editGoal.title &&
                      formik5.values.goal_description === editGoal.description
                    }
                  />
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Modal>
    );
  };

  const exploreGoalModal = () => {
    return (
      <Modal
        open={openExploreGoalModal}
        onClose={handleExploreGoalModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        justifyContent="center"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxHeight: "90vh", // Ensure the modal has a consistent height
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontFamily: "Lexend",
                fontWeight: 300,
                fontSize: { xs: "24px", md: "36px" },
                lineHeight: "45px",
              }}
            >
              Goal Tracker
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  height: "60%",
                  background: "white",
                  border: "1px solid black",
                  borderRadius: "43px",
                  paddingX: "30px",
                  paddingY: "6px",
                  gap: "10px",
                  alignItems: "center",
                }}
                onClick={() => {
                  setEditGoal(selectedGoal);
                  setInitialGoalDate(selectedGoal.due_date);
                  setEditGoalDate(selectedGoal.due_date);
                  setOpenEditGoalModal(true);
                }}
              >
                <Typography
                  sx={{
                    color: "#202226",
                    fontSize: "10px",
                    fontFamily: "Lexend",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                >
                  Edit
                </Typography>
                <BorderColor
                  sx={{
                    fill: "black",
                    fontSize: "12px",
                  }}
                />
              </Button>
              {selectedGoal && selectedGoal.status !== "complete" && (
                <Button
                  sx={{
                    height: "60%",
                    background: "white",
                    border: "1px solid black",
                    borderRadius: "43px",
                    paddingX: "30px",
                    paddingY: "6px",
                    alignItems: "center",
                    color: "#50B500",
                    "&:hover": {
                      cursor: "pointer",
                      color: "white",
                      background: "rgba(80, 181, 0, 0.4)",
                    },
                  }}
                  onClick={() => {
                    setCompleteGoal(selectedGoal);
                    setOpenCompleteGoalModal(true);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontFamily: "Lexend",
                      fontWeight: 500,
                      textTransform: "uppercase",
                    }}
                  >
                    Mark as Complete
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
              },
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                md: "16px",
              },
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "2px",
                  textAlign: "left",
                }}
              >
                Goal
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18.9px",
                  padding: "16px",
                  borderRadius: "16px",
                  border: "1px solid #F1F1F1",
                }}
              >
                {selectedGoal && selectedGoal.title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "2px",
                  textAlign: "left",
                }}
              >
                Description
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18.9px",
                  padding: "16px",
                  borderRadius: "16px",
                  border: "1px solid #F1F1F1",
                }}
              >
                {selectedGoal && selectedGoal.description}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "2px",
                  textAlign: "left",
                }}
              >
                Due Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18.9px",
                  padding: "16px",
                  borderRadius: "16px",
                  border: "1px solid #F1F1F1",
                }}
              >
                {selectedGoal
                  ? dayjs(selectedGoal.due_date).utc().format("MM/DD/YY")
                  : ""}
              </Typography>
            </Box>
            {selectedGoal && selectedGoal?.coach_full_name && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "18px",
                    letterSpacing: "2px",
                    textAlign: "left",
                  }}
                >
                  Coach
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "18.9px",
                    padding: "16px",
                    borderRadius: "16px",
                    border: "1px solid #F1F1F1",
                  }}
                >
                  {selectedGoal.coach_full_name}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "2px",
                  textAlign: "left",
                }}
              >
                Action Metrics
              </Typography>
              <Box
                sx={{
                  borderRadius: "16px",
                  border: "1px solid #F1F1F1",
                  padding: "10px",
                }}
              >
                {selectedGoal && selectedGoal.actions && (
                  <GoalActionsMetrics actions={selectedGoal.actions} />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                flexGrow: 1, // Allow this section to grow
                overflowY: "auto", // Make only this section scrollable
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "18px",
                  letterSpacing: "2px",
                  textAlign: "left",
                }}
              >
                Actions List
              </Typography>
              {/* Column headers */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  borderBottom: "1px solid #ddd",
                  paddingBottom: "8px",
                  marginBottom: "8px",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#B6B6B6",
                    width: "60%",
                  }}
                >
                  Action
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#B6B6B6",
                    width: "25%",
                  }}
                >
                  Call Date
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#B6B6B6",
                    width: "25%",
                  }}
                >
                  Due Date
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#B6B6B6",
                    width: "25%",
                  }}
                >
                  Status
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  maxHeight: "300px", // Set a max height for the scrollable section
                }}
              >
                {selectedGoal && selectedGoal.actions
                  ? selectedGoal.actions.map((action, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            marginBottom: "8px",
                            color: "black",
                            width: "60%",
                          }}
                        >
                          {action.action}
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "8px",
                            color: "black",
                            alignItems: "center",
                            width: "25%",
                          }}
                        >
                          {action.call_date
                            ? dayjs(action.call_date).utc().format("MM/DD/YY")
                            : "        "}
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "8px",
                            color: "black",
                            alignItems: "center",
                            width: "25%",
                          }}
                        >
                          {action.due_date
                            ? dayjs(action.due_date).utc().format("MM/DD/YY")
                            : "        "}
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "8px",
                            color: "black",
                            alignItems: "center",
                            width: "25%",
                          }}
                        >
                          {action.status ? action.status : ""}
                        </Typography>
                      </Box>
                    ))
                  : "This goal has no actions assigned"}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  const GoalActionsMetrics = ({ actions }) => {
    // Calculate the counts for different types of actions
    const totalActions = actions.length;

    let toDoActions = 0;
    let completedActions = 0;
    let overdueActions = 0;

    actions.forEach((action) => {
      if (action.status === "complete") {
        completedActions++;
      } else {
        toDoActions++;
      }

      if (
        action.due_date &&
        dayjs(action.due_date).isBefore(dayjs(), "day") &&
        action.status !== "complete"
      ) {
        overdueActions++;
      }
    });

    const percentageActionsCompleted =
      totalActions > 0 ? (completedActions / totalActions) * 100 : 0;
    const roundedPercentage = Math.round(percentageActionsCompleted); // Round to the nearest whole number
    console.log("percentage: ", roundedPercentage);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "black",
              textAlign: "center",
            }}
          >
            Total <br /> {totalActions}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "black",
              textAlign: "center",
            }}
          >
            To-Do <br /> {toDoActions}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "black",
              textAlign: "center",
            }}
          >
            Completed <br /> {completedActions}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "1.2",
              color: "black",
              textAlign: "center",
            }}
          >
            Overdue <br /> {overdueActions}
          </Typography>
        </Box>
      </Box>
    );
  };

  const completeGoals = async () => {
    try {
      const body = {
        status: "complete",
        goal_id: completeGoal.goal_id,
        date_completed: new Date().toISOString().split("T")[0], // ISO string format, just date part
      };
      const response = await API.put("zoomapi", "/zoom/update-goal-status", {
        body: body,
      });

      setOpenCompleteGoalModal(false);
      setGoals((prev) =>
        prev.map((prevRow) =>
          prevRow.goal_id === completeGoal.goal_id
            ? {
                ...prevRow,
                status: "complete",
                date_completed: new Date().toISOString().split("T")[0],
              }
            : prevRow
        )
      );
      setCompleteGoal({});

      toast(`Goal marked as complete`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
    } catch (err) {
      console.log("Error in update", err.message);
      toast.error(err.message, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
      });
    }
  };

  const findCoachNameById = (coachId) => {
    const coach = coaches.find((coach) => coach.user_id === coachId);
    return coach ? coach.full_name : null;
  };

  const GoalComponent = ({ goal }) => {
    return (
      <Box
        sx={{
          padding: "24px",
          borderRadius: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          background: "#F6F6F6",
          "&:hover": {
            cursor: "pointer",
            background: "#d3d3d3",
          },
        }}
        onClick={() => {
          setSelectedGoal(goal);
          setOpenExploreGoalModal(true);
        }}
      >
        <Box display="flex" flexDirection="column" gap="8px" width="100%">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={subHeadingStyle}>{goal.title}</Typography>
              <Typography
                sx={{
                  justifyContent: "left",
                  fontFamily: "Lexend",
                  fontSize: "12px",
                  lineHeight: "2.6",
                  fontWeight: "500",
                  color: "#B6B6B6",
                }}
              >
                {dayjs(goal.due_date).utc().format("MM.DD.YY")}
              </Typography>
            </Box>
            {goal.coach_full_name && (
              <Typography
                sx={{
                  justifyContent: "left",
                  fontFamily: "Lexend",
                  fontSize: "10px",
                  lineHeight: "2.6",
                  fontWeight: "500",
                  color: "black",
                }}
              >
                Coach: {goal.coach_full_name}
              </Typography>
            )}
            <Box
              sx={{
                maxHeight: "35px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.)
                },
                "-ms-overflow-style": "none", // Hide the scrollbar for Internet Explorer and Edge
                scrollbarWidth: "none", // Hide the scrollbar for Firefox
              }}
            >
              <Typography sx={textStyle}>{goal.description}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.2",
                  color: "black",
                  textAlign: "left",
                }}
              >
                Action Metrics
              </Typography>
              <GoalActionsMetrics actions={goal.actions} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const goalComponent = () => {
    return (
      <>
        {tabs === "todo" &&
          goals?.map(
            (goal, index) =>
              goal.status === "incomplete" && (
                <GoalComponent key={index} goal={goal} />
              )
          )}
        {tabs === "completed" &&
          goals?.map(
            (goal, index) =>
              goal.status === "complete" && (
                <GoalComponent key={index} goal={goal} />
              )
          )}
      </>
    );
  };

  return (
    <>
      {editGoalModal()}
      {completeGoalModal()}
      {exploreGoalModal()}
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "40px",
          background: "white",
          overflow: "hidden",
          minHeight: "200px",
          height: "100%",
          width: "100%",
          gap: "20px",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "28px",
              lineHeight: "1.2",
              color: "black",
            }}
          >
            My Goals
          </Typography>
          <ClientAddGoal
            user={user}
            clientData={clientData}
            coaches={coaches}
            loading={loading}
            setLoading={setLoading}
            fetchClientGoalsData={fetchClientGoalsData}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflowX: "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  textTransform: "uppercase",
                  paddingBottom: "8px",
                  borderBottom: tabs === "todo" ? "2px solid black" : "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabClick("todo")}
              >
                To-Do
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  textTransform: "uppercase",
                  paddingBottom: "8px",
                  borderBottom:
                    tabs === "completed" ? "2px solid black" : "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabClick("completed")}
              >
                Completed
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingRight: {
                lg: "8px",
              },
              gap: "16px",
              overflowY: {
                xs: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgb(207, 207, 207)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgb(207, 207, 207)",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "black" }} />
              </Box>
            ) : (
              <>{goalComponent()}</>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ClientGoalCard;

const textStyle = {
  color: "#202226",
  fontWeight: "400",
  fontSize: "12px",
  fontFamily: "Montserrat",
};

const subHeadingStyle = {
  color: "black",
  fontWeight: "500",
  fontSize: "18px",
  fontFamily: "Montserrat",
  lineHeight: "135%",
};

//   <ClientAddGoal
//   user={user}
//   clientData={clientData}
//   loading={loading}
//   setLoading={setLoading}
//   fetchClientGoalsData={fetchClientGoalsData}
// />
