import { Button, CircularProgress, Typography } from "@mui/material";

const ConfirmButton = ({ onClick, label, disabled, loading }) => {
  return (
    <Button 
        sx={{
            paddingX: "80pt",
            paddingY: "10pt",
            borderRadius: "52px",
            color: "white",
            background:  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              background: "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
              cursor: "pointer"
            },
            "&:disabled": {
              background: "#d3d3d3", 
              color: "#B7B7B7",
              border: "none",
              cursor: "not-allowed",
            },
        }}
        disabled={disabled || loading}
        onClick={onClick}>
      {loading ? (
        <CircularProgress style={{ color: "black" }} />
      ) : (
        <Typography
          sx={{
            fontFamily: "Lexend",
            color: "white",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "bold",
            letterSpacing: "2px",
            textTransform: "uppercase",
          }}
        >
          {label}
        </Typography>
      )}
    </Button>
  );
};

export default ConfirmButton;
