import { Box, CircularProgress, Typography } from "@mui/material";

const ActionsMetrics = ({ loading, lastActionCount, todoActionsSum, completedActionsSum, overdueActionsCount }) => {
    return(
        <Box
        sx={{
          width: "100%",
          position: "relative",
          borderRadius: "40px",
          background:
          "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
          display: "flex",
          gap: {xs: "8px", md: "24px"},
          padding: {xs: "8px 12px", md:"24px 40px"},
          alignItems: "center",
        }}
      >

        <Box
          sx={{
            display: "flex",
            flex: "1",
            justifyContent: "center"
          }}
        >
          <Box
            sx={{
              flex: "unset",
              padding: {xs: "0 20px", md: "0 50px"},
              height: "100%",
              borderRight: "1px solid #FFF",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      height: "100%",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          md: "56px",
                        },
                        fontWeight: 300,
                        color: "#fff",
                        lineHeight: "1.2"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {lastActionCount ?? 0}
                      </Box>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: "#FFF",
                          fontFamily: "Lexend",
                          fontSize: {xs: "10px" , md:"14px"},
                          lineHeight: "1.2",
                          fontWeight: 600,
                        }}
                      >
                        New Actions
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              flex: "unset",
              padding: {xs: "0 20px", md: "0 50px"},
              height: "100%",
              borderRight: "1px solid #FFF",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      height: "100%",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          md: "56px",
                        },
                        fontWeight: 300,
                        color: "#fff",
                        lineHeight: "1.2"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {todoActionsSum ?? 0}
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 400,
                            color: "#FFF",
                            fontStyle: "italic",
                          }}
                        >
                        
                        </Typography>
                      </Box>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: "#FFF",
                          fontFamily: "Lexend",
                          fontSize: {xs: "10px" , md:"14px"},
                          lineHeight: "1.2",
                          fontWeight: 600,
                        }}
                      >
                        To-Do
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              flex: "unset",
              padding: {xs: "0 20px", md: "0 50px"},
              height: "100%",
              borderRight: "1px solid #FFF",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      height: "100%",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          md: "56px",
                        },
                        fontWeight: 300,
                        color: "#fff",
                        lineHeight: "1.2"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {completedActionsSum ?? 0}
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 400,
                            color: "#FFF",
                            fontStyle: "italic",
                          }}
                        >

                        </Typography>
                      </Box>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: "#FFF",
                          fontFamily: "Lexend",
                          fontSize: {xs: "10px" , md:"14px"},
                          lineHeight: "1.2",
                          fontWeight: 600,
                        }}
                      >
                        Completed
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              flex: "unset",
              padding: {xs: "0 20px", md: "0 50px"},
              height: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      height: "100%",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          md: "56px",
                        },
                        fontWeight: 300,
                        color: "#fff",
                        lineHeight: "1.2"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {overdueActionsCount ?? 0}
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 400,
                            color: "#FFF",
                            fontStyle: "italic",
                          }}
                        >
                        
                        </Typography>
                      </Box>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: "#FFF",
                          fontFamily: "Lexend",
                          fontSize: {xs: "10px" , md:"14px"},
                          lineHeight: "1.2",
                          fontWeight: 600,
                        }}
                      >
                        Overdue
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    )
}

export default ActionsMetrics;