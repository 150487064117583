import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify"; // Import Amplify Auth
import Box from "@mui/material/Box";
import "./PasswordReset.css";
import Logo from "scenes/Layout/logo";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    backgroundColor: {
      default: "rgba(26, 32, 44, 1)",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
};

const theme = createTheme(themeOptions);

export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmationCodeChange = (e) => {
    setConfirmationCode(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      await Auth.forgotPassword(email);
      setSuccess("Password reset code sent to your email.");
      setResetCodeSent(true)
    } catch (error) {
      setError(error.message);
    }
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
      setSuccess(
        "Password reset successful. You can now sign in with your new password."
      );
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
    <Box
      sx={{
        height: "100%",
        width: "100%",
        background: "#F7F7F7"
      }}
    >
      <Box
        display="flex"
        style={{ alignItems: "left", marginRight: "1200px" }}
      >
        <Logo />
      </Box>
      <Box
        display="flex"
        alignItems="left"
        justifyContent="left"
        
      >
        <ThemeProvider theme={theme}>
          <Container
            component="main"
            maxWidth="xs"
            style={{ marginTop: "600px" }}
          >
            <Box style={{ marginTop: "-500px", color: "black" }}>
              <Typography
                 sx={{
                  fontSize: "48px",
                  lineHeight: "1.17",
                  letterSpacing: "-0.02em",
                  color: "#22272F",
                  textAlign: "center"
                }}
              >
                Password Reset
              </Typography>
              {success && (
                <div style={{ color: "green", textAlign: "center" }}>
                  {success}
                </div>
              )}
              {error && (
                <div style={{ color: "red", textAlign: "center" }}>{error}</div>
              )}
              <form>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  onChange={handleEmailChange}
                  InputProps={{
                    style: {
                      color: "#22272F",
                      outline: "none"
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#454F5F",
                    },
                  }}
                  sx={{
                    color: "#22272F",
                    background: "white",
                    borderRadius: "16px",
                    width: "100%",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                  placeholder="Email Address"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: "16px",
                    width: "100%",
                    padding: "18px 0",
                    background: "white",
                    border: "1px solid black",
                    borderRadius: "43px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    transition: "all 0.3s",
                    "&:hover": {
                      background: "black",
                      "& *": {
                        color: "white",
                      },
                    },
                  }}
                  onClick={handlePasswordReset}
                >
                   <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "600",
                          letterSpacing: "0.4px",
                          textTransform: "uppercase",
                        }}
                      >
                        Send Password Reset Code
                      </Typography>
                </Button>
              </form>
              {resetCodeSent && (
              <form>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmationCode"
                  name="confirmationCode"
                  onChange={handleConfirmationCodeChange}
                  InputProps={{
                    style: {
                      color: "#22272F",
                      outline: "none"
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#454F5F",
                    },
                  }}
                  sx={{
                    color: "#22272F",
                    background: "white",
                    borderRadius: "16px",
                    width: "100%",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                  placeholder="Confirmation Code"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  type="password"
                  id="newPassword"
                  onChange={handleNewPasswordChange}
                  InputProps={{
                    style: {
                      color: "#22272F",
                      outline: "none"
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#454F5F",
                    },
                  }}
                  sx={{
                    color: "#22272F",
                    background: "white",
                    borderRadius: "16px",
                    width: "100%",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                  placeholder="New Password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={handleConfirmPassword}
                  sx={{
                    marginTop: "16px",
                    width: "100%",
                    padding: "18px 0",
                    background: "white",
                    border: "1px solid black",
                    borderRadius: "43px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    transition: "all 0.3s",
                    "&:hover": {
                      background: "black",
                      "& *": {
                        color: "white",
                      },
                    },
                  }}
                >
                  <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "600",
                          letterSpacing: "0.4px",
                          textTransform: "uppercase",
                        }}
                      >
                        Reset Password
                      </Typography>
                </Button>
              </form>)}
              <Box marginTop={"16px"} fontSize={"12px"}>
                <Typography component={"span"}>Back to </Typography>
                <Typography
                  component={"span"}
                  onClick={() => navigate("/signin")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Sign In
                </Typography>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </Box>
    </Box>
    </>
  );
}
