import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";

const MetricCards = ({ user, accessTo, zoomStatus, modal, handleOpen }) => {
  const [sessionSum, setSessionSum] = useState(0);
  const [coachingSum, setCoachingSum] = useState(0);
  const [actionsSum, setActionsSum] = useState(0);
  const [goalsCount, setGoalsCount] = useState(0);
  const [loading, setLoading] = useState(false)
  
  const fetchSum = async () => {
    if(!user)
      return
    setLoading(true)
    try {
      let response;
        if(user?.attributes?.["custom:is_client"] === "true" && accessTo === "platform_professional"){
          response = await API.get("zoomapi", "/zoom/fetch-professional-client-sum", {
            queryStringParameters: {
              user_id: user?.attributes?.sub,
            },
          });
        }else {      
          response = await API.get("zoomapi", "/zoom/fetch-client-sum", {
            queryStringParameters: {
              client_id: user?.attributes?.sub,
            },
          });
        }

      const coaching_sum = response.data.coaching_sum / 60;
      setSessionSum(response.data.session_sum);
      setCoachingSum(coaching_sum.toFixed(1));
      setActionsSum(response.data.actions_sum);
      setGoalsCount(response.data.goals_count)
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchSum()
  },[user])

    return(
        <>
          {modal}
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              width: "100%",
              gap: "8px"
            }}
          >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "40px",
                    padding: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                    background:
                      "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                }}
            >
                {loading && (
                    <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                    >
                        <CircularProgress sx={{color: "white"}} />
                    </Box>
                )}
                {!loading && (
                    <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      zIndex: 1,
                      width: "100%"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: {
                        xs: "24px"   
                        },
                        fontWeight: 300,
                        color: "white",
                        marginBottom: "8px",
                        lineHeight: "1.2",
                      }}
                    >
                      My Stats
                    </Typography>
                    <Typography
                        sx={{
                        color: "white",
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginBottom: "8px",
                        lineHeight: "1.2",
                    }}
                    >
                      Here's what's been happening
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "50px",
                        paddingY: "20px"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px"
                        }}
                      >
                        <Typography
                          sx={{
                          color: "white",
                          fontFamily: "Poppins",
                          fontSize: "56px",
                          fontWeight: 300,
                          lineHeight: "normal",
                          padding: "0px"
                      }}
                      >
                        {actionsSum ?? 0}
                      </Typography>
                        <Box
                          display="flex"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 700
                            }}
                          >
                            Total Actions
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                        <Typography
                          sx={{
                          color: "white",
                          fontFamily: "Poppins",
                          fontSize: "56px",
                          fontWeight: 300,
                          lineHeight: "normal",
                          padding: "0px"
                      }}
                      >
                        {goalsCount ?? 0}
                      </Typography>
                        <Box
                          display="flex"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 700
                            }}
                          >
                            Total Goals
                          </Typography>
                        </Box>
                      </Box>
    
                    </Box>
                    {accessTo === "platform_professional" && !zoomStatus && (
                        <Button
                          sx={{
                            color: "black",
                            background: "white",
                            maxWidth: "50%",
                            padding: "10px 8px",
                            borderRadius: "52px",
                            border: "1px solid #578FF2",
                            borderColor: "#578FF2",
                            "&:hover": {
                              cursor: "pointer",
                              background: "black",
                              borderColor: "black",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                          }}
                          onClick={handleOpen}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              letterSpacing: "2px",
                              lineHeight: "1.5",
                              textTransform: "uppercase",
                            }}
                          >
                            Connect to Zoom
                          </Typography>
                        </Button>
                      )}
                  </Box>
                )}
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRadius: "40px",
                padding: "30px",
                display: "flex",
                justifyContent: "space-between",
                background:
                    "linear-gradient(45deg, #FFFFFF 0%, #FFFFFF 70%, #98cbe9 100%)",
              }}
            >
              {loading && (
                    <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                    >
                        <CircularProgress sx={{color: "black"}} />
                    </Box>
                )}
                 {!loading && (
                    <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      zIndex: 1,
                      width: "100%"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: {
                        xs: "24px"   
                        },
                        fontWeight: 300,
                        color: "black",
                        marginBottom: "8px",
                        lineHeight: "1.2",
                      }}
                    >
                      Coaching Stats
                    </Typography>
                    <Typography
                        sx={{
                        color: "black",
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginBottom: "8px",
                        lineHeight: "1.2",
                    }}
                    >
                      Check in with your coach
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "50px",
                        paddingY: "20px"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px"
                        }}
                      >
                        <Typography
                          sx={{
                          color: "black",
                          fontFamily: "Poppins",
                          fontSize: "56px",
                          fontWeight: 300,
                          lineHeight: "normal",
                          padding: "0px"
                      }}
                      >
                        {sessionSum ?? 0}
                      </Typography>
                        <Box
                          display="flex"
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 700
                            }}
                          >
                            Total Sessions
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                        <Typography
                          sx={{
                          color: "black",
                          fontFamily: "Poppins",
                          fontSize: "56px",
                          fontWeight: 300,
                          lineHeight: "normal",
                          padding: "0px"
                      }}
                      >
                        {coachingSum ?? 0}
                      </Typography>
                        <Box
                          display="flex"
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 700
                            }}
                          >
                            Total Hours Coached
                          </Typography>
                        </Box>
                      </Box>
    
                    </Box>
                  </Box>
                )}
            </Box>
          </Box>
        </>

    );

}

export default MetricCards;