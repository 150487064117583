/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "data/dashboardAnimation.json";

import { API } from 'aws-amplify';

const RetuneIframe = ({ user }) => {

    // const userId = user?.attributes?.sub;
    const [encryptedToken, setEncryptedToken] = useState('')

    const iframeRef = useRef(null);
    const [loadingIframe, setLoadingIframe] = useState(true);

    useEffect(() => {
        const userID = user.attributes.sub;
        if (userID) {
            async function fetchEncryptedVcAccessToken() {
                try {
                    const response = await API.get("zoomapi", `/zoom/get-encrypted-token-for-vc/${userID}`)
                    setEncryptedToken(response.signedToken)
                } catch (error) {
                    console.log(error);
                }

            }
            fetchEncryptedVcAccessToken()
        }

    }, [user])

    return (
        <>
            {user && (
                <Box height="100%" width="100%">
                    <Box
                        display="flex"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        sx={{
                            marginTop: { xs: "0px", lg: "0px" },
                            "& iframe": {
                                width: { xs: "90vw", lg: "100%" },
                                height: { xs: "calc(100vh - 300px)", lg: "100%" },
                            },
                        }}
                    >
                        {loadingIframe ? (
                            <Box
                                sx={{
                                    padding: "30px",
                                    borderRadius: "40px",
                                    background: "white",
                                    flexDirection: "column",
                                    width: "100%",
                                    marginBottom: "-30px",
                                }}
                            >
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                    width="50%"
                                    height="auto"
                                />
                            </Box>
                        ) : null}

                        {
                            encryptedToken && <iframe
                                title="Retune Chatbot"
                                // src={`https://altercall-v3.retune.so/?token=${encryptedToken}`}
                                src={`${process.env.REACT_APP_VIRTUAL_COACH_BASE_URL}/?token=${encryptedToken}`}
                                onLoad={() => setLoadingIframe(false)}
                                ref={iframeRef}
                                style={{
                                    zIndex: 100,
                                    border: "none",
                                    background: "white",
                                    borderRadius: "20px",
                                    boxShadow: "0 0 10px white",
                                }}
                            />
                        }



                    </Box>
                </Box>
            )}
        </>
    );
};

export default RetuneIframe;
