import { useState, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "graphql/queries"; // Assuming your GraphQL queries are stored here

export const useCheckClientInviteAllowance = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkClientInviteAllowance = useCallback(async (userID) => {
    setLoading(true);

    if (!userID) {
      console.error("UserID is null or undefined.");
      setError(new Error("UserID is required and cannot be null."));
      setLoading(false);
      return;
    }

    // Construct the variables object to be passed into the GraphQL query
    const variables = {
      userID: userID,
    };

    try {
      // Use the `graphqlOperation` helper to construct the GraphQL operation
      const response = await API.graphql(
        graphqlOperation(queries.checkClientInviteAllowance, variables)
      );

      // Assuming your GraphQL response structure, adjust as necessary
      const { clientTierAllowance, clientCount } =
        response.data.checkClientInviteAllowance;

      // Here you can return the response or handle it as needed
      return { clientTierAllowance, clientCount };
    } catch (error) {
      console.error("Error fetching client counter:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    checkClientInviteAllowance,
    loading,
    error,
  };
};
