import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "data/dashboardAnimation.json";
import ProductChoose from "components/ProductChoose";
import FailedPayment from "components/FailedPayment";

// Define the GraphQL query outside of the component
const payments3sByUserID = /* GraphQL */ `
  query Payments3sByUserID($userID: String!) {
    payments3sByUserID(userID: $userID) {
      items {
        stripeCustomerId
        customer
        virtualcoach
        coachingPlatform
        coachingPlatformTier
      }
    }
  }
`;

const AuthenticatingLoader = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [clientHasInactiveCoach, setClientHasInactiveCoach] = useState(false);
  const [isCustomer, setIsCustomer] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!user) return;

    const fetchUserAttributes = async () => {
      const response = await API.get("zoomapi", "/zoom/fetch-user-attributes", {
        queryStringParameters: { user_id: user.attributes.sub }
      });
      return response.data;
    };

    const fetchPayments = async (userSub) => {
      const response = await API.graphql(graphqlOperation(payments3sByUserID, { userID: userSub }));
      return response.data.payments3sByUserID.items;
    };

    const checkClientCoachStatus = async (userSub) => {
      const response = await API.get("zoomapi", "/zoom/fetch-client-coach-status", {
        queryStringParameters: { user_id: userSub }
      });
      return response.some(coach => !coach.is_coach);
    };

    const trackLogin = async (userSub) => {
      await axios.post(
        "https://8m23b8v7tf.execute-api.us-east-1.amazonaws.com/prod/track-login",
        { userID: userSub, loginTime: new Date().toISOString() }
      );
    };

    const checkUserAccess = async () => {
      setIsLoading(true);
      try {
        const userSub = user.attributes.sub;
        await trackLogin(userSub);
        const userAttributes = await fetchUserAttributes();
        setUserData(userAttributes);
        const payments = await fetchPayments(userSub);

        if (userAttributes.is_client && userAttributes.access_to === 'client_portal') {
          if (await checkClientCoachStatus(userSub)) {
            setClientHasInactiveCoach(true);
            setIsLoading(false);
            return;
          }
          navigate("/platform/coachee");
          return;
        }

        if (userAttributes.access_to === 'platform_professional' || userAttributes.access_to === 'platform') {
          if (payments.length > 0 && payments[0].customer) {
            navigate("/platform/coach");
          } else {
            setIsCustomer(false);
            setStripeCustomerId(payments[0]?.stripeCustomerId || null);
            setIsLoading(false);
          }
          return;
        }

        if (payments.length > 0) {
          const payment = payments[0];
          setStripeCustomerId(payment.stripeCustomerId || null);
          if (payment.coachingPlatform && payment.customer) {
            navigate("/platform/coach");
          } else if (payment.virtualcoach && payment.customer) {
            navigate("/virtualcoach/bot");
          } else {
            setIsCustomer(payment.customer);
            setIsLoading(false);
          }
        } else {
          setIsCustomer(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Failed to check user access or track login:", error);
        setIsCustomer(false);
        setIsLoading(false);
      }
    };

    checkUserAccess();
  }, [user, navigate]);

  if (isLoading) {
    return (
      <Box sx={{ width: "100%", height: "100%", background: "#EFF3F6", overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "column", color: "black", textAlign: "center" }}>
          <Lottie options={{ loop: true, autoplay: true, animationData, rendererSettings: { preserveAspectRatio: "xMidYMid slice" }}} width="50%" height="auto" />
        </Box>
      </Box>
    );
  }

  return !isCustomer && stripeCustomerId ? (
    <FailedPayment user={user} stripeCustomerId={stripeCustomerId} userData={userData} />
  ) : (
    <ProductChoose clientHasInactiveCoach={clientHasInactiveCoach} />
  );
};

export default AuthenticatingLoader;
