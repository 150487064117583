import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

export const _CoachLaraFilter = ({
  value,
  handleChange,
  items
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <Box
      sx={{
        padding: "10px",
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
        width: "200px",
        maxWidth: "200px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={(event) => {
          setOpened(!opened);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              color: "black",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "2px",
              lineHeight: "150%",
              textTransform: "uppercase",
            }}
          >
            {value === "none" ? items[0]?.key : value}
          </Typography>
          {value !== "none" && value !== "" && value !== null && (
            <CloseIcon
              sx={{
                color: "black",
                fontSize: "10px",
                cursor: "pointer",
                background: "#AAA",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChange("none");
              }}
            />
          )}
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: "30px",
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 30px)",
            borderTop: "1px solid #E0E3DE",
            margin: "0 15px 16px",
            position: "sticky",
            top: "0",
          }}
        />
        {items && items.map((item, index) =>
          item.value !== "none" && item.value && (
            <Box
              key={index}
              sx={{
                padding: "12px",
                fontFamily: "Lexend",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                background: "white",
                cursor: "pointer",
                "&:hover": {
                  background: "#F5F6F4",
                }
              }}
              onClick={() => {
                setOpened(false);
                handleChange(item.value);
              }}
            >
              {item.key}
            </Box>
          )
        )}
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};

export const _CoachLaraFilterForClient = ({
  value,
  handleChange,
  items,
  type,
}) => {
  const [opened, setOpened] = useState(false);
  const [clientFilterOpen, setClientFilterOpen] = useState(false);

  const handleClientSelection = (val) => {
    if (val === "by client") {
      setClientFilterOpen(!clientFilterOpen);
    } else {
      handleChange(val);
      setOpened(false);
      setClientFilterOpen(false);
    }
  };

  const displayText = () => {
    if (type === "client") {
      return value === "none" ? "All Coachees" : value;
    }
    return value === "none" ? "Coach" : value;
  };

  return (
    <Box
      sx={{
        padding: "10px",
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
        width: "200px",
        maxWidth: "200px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={(event) => {
          setOpened(!opened);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              color: "black",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "2px",
              lineHeight: "150%",
              textTransform: "uppercase",
            }}
          >
            {displayText()}
          </Typography>
          {value !== "none" && value !== "" && value !== null && (
            <CloseIcon
              sx={{
                color: "black",
                fontSize: "10px",
                cursor: "pointer",
                background: "#AAA",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChange("none");
              }}
            />
          )}
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: "30px",
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        {type === "client" ? (
          <>
            <Box
              sx={{
                padding: "12px",
                fontFamily: "Lexend",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                background: "white",
                cursor: "pointer",
                "&:hover": {
                  background: "#F5F6F4",
                },
              }}
              onClick={() => handleClientSelection("none")}
            >
              All Coachees
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "12px",
                alignItems: "center",
                fontFamily: "Lexend",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                background: "white",
                cursor: "pointer",
                gap: "5px",
                "&:hover": {
                  background: "#F5F6F4",
                },
              }}
              onClick={() => handleClientSelection("by client")}
            >
              By Coachee
              {clientFilterOpen ? <KeyboardArrowUp fontSize="12px"/> : <KeyboardArrowDown fontSize="12px"/>}
            </Box>
            {clientFilterOpen && items.map((item, index) =>
              item.value !== "none" && item.value && (
                <Box
                  key={index}
                  sx={{
                    padding: "12px",
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "1.5",
                    letterSpacing: "-0.02",
                    color: "black",
                    background: "white",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#F5F6F4",
                    },
                    "&::before": {
                      content: '"•"',
                      color: "black",
                      display: "inline-block",
                      width: "1em",
                      marginRight: "4px",
                    },
                    paddingLeft: "20px", // Add indentation
                  }}
                  onClick={() => {
                    setOpened(false);
                    handleChange(item.value);
                    setClientFilterOpen(false);
                  }}
                >
                  {item.key}
                </Box>
              )
            )}
          </>
        ) : (
          items.map((item, index) =>
            item.value !== "none" && item.value && (
              <Box
                key={index}
                sx={{
                  padding: "12px",
                  fontFamily: "Lexend",
                  fontSize: "10px",
                  fontWeight: "500",
                  lineHeight: "1.5",
                  letterSpacing: "-0.02",
                  color: "black",
                  background: "white",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#F5F6F4",
                  },
                }}
                onClick={() => {
                  setOpened(false);
                  handleChange(item.value);
                }}
              >
                {item.key}
              </Box>
            )
          )
        )}
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => {
            setOpened(false);
            setClientFilterOpen(false);
          }}
        />
      )}
    </Box>
  );
};
