import { Box, Button, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const DeleteSection = ({ fetchedData, dataFetchLoading }) => {

    const [selectedResource, setSelectedResource] = useState();

    const [pagesForSelectedResource, setPagesForSelectedResource] = useState([])
    const [selectedPage, setSelectedPage] = useState();

    const [sectionsForSelectedPage, setSectionsForSelectedPage] = useState([]);
    const [selectedSection, setSelectedSection] = useState();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        try {
            setSelectedResource(fetchedData[0]?.resource_id)
            setLoading(dataFetchLoading)

        } catch (error) {
            console.log(error);
        }
    }, [fetchedData, dataFetchLoading])


    // Change page for selected Resource
    useEffect(() => {
        const selectedResourceFromFormattedData = fetchedData?.find((data) => data.resource_id === selectedResource)
        const pageNames = selectedResourceFromFormattedData?.pages?.map((data) => {
            return {
                pageName: data.page_name,
                pageId: data.page_id,
                sections: data.sections
            }
        })
        if (pageNames && pageNames.length > 0) {
            setPagesForSelectedResource(pageNames)
            setSelectedPage(pageNames[0].pageId)
        } else {
            setPagesForSelectedResource([])
            setSelectedPage(undefined);
        }


    }, [selectedResource])

    // Change section for selected page
    useEffect(() => {
        if (selectedPage && selectedResource && fetchedData.length > 0) {
            const sections = fetchedData.find((data) => data.resource_id === selectedResource).pages.find((page) => page.page_id === selectedPage).sections;
            setSectionsForSelectedPage(sections);
            setSelectedSection(sections[0].section_id)
        }
    }, [selectedPage])


    function getResourceNames() {
        const resourceNamesArray = [];
        fetchedData.forEach((resource) => {
            resourceNamesArray.push({
                resourceId: resource.resource_id,
                resourceName: resource.resource_name
            })
        })
        return resourceNamesArray;
    }


    async function handleDeleteSection() {

        try {
            if (!window.confirm('Sure to Delete ?')) {
                return;
            }
            setLoading(true)
            const data = {
                body: {
                    section_id: parseInt(selectedSection)
                }
            }
            const dbResponse = await API.del('zoomapi', '/zoom/delete-section', data)
            if (dbResponse) {
                setLoading(false)
                toast.success('Deleted Successfully')

            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error('Error while deleting')
        }
    }



    return (
        <Box color={'black'} pb={'100px'}>

            <Typography fontSize={'25px'} mt={'20px'} mb={'30px'} fontWeight={'500'}>Delete Section</Typography>
            <br />
            <Box display={'flex'} flexDirection={{ xs: "column", lg: 'row' }} gap={'25px'} pb={'20px'}>

                <Box>
                    <Typography fontSize={'10px'}>Please Select Resource Name</Typography>

                    {/* RESOURCE */}
                    <select onChange={(event) => setSelectedResource(parseInt(event.target.value))}>
                        {
                            getResourceNames()?.map((resource) => <option key={resource.resourceId} value={resource.resourceId}>{resource.resourceName}</option>)
                        }
                    </select>

                </Box>

                <Box>
                    <Typography fontSize={'10px'}>Select Page Name</Typography>

                    <select onChange={(event) => setSelectedPage(parseInt(event.target.value))}>
                        {
                            pagesForSelectedResource?.map((page) => <option key={page.pageId} value={page.pageId}>{page.pageName}</option>)
                        }
                    </select>

                </Box>


                <Box>
                    <Typography fontSize={'10px'}> Select Section Name. <small>The one you see in the sidebar</small></Typography>

                    <select onChange={(event) => setSelectedSection(parseInt(event.target.value))}>
                        {
                            sectionsForSelectedPage?.map((section) => <option key={section.section_id} value={section.section_id}>{section.section_name}</option>)
                        }
                    </select>

                </Box>


                <Button
                    sx={{
                        paddingX: "20px",
                        paddingY: "5px",
                        borderRadius: "52px",
                        color: "white",
                        background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                            background: "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
                            cursor: "pointer"
                        },
                        "&:disabled": {
                            background: "#d3d3d3",
                            color: "#B7B7B7",
                            border: "none",
                            cursor: "not-allowed",
                        },
                    }}
                    disabled={loading}
                    onClick={handleDeleteSection}>
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            color: "white",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            letterSpacing: "2px",
                            textTransform: "uppercase",
                        }}
                    >
                        Delete Section
                    </Typography>
                </Button>

            </Box>

        </Box>
    );
};

export default DeleteSection;