import React from 'react';

function VirtualCoach({ color }) {
  return (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.67 80.67">
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path fill={color} d="m40.25,80.67c-8.7,0-17.13-2.81-24.19-8.14C7.45,66.04,1.89,56.6.4,45.93c-1.5-10.67,1.25-21.28,7.74-29.88C14.62,7.45,24.07,1.89,34.73.4c10.67-1.5,21.28,1.25,29.88,7.74,8.6,6.49,14.16,15.93,15.66,26.6,1.5,10.67-1.25,21.28-7.74,29.88s-15.93,14.16-26.6,15.66c-1.9.27-3.8.4-5.68.4Zm.16-77.67c-1.75,0-3.5.12-5.26.37-9.87,1.38-18.62,6.53-24.62,14.49-6,7.96-8.55,17.78-7.16,27.66,1.38,9.87,6.53,18.62,14.49,24.62,7.96,6,17.78,8.54,27.66,7.16,9.87-1.38,18.62-6.53,24.62-14.49,6-7.96,8.55-17.78,7.16-27.66h0c-1.38-9.87-6.53-18.62-14.49-24.62-6.54-4.93-14.34-7.53-22.39-7.53Z" />
          <path fill={color} d="m8.01,21.67c-2.42,4.97,2.32,11.15,5.55,15.08,8.1,9.21,18.79,16.15,29.96,21.15,5.66,2.44,11.6,4.4,17.7,5.04,12.62,1.34,16.66-5.45,9.41-15.92-9.08-12.47-23.22-21.26-37.45-26.82,13.96,4.73,39.14,19.54,42.12,34.98.61,3.42-1.42,6.91-4.66,8.13-8.82,3.2-19.53-.48-27.85-3.68-11.64-4.94-22.59-11.75-31.21-21.15-4.09-4.76-9.42-11.74-6.17-18.31,1.02-1.76,3.61-.25,2.6,1.5h0Z" />
          <path fill={color} d="m34.01,60.1c12.21-5.76,23.71-13.65,32.52-23.88,6.5-7.62,11.58-19.21-3.58-18.32-15.92,1.63-32.46,11.47-44.35,21.91-4.67,4.42-13.98,13.66-10.87,20.55,1.65,2.9,5.47,3.59,8.61,3.7,1.69.07,3.4-.03,5.09-.24-6.49,1.63-16.96,1.01-15.63-8.25,3.86-19.34,43.24-43.69,62.45-40.28,12.95,2.98,6.14,16.06.05,22.45-9.36,10.19-21.39,17.6-34.28,22.36h0Z" />
          <path fill={color} d="m55.87,40.33c-.51-8.33-1.71-16.62-4.5-24.46-2.1-5.93-7.82-17.45-15.15-11.18-13.02,12.9-12.97,55.46-2.52,70.09,1.59,2.2,3.8,4.2,6.64,4.38-6.03-.03-9.44-6.36-11.69-11.16-7.37-16.57-8.12-52.11,5.72-65.47,5.98-5.16,12.39-1.09,15.51,4.79,5.47,10.12,6.44,21.79,5.98,33.01h0Z" />
          <path fill={color} d="m40.22,79.33c3.39-1.63,5.44-4.73,7.8-7.45.82-.96,1.67-1.95,2.76-2.89.05,1.44-.25,2.79-.72,4.07-1.35,3.75-5.55,7.99-9.84,6.27h0Z" />
          <path fill={color} d="m7.6,20.92c-.32,0-.65-.1-.92-.32-.65-.51-.77-1.45-.26-2.11,2.71-3.47,7.97-4.42,15.21-2.73.81.19,1.31,1,1.12,1.8s-.99,1.31-1.8,1.12c-5.93-1.38-10.25-.8-12.16,1.65-.3.38-.74.58-1.18.58Z" />
        </g>
      </g>
    </svg>
  )
}

export default VirtualCoach;