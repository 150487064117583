import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Modal,
  Typography,
} from "@mui/material";
import Team from "scenes/team";
import { API, Hub } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { getCallsByCoachID } from "slices/CoachSlice";
import { setUser } from "slices/UserSlice";
import { getCallsByClientID } from "slices/ClientSlice";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { toDateFormat } from "helpers/DateHelper";
import WelcomeCard from "components/CoachDashboardCards/WelcomeCard";
import ClientCard from "components/CoachDashboardCards/ClientCard";
import VirtualCoachCard from "components/CoachDashboardCards/VirtualCoachCard";
import ZoomCenterModal from "components/ClientDashboardCards/ZoomCenterModal";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import ClientLimitReached from "components/ClientLimitReachedModal";
import ClientGoalCard from "components/ClientDashboardCards/ClientGoalCard";
import ReturnFromZoomModal from "components/CoachDashboardCards/ReturnFromZoomModal";
import MetricCards from "components/ClientDashboardCards/MetricCards";

const CoachDashboard = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [loading, setLoading] = useState(true);
  const [zoomStatus, setZoomStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const coachCallData = useSelector((state) => state.coach.data);
  const [lastFileName, setLastFileName] = useState("");
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [client, setClient] = useState([]);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [unprocessedCallsSum, setUnprocessedCallsSum] = useState(0);
  const [unassignedCallsSum, setUnassignedCallsSum] = useState(0);
  const [assignedCallsSum, setAssignedCallsSum] = useState(0);
  const [totalActionsSum, setTotalActionsSum] = useState(0)
  const clientID = process.env.REACT_APP_CLIENT_ID;
  const redirectURL = "https://apizoom.altercall.ai/zoom";



  const handleOAuthRedirect = async () => {
    try {
      const userId = user?.attributes?.sub;
      const stateParam = encodeURIComponent(userId);
      const zoomOAuthURL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${encodeURIComponent(
        redirectURL
      )}&state=${stateParam}`;
      window.location.href = zoomOAuthURL;
    } catch (error) {
      console.error("Error fetching user ID or redirecting:", error);
    }
  };

  const fetchSum = async () => {
    try {
      let response;
      response = await API.get("zoomapi", "/zoom/fetch-coach-sum", {
        queryStringParameters: {
          coach_id: user?.attributes?.sub,
        },
      });
      if (!response.data) {
        return;
      }

      setUnprocessedCallsSum(response.data.unprocessed_sum);
      setUnassignedCallsSum(response.data.unassigned_sum);;
      setAssignedCallsSum(response.data.assigned_sum);
      setTotalActionsSum(response.data.actions_sum)
    } catch (error) {
      console.error("Error fetching sum data:", error);
    }
  };

  useEffect(() => {
    fetchSum();
  }, [user, loading]);



  const fetchUserData = async () => {
    try {
      const response =
        (user?.attributes?.["custom:is_client"] === "true")
          ? await dispatch(getCallsByClientID({ clientId: user?.attributes?.sub })).unwrap()
          : await dispatch(
              getCallsByCoachID({ coachId: user?.attributes?.sub })
            ).unwrap();
      const zoomCheckResponse = await API.get("zoomapi", "/zoom/check-zoom", {
        queryStringParameters: {
          coach_id: user?.attributes?.sub,
        },
      });
      if (zoomCheckResponse) {
        let zoomStatus =
          zoomCheckResponse.data === undefined ||
          zoomCheckResponse?.data[0].zoom_access_token === null;
        setZoomStatus(!zoomStatus);
      }
      if (response) {
        const sanitizedData = response?.map((row) => ({
          ...row,
          call_name: row.call_name.replace(/_/g, " "), // Replace underscores with spaces
          zoom_date: row.zoom_date
            ? new Date(row.zoom_date).toLocaleDateString("en", {
                weekday: "short",
                month: "short",
                day: "numeric",
                year: "numeric",
              })
            : "",
          file_name: row.file_name, // Include the "file_name" from the response
          id: row.id,
        }));
        const sortedData = sanitizedData?.sort(
          (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
        );
        for (const row of sortedData) {
          if (row.status === "ReadyToPublish" || "assigned") {
            setLastFileName(row.file_name);
            break;
          }
        }

        if (!response) {
          setLoading(false);
          return;
        }

      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getRole = (userData) => {
    if (userData.accessTo === "platform_professional") {
      return userData.isClient ? "Professional Coachee" : "Professional Tier";
    } else if (userData.accessTo === "platform") {
      switch (userData.coachingTier) {
        case 'tier1':
          return "Coach Level 1";
        case 'tier2':
          return "Coach Level 2";
        case 'tier3':
          return "Coach Level 3";
        default:
          return null;
      }
    }
    return null;
  };



  const fetchClientData = async () => {
    if(userData.accessTo === "client_portal")
      return
    try {
      const userId = user?.attributes?.sub;
      let response;
      response = await API.get("zoomapi", "/zoom/fetch-clients", {
        queryStringParameters: {
          coach_id: userId,
        },
      });

      if (!response.client_data && !response.call_data) {
        return;
      }

      response.client_data.map(async (item, index) => {
        item.id = index + 1;
        item.isClient = true;
        item.zoom_date = toDateFormat(item.zoom_date);
        item.avatar = item.avatar ? `https://d7joen7tb0xwx.cloudfront.net/public/${item.avatar}` : null;
        return item;
      });

      const sortedClientData = response.client_data.sort(
        (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
      );

      setClient(sortedClientData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {

    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn") {
        setUser(data.signInUserSession.idToken.payload);
      }
    });
  }, []);

  useEffect(() => {
    fetchClientData();
    fetchUserData();
  }, [])


  const modal = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: {xs: "50%", lg: "60%", xl:"58%"},
          transform: "translate(-50%, -50%)",
          width: 500,
          background: "white",
          border: "2px solid white",
          boxShadow: 24,
          paddingX: "50px",
          paddingY: "30px",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "20px",
          margin: "0 auto",
          maxWidth: "calc(100% - 40px)",
        }}
      >
        <Box>
          <Typography color="black" fontWeight="400">
            {`You will be redirected to Zoom and asked to allow our app connection.`}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" 
            sx={{
              gap: {xs: "8px", md: "15px"},
              flexDirection: {xs: "column-reverse", md: "row"}
            }}
          >
          <DeclineButton 
            onClick={handleClose}
            label={"Cancel"}
          />
          <ConfirmButton 
            onClick={handleOAuthRedirect}
            label={"Connect"}
          />
          
        </Box>
      </Box>
    </Modal>
  );

if(!zoomStatus && userData.isClient !== true){
  return(
    <>
      <ClientLimitReached user={user} userData={userData}/>
      <ZoomCenterModal
        user={user}
        loading={loading}
        modal={modal}
        setOpen={setOpen}
        collapsed={collapsed}
        getRole={getRole}
        userData={userData}
      />
    </>
  )
}
else{
  return (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <ClientLimitReached user={user} userData={userData}/>
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          padding: {
            xs: "16px",
            md: "32px 40px",
          },
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={"12px"}
          sx={{
            marginTop: {
              xs: "28px",
              md: "0px",
            },
          }}
        >
          <Typography
            sx={{
              color: {
                xs: "#070728",
                md: "black",
              },
              fontSize: {
                xs: "28px",
                md: "36px",
              },
              fontFamily: "Poppins",
              fontWeight: 300,
              paddingBottom: "16px",
            }}
          >
            Dashboard
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              alignItems: "center",
              background: "-webkit-linear-gradient(315deg, #D8659B 0%, #03093A 60%, #242F8C 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {getRole(userData)}
          </Typography>
        </Box>
          <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: {xs: "100%", md: "calc(100% / 3 * 2)"},
                  gap: "8px",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: {xs: "column", md: "row"},
                    height: "100%"
                  }}
                >
                  {userData.accessTo === "platform_professional" && user?.attributes?.["custom:is_client"] === "true" ? (
                    <>
                    <MetricCards 
                      user={user}
                      accessTo={userData.accessTo}
                      zoomStatus={zoomStatus}
                      modal={modal}
                      handleOpen={handleOpen}
                    />
                    </>
                  ) : (
                    <>
                    <WelcomeCard
                    loading={loading}
                    zoomStatus={zoomStatus}
                    user={user}
                    coachCallData={coachCallData}
                    lastFileName={lastFileName}
                    handleOpen={handleOpen}
                    modal={modal}
                    unprocessedCallsSum={unprocessedCallsSum}
                    unassignedCallsSum={unassignedCallsSum}
                    totalActionsSum={totalActionsSum}
                    accessTo={userData.accessTo}
                  />
                    <ClientCard
                      loading={loading}
                      zoomStatus={zoomStatus}
                      handleOpen={handleOpen}
                      modal={modal}
                      client={client}
                      assignedCallsSum={assignedCallsSum}
                      totalActionsSum={totalActionsSum}
                    />
                    </>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: {xs: "100%", md: "calc(100% / 3)"},
                  flexGrow: 1,
                }}
              >
                <VirtualCoachCard user={user} />
              </Box>
            </Box>
        <Team
          user={user}
          fetchData={fetchUserData}
          isDashboard={true}
          isProfessionalTier={userData.accessTo === 'platform_professional'}
          fetchSum={fetchSum}
          accessTo={userData.accessTo}
        />
      </Box>
      <ReturnFromZoomModal open={openSuccessMessage} setOpen={setOpenSuccessMessage} />
    </GlobalPageComponent>
  );
  }
};

export default CoachDashboard;
