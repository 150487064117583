import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { API, } from 'aws-amplify';
import { toast } from "react-toastify";




const AddNewResource = () => {

    const [textEditorValue, setTextEditorValue] = useState('');
    const [fetchedData, setFetchedData] = useState([]);
    const [selectedResource, setSelectedResource] = useState(1);
    const [selectedPage, setSelectedPage] = useState()
    const [sectionName, setSectionName] = useState('')
    const [pagesForSelectedResource, setPagesForSelectedResource] = useState([])


    // Change page for selected Resource
    useEffect(() => {
        const pageNamesArray = []
        fetchedData.forEach((data) => {
            if (data.resource_id === selectedResource) {
                pageNamesArray.push({
                    pageName: data.page_name,
                    pageId: data.page_id
                })
            }
        });
        setSelectedPage(pageNamesArray[0]?.pageId)
        setPagesForSelectedResource(pageNamesArray)
    }, [selectedResource, fetchedData])


    // Fetch Data from DB
    useEffect(() => {
        try {
            async function fetchResourceAndPagNames() {
                const data = await API.get("zoomapi", "/zoom/resources-and-pages")
                setFetchedData(data);
            }
            fetchResourceAndPagNames()
        } catch (error) {
            console.log(error);
        }

    }, [])



    function getResourceNames() {
        let resourceNamesArray = []
        fetchedData.forEach((data) => {
            if (!resourceNamesArray.find((resource) => resource.resourceId === data.resource_id)) {
                resourceNamesArray.push({
                    resourceId: data.resource_id,
                    resourceName: data.resource_name
                })
            }
        })
        return resourceNamesArray
    }


    async function handleSaveResourceInDB() {
        try {

            if (!(selectedPage && sectionName && textEditorValue)) {
                toast.error('Please fillup all the fields')
                return
            }
            const dataToSave = {
                page_id: selectedPage,
                name: sectionName,
                contents: textEditorValue
            }

            const dbResponse = await API.post("zoomapi", "/zoom/save-new-resource-section", {
                body: dataToSave
            });

            if (dbResponse) {
                toast.success('Saved In Database Successfully')
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleSelectedPageChange(pageId) {
        setSelectedPage(pageId);
    }



    const modules = {
        toolbar: [

            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            // [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    return (
        <Box color={'black'} width={'100%'}  >

            <Box mt={'30px'}  >

                <Typography fontSize={'25px'} mt={'20px'} mb={'30px'} fontWeight={'500'}>Add New Section</Typography>

                <br />

                <Box display={'flex'} flexDirection={{ xs: "column", lg: 'row' }} gap={'25px'} pb={'20px'}>


                    <Box>
                        <Typography fontSize={'10px'}>Please Select Resource Name</Typography>
                        <select value={selectedResource} onChange={(event) => setSelectedResource(parseInt(event.target.value))}>
                            {
                                getResourceNames()?.map((resource) => <option key={resource.resourceId} value={resource.resourceId}>{resource.resourceName}</option>)
                            }
                        </select>

                    </Box>


                    <Box>
                        <Typography fontSize={'10px'}>Please Select Page Name</Typography>
                        <select value={selectedPage} onChange={(event) => handleSelectedPageChange(parseInt(event.target.value))}>
                            {
                                pagesForSelectedResource?.map((page) => <option key={page.pageId} value={page.pageId}>{page.pageName}</option>)
                            }
                        </select>

                    </Box>

                    <Box>
                        <Typography fontSize={'10px'}>Write the Section name here</Typography>
                        <textarea cols={30} onChange={(event) => setSectionName((event.target.value).trim())} />
                    </Box>


                </Box>


                <Box sx={{ width: "60%" }}>
                    <ReactQuill style={{ marginTop: "20px", height: "500px", }} modules={modules} theme="snow" value={textEditorValue}
                        onChange={setTextEditorValue} />

                    <Box display={'flex'} justifyContent={'end'} mt={'70px'} >
                        <br /> <br /> <br />
                        <Button
                            sx={{
                                paddingX: "20px",
                                paddingY: "5px",
                                borderRadius: "52px",
                                color: "white",
                                background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                "&:hover": {
                                    background: "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
                                    cursor: "pointer"
                                },
                                "&:disabled": {
                                    background: "#d3d3d3",
                                    color: "#B7B7B7",
                                    border: "none",
                                    cursor: "not-allowed",
                                },
                            }}
                            disabled={!(selectedPage && sectionName && textEditorValue)}
                            onClick={handleSaveResourceInDB}>
                            <Typography
                                sx={{
                                    fontFamily: "Lexend",
                                    color: "white",
                                    fontSize: "10px",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    letterSpacing: "2px",
                                    textTransform: "uppercase",
                                }}
                            >
                                Save In Database
                            </Typography>
                        </Button>
                    </Box>


                </Box>

            </Box>


        </Box>
    );
};

export default AddNewResource;