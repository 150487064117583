import React, { useState } from "react";

const ClientFAQ = () => {
  const { Remove, Add } = require("@mui/icons-material");
  const {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
  } = require("@mui/material");
  const [expanded, setExpanded] = useState({});
  const handleFaqChange = (panel) => (event, isExpanded) => {
    setExpanded({
      [panel]: isExpanded,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "56px",
      }}
    >
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: "300",
          color: "black",
          marginBottom: "-50px",
          marginTop: "-30px",
        }}
      >
        F.A.Q.
      </Typography>
      <Box>
        <Accordion
          expanded={expanded["panel1"] === true}
          onChange={handleFaqChange("panel1")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel1"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are Actions?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Actions are tasks that you and your Coach have agreed to complete as part of advancing in your coaching program. These tasks are instrumental in progressing to the next stages of your coaching journey. You can track your progress by visiting the Actions page. Incomplete and completed actions are categorized under the TO-DO and COMPLETED tabs, respectively. To mark an Action as complete, simply select the desired action and click on the Mark as Completed button.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel2"] === true}
          onChange={handleFaqChange("panel2")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel2"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I review a call?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            To review a call, go to the Dashboard page using the sidebar, navigate to the Sessions List and select the call you wish to review. Please note that you will be able to review the call only after your Coach has shared it with you.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel3"] === true}
          onChange={handleFaqChange("panel3")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel3"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are Session Summaries?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Session Summaries provide a concise overview of the key points and insights derived from each coaching session. These summaries help view insights, reflect on the session's content, and track your progress over time.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel4"] === true}
          onChange={handleFaqChange("panel4")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel4"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the LARA framework?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The LARA framework stands for Listen, Affirm, Respond, and Ask Questions. It is designed to facilitate empathetic engagement and conflict resolution by inviting diverse perspectives to create shared meaning.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel5"] === true}
          onChange={handleFaqChange("panel5")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel5"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does AlterCall AI use the LARA framework?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            AlterCall AI (AAI) leverages AI technology to assess verbal features based on the LARA framework, ensuring precise evaluation and feedback to enhance each coaching session.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel6"] === true}
          onChange={handleFaqChange("panel6")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel6"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are the components of the LARA score and how is it calculated?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The LARA score includes four sub-scores:
            <ul>
              <li>Listen: Evaluates the ability to listen without interrupting and show understanding.</li>
              <li>Affirm: Measures how well the user acknowledges and validates the coach’s input.</li>
              <li>Respond: Assesses the ability to provide thoughtful and respectful reactions.</li>
              <li>Ask Questions: Gauges the quality and relevance of the user’s questions.</li>
            </ul>
            These sub-scores are averaged to produce an overall LARA score, providing a comprehensive overview of the session’s effectiveness.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel7"] === true}
          onChange={handleFaqChange("panel7")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel7"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does AAI generate and display the LARA score?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            AAI uses Large Language Models (LLMs) to analyze transcripts and audio of coaching sessions, generating detailed scores and feedback based on verbal and non-verbal cues. Both the Client and Coach scores are displayed in a user-friendly interface with visual aids like graphs.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel8"] === true}
          onChange={handleFaqChange("panel8")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel8"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can users improve their LARA scores?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            AAI offers specific, actionable suggestions using cited instances from coaching calls to enhance communication skills, such as:
            <ul>
              <li>Avoiding interruptions and summarizing the coach's points.</li>
              <li>Reflecting deeper on how feedback applies to personal situations.</li>
              <li>Providing more thoughtful responses.</li>
              <li>Asking insightful, open-ended questions.</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel9"] === true}
          onChange={handleFaqChange("panel9")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel9"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does the LARA framework benefit coaches?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Coaches can continuously refine their communication skills using the detailed feedback provided by the LARA framework, helping them become more empathetic and effective.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel10"] === true}
          onChange={handleFaqChange("panel10")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel10"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How does the LARA framework benefit clients?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Clients benefit from structured and supportive coaching sessions that provide clear, actionable advice. It helps them improve their communication skills and achieve their goals through empathetic engagement and constructive feedback.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel11"] === true}
          onChange={handleFaqChange("panel11")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel11"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What is the role of verbal features assessment in the LARA framework?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Using LLMs, AAI measures verbal signs of active listening and other communication skills based on the LARA framework, ensuring that each coaching session is aligned with best practices.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel12"] === true}
          onChange={handleFaqChange("panel12")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel12"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I get the most value out of Virtual Coach?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            The Virtual Coach Guide is a comprehensive manual designed to help you maximize the benefits of Virtual Coach. It includes detailed instructions and a variety of base prompts that you can customize to meet your specific needs. We recommend downloading the Virtual Coach user guide. To access the guide, please use the menu sidebar located on the left, navigate to the Virtual Coach section and click on DOWNLOAD USER GUIDE located at the top right corner.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel13"] === true}
          onChange={handleFaqChange("panel13")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel13"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            What are Virtual Coach threads?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Virtual Coach Threads represent conversations. This is helpful when keeping interactions with Virtual Coach organized. We recommend starting a new thread when changing conversation topics and revisiting previous threads to continue conversations related to a thread's topic.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel14"] === true}
          onChange={handleFaqChange("panel14")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel14"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How do I start a new thread?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            To initiate a new topic, click the “New Thread +” button at the top right corner of the Virtual Coach interface. This action resets your session, prompting Virtual Coach to delve into introspective and probing questions aimed at uncovering the core of your inquiry.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel15"] === true}
          onChange={handleFaqChange("panel15")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel15"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            Can I collapse the threads list?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            Yes, you can collapse your threads list for a more focused conversation view by clicking on the arrow at the top right of the threads list. To reopen the list, click on the hamburger icon, which is represented by three horizontal lines.
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded["panel16"] === true}
          onChange={handleFaqChange("panel16")}
          sx={{
            background: "transparent",
            color: "black",
            boxShadow: "none",
            marginBottom: "30px",
            "& .MuiAccordionSummary-root": {
              padding: 0,
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded["panel16"] === true ? (
                <Remove sx={{ color: "black" }} />
              ) : (
                <Add sx={{ color: "black" }} />
              )
            }
            sx={{
              fontSize: "18px",
              fontWeight: "medium",
              lineHeight: "1.2",
              borderBottom: "1px solid #E8E8E8",
              fontFamily: "Lexend",
            }}
          >
            How can I access the settings or sign out?
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontFamily: "Lexend",
              fontSize: "14px",
            }}
          >
            To access the settings, click on your profile photo located at the top right corner of your screen. A dropdown menu will appear, offering you options to modify your settings or sign out.
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default ClientFAQ;
