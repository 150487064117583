import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { Header } from "components/GlobalHeader/GlobalHeader";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import logoBgImage from "../../assets/logo-bg-banner.svg";
import { useNavigate } from "react-router-dom";
import Footer from "components/Footer/Footer";
import { API } from "aws-amplify";
import { format } from "date-fns";

const Blogs = ({ user, allowAccessToBot, allowAccessToPlatform }) => {
  const [blogs, setBlogs] = useState([]);

  const [blogsInView, setBlogsInView] = useState(blogs);

  const navigate = useNavigate();

  const [tags, setTags] = useState([]);

  const [selectedTag, setSelectedTag] = useState("");

  const [searchText, setSearchText] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await API.get("zoomapi", "/zoom/blog");
        setBlogs(response);
        setBlogsInView(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  function formatDate(dateInTzFormat) {
    const date = new Date(dateInTzFormat);
    const formattedDate = format(date, "MMMM dd, yyyy");
    return formattedDate;
  }

  // useEffect(() => {
  //     let allTags = [];
  //     blogs.forEach((blog) => {
  //         blog.tags.forEach((tag) => {
  //             if (!allTags.includes(tag)) {
  //                 allTags.push(tag)
  //             }
  //         })
  //     })
  //     setTags(allTags)
  // }, [])

  // function handleTagClick(tagName) {
  //     if (tagName === selectedTag) {
  //         setSelectedTag("")
  //         setBlogsInView(blogs)
  //     } else {
  //         setSelectedTag(tagName)
  //         const blogWithSelectedTag = blogs.filter((blog) => blog.tags.includes(tagName))
  //         setBlogsInView(blogWithSelectedTag)
  //     }
  // }

  function handleSearch(searchInputText) {
    if (searchInputText.trim().length < 1) {
      setSearchText("");
      return;
    }
    setSelectedTag("");
    setSearchText(searchInputText);
    searchHandler(searchInputText);
  }

  function searchHandler(searchInputText) {
    const blogsWithSearchedTextInTitle = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchInputText.trim().toLowerCase())
    );
    setBlogsInView(blogsWithSearchedTextInTitle);
  }

  return (
    <Box sx={{ color: "blue", width: "100%" }}>
      <Header
        user={user}
        allowAccessToBot={allowAccessToBot}
        allowAccessToPlatform={allowAccessToPlatform}
        hasRightSidebar={false}
        hideGlobalSearchbar={true}
      />

      <Box
        sx={{
          width: "100%",
          color: "black",
          paddingX: { xs: "20px" },
          backgroundColor: "#EFF3F6",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${logoBgImage})`,
            backgroundSize: "auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            py: "150px",
          }}
        >
          {/* AlterCall Ai */}

          <Box
            component="div"
            display="flex"
            justifyContent="center"
            gap="15px"
          >
            <Typography
              fontSize={{ xs: "28px", md: "66px" }}
              fontWeight="300"
              color="#0076BA"
            >
              {" "}
              AlterCall Ai
            </Typography>
            <Typography fontSize={{ xs: "28px", md: "66px" }} fontWeight="300">
              {" "}
              Blog
            </Typography>
          </Box>

          <Typography
            mx="auto"
            fontSize={{ xs: "14px", md: "22px" }}
            mt={"10px"}
            fontFamily={"Lexend"}
            fontWeight="400"
            width={{ xs: "80%", md: "45%" }}
            textAlign={"center"}
          >
            AlterCall AI redefines leadership by merging AI technology with
            personalized coaching to accelerate your transformation into a
            purpose-driven leader. 
          </Typography>

          {/* Search Field */}

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              width: { xs: "100%", md: "45%" },
              mx: "auto",
              mt: { xs: "10px", md: "65px" },
            }}
          >
            <SearchIcon sx={{ color: "orange", mr: "5px" }} fontSize="medium" />
            <TextField
              value={searchText}
              onChange={(event) => handleSearch(event.target.value)}
              label="Search for content"
              variant="standard"
              style={{ width: "100%" }}
            />
          </Box>
          {/* Tags */}
          {/* <Box mx='auto' width="45%" mt="25px" py="10px" display={{ xs: "none", md: "flex" }} gap="1.1vw" flexWrap="wrap" >
                        {
                            tags.map((tag, index) => (
                                <Box key={index} onClick={() => handleTagClick(tag)}
                                    sx={{
                                        cursor: "pointer",
                                        boxShadow: selectedTag === tag ? "5px 5px black" : "0",
                                        border: selectedTag === tag ? "1px solid black" : "1px solid #0076BA",
                                    }}
                                    borderRadius="8px"
                                    height="72px"
                                    width="12vw"
                                    minWidth="180px"
                                    display="flex"
                                    gap="15px"
                                    alignItems="center"
                                    pl="15px">
                                    <Box component="img" width="21px" height="21px" borderRadius={"50%"} src={blogSearchTag}></Box>
                                    <Typography fontSize="20px">{tag}</Typography>
                                </Box>

                            ))
                        }

                    </Box> */}
        </Box>

        <Grid
          container
          rowSpacing={3}
          width={{ xs: "100%", md: "100%", lg: "60%" }}
          mx="auto"
        >
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: "60%",
                left: "50%",
                width: "100vh",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            blogsInView?.map((blog) => (
              <Grid key={blog.id} item md={6} xs={12} height={"722px"}>
                <Box
                  onClick={() =>
                    navigate(`/blog?title=${blog.title}&id=${blog.id}`)
                  }
                  sx={{
                    cursor: "pointer",
                    background:
                      "linear-gradient(to bottom, #DDE5ED 25%, #DDE5ED 100%)",
                  }}
                  position="relative"
                  width={{ xs: "100%", md: "95%" }}
                  height="100%"
                  mx="auto"
                  borderRadius="14px"
                  px="20px"
                  border={"3 px solid #F2EFE6"}
                >
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    paddingTop="20px"
                  >
                    <Box
                      src={
                        process.env.REACT_APP_BlogImgBaseUrl + blog.thumbnailurl
                      }
                      component="img"
                      width="100%"
                      height="325px"
                      borderRadius="18px"
                    />
                  </Box>
                  {blog.tag && (
                    <Box display="flex" columnGap="10px" mt="20px">
                      {blog.tags.map((tag, index) => (
                        <Typography
                          key={index}
                          borderRadius="10px"
                          fontWeight={"700"}
                          bgcolor={"black"}
                          fontSize={"14px"}
                          color={"white"}
                          px={"16px"}
                          py={"7px"}
                          fontFamily={"Lexend"}
                        >
                          {tag.toUpperCase()}
                        </Typography>
                      ))}
                    </Box>
                  )}
                  <Typography
                    width={"90%"}
                    fontSize={{ xs: "20px", md: "34px" }}
                    fontWeight={"300"}
                    fontFamily={"Poppins"}
                    lineHeight={{ xs: "24px", md: "40px" }}
                    letterSpacing={{ md: "-1.65px" }}
                    my={"30px"}
                  >
                    {blog.title}{" "}
                  </Typography>

                  <Typography
                    position={"absolute"}
                    bottom={20}
                    left={30}
                    color={"#6F6F6F"}
                    fontFamily={"Lexend"}
                    fontSize={"19px"}
                    fontWeight={"300"}
                    lineHeight={"30px"}
                  >
                    {blog.author}
                  </Typography>

                  <Typography
                    position={"absolute"}
                    bottom={20}
                    right={30}
                    color={"#6F6F6F"}
                    fontFamily={"Lexend"}
                    fontSize={"19px"}
                    fontWeight={"300"}
                    lineHeight={"30px"}
                  >
                    {formatDate(blog.date)}
                  </Typography>
                </Box>
              </Grid>
            ))
          )}
        </Grid>

        {/* Stay Updated With Us and Signup field Box */}

        <Box display={{ xs: "none", md: "block" }} pb="50px" mt={"170px"}>
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            gap="15px"
          >
            <Typography fontSize={{ xs: "28px", md: "66px" }} fontWeight="300">
              {" "}
              Stay Updated
            </Typography>
            <Typography
              fontSize={{ xs: "28px", md: "66px" }}
              fontWeight="300"
              color="#0076BA"
            >
              {" "}
              With Us
            </Typography>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"baseline"}
            mt={"60px"}
          >
            <TextField
              label="you@email.com "
              variant="standard"
              style={{ width: "20%" }}
            />
            <Button
              onClick={() => navigate("/signup")}
              sx={{
                height: { xs: "30px", md: "40px" },
                fontSize: "16px",
                marginLeft: { xs: "10px", md: "10px" },
                borderRadius: "43px",
                backdropFilter: "blur(25px)",
                color: "white",
                textTransform: "none",
                padding: "0px",
                background:
                  "linear-gradient(330deg, #0076BA 21%, #03093A 67%, #000000 100%)",
                display: "block",
                width: "120px",
                transition: "0.5s all ease-in-out",
                "&:hover": {
                  filter: "brightness(2)",
                  transition: "0.5s all ease-in-out",
                },
                fontFamily: "Lexend",
              }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>

        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default Blogs;
