import React, { useEffect, useState, useCallback, memo } from 'react';
import { Box, Button, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      style={{
        position: "relative",
      }}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: "20px", width: "100%" }}>{children}</Box>}
    </div>
  );
};

const Circle = memo(({ id, color, score, radius, fill, strokeDashoffset }) => {
  const r = radius;
  const circ = 2 * Math.PI * r;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill={fill ?? "transparent"}
      stroke={color ? color : `url(#${id})`}
      strokeWidth={"0.8rem"}
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{ transition: "stroke-dashoffset 1s ease-out" }}
    ></circle>
  );
});

const Text = ({ score, textSize }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={textSize}
      fontFamily="Poppins"
      fontWeight={300}
    >
      {isNaN(score) ? "-" : (score === 0 ? "0" : Math.round(score))}
    </text>
  );
};

const Pie = ({ score, radius, color, textSize, fillStopColor }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(2 * Math.PI * radius);
  const gradientId = `gradient-${Math.random()}`;
  const fillGradientId = `fill-gradient-${Math.random()}`;

  useEffect(() => {
    const circ = 2 * Math.PI * radius;
    const strokescore = ((100 - score) * circ) / 100;
    setStrokeDashoffset(strokescore);
  }, [score, radius]);

  return (
    <svg width={200} height={200}>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#e383eb" />
          <stop offset="40%" stopColor="#8e33b8" />
          <stop offset="60%" stopColor="#568FF3" />
          <stop offset="100%" stopColor="#62C1EB" />
        </linearGradient>
        <linearGradient id={fillGradientId}>
          <stop offset="0%" stopColor={fillStopColor ?? "#62C1EB"} stopOpacity=".2" />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g transform={`rotate(90 ${"100 100"})`}>
        <Circle color="lightgrey" radius={radius} score={100} fill={`url(#${fillGradientId})`} strokeDashoffset={0} />
        <Circle id={gradientId} radius={radius} score={score} color={color} strokeDashoffset={strokeDashoffset} />
      </g>
      {isNaN(score) ? <Text score="-" textSize={textSize} /> : <Text score={score} textSize={textSize} />}
    </svg>
  );
};

const LaraSessionPage = ({ downloadTranscript, laraData }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [fullReportOpen, setFullReportOpen] = useState(true);
  const [showAreasOfImprovement, setShowAreasOfImprovement] = useState(false);
  const [open, setOpen] = useState(false);


  const calculateOverallScore = useCallback((listenScore, affirmScore, respondScore, askScore) => {
    return !isNaN(listenScore) && !isNaN(affirmScore) && !isNaN(respondScore) && !isNaN(askScore)
      ? ((parseFloat(listenScore) + parseFloat(affirmScore) + parseFloat(respondScore) + parseFloat(askScore)) / 4) * 20
      : 0;
  }, []);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    setFullReportOpen(true);
    setShowAreasOfImprovement(false);
  };

  const handleChange = (event) => {
    setShowAreasOfImprovement(event.target.value === 'areasOfImprovement');
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  return (
    <Box sx={{ width: '100%', marginTop: {xs: "0px", sm: "50px", md: "0px"} }}>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            gap: "20px",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            height: "70px"
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="client-coach-tabs"
            TabIndicatorProps={{ style: { backgroundColor: "black" } }}
            sx={{
              overflow: "visible",
              "& .MuiTab-textColorPrimary": {
                color: "black",
                fontFamily: "Lexend",
              },
              "& .MuiTab-root.MuiButtonBase-root": {
                fontSize: "12px",
                fontWeight: 700,
                paddingBottom: "4px"
              },
              "& .MuiTabs-scroller": {
                overflowX: {
                  xs: "auto !important",
                  lg: "visible !important",
                },
                overflowY: {
                  xs: "visible !important",
                },
              },
              padding: "0px"
            }}
          >
            <Tab label="Coachee" {...a11yProps(0)} />
            <Tab label="Coach" {...a11yProps(1)} />
          </Tabs>
          <Select
            value={showAreasOfImprovement ? 'areasOfImprovement' : 'sessionOverview'}
            onChange={handleChange}
            displayEmpty
            inputProps={{
              MenuProps: {
                  MenuListProps: {
                      sx: {
                          backgroundColor: 'white'
                      }
                  }
              }
            }}
            renderValue={(selected) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'black',
                      fontSize: '10px',
                      fontFamily: 'Lexend',
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      letterSpacing: 2,
                    }}
                  >
                    {selected === 'areasOfImprovement' ? 'Areas of Improvement' : 'Session Overview'}
                  </Typography>
                  {showAreasOfImprovement ? <KeyboardArrowUp sx={{ ml: 1 }} /> : <KeyboardArrowDown sx={{ ml: 1 }} />}
                </Box>
              );
            }}
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: "center",
              textAlign: 'center',
              color: 'black',
              border: "1px solid black",
              maxHeight: "50%",
              borderRadius: "10px",
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused': {
                outline: 'none',
              },
              
            }}
          >
          <MenuItem value="sessionOverview">
            <Typography
              sx={{
                color: 'black',
                fontSize: '10px',
                fontFamily: 'Lexend',
                fontWeight: 700,
                textTransform: 'uppercase',
                textAlign: 'center',
                letterSpacing: 2,
              }}
            >
              Session Overview
            </Typography>
          </MenuItem>
          <MenuItem value="areasOfImprovement">
            <Typography
              sx={{
                color: 'black',
                fontSize: '10px',
                fontFamily: 'Lexend',
                fontWeight: 700,
                textTransform: 'uppercase',
                textAlign: 'center',
                letterSpacing: 2,
              }}
            >
              Areas of Improvement
            </Typography>
          </MenuItem>
        </Select>
        </Box>
        <Button
          sx={{
            fontSize: "14px",
            color: "#B3B3B3",
            textTransform: "none",
            padding: 0,
            minWidth: "unset",
            display: {xs: "none", md: "flex"}
          }}
          onClick={() => downloadTranscript()}
        >
          Download transcript
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start"
        }}
      >
        {/* ------------ client tab ------------ */}
        <CustomTabPanel value={tabIndex} index={0}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              gap: "10px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingRight: "20px",
                minWidth: "230px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Pie score={laraData?.client ? calculateOverallScore(laraData?.client?.listen_score, laraData?.client?.affirm_score, laraData?.client?.respond_score, laraData?.client?.ask_score) : 0} radius={90} textSize={"60px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: {xs: "left", md: "center"}
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "24px",
                    fontFamily: "Lexend",
                    fontWeight: 600,
                  }}
                >
                  Overall Score
                </Typography>
              </Box>
            </Box>
            {fullReportOpen && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-between"
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.client?.listen_score)) ? "-" : parseFloat(laraData?.client?.listen_score, 10) * 20} radius={70} color={"#62C1EB"} textSize={"40px"} fillStopColor={"#62C1EB"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Listen
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData.client ? (
                          !showAreasOfImprovement ? (
                            laraData?.client?.listen_report
                            ) : (
                            laraData?.client?.listen_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.client?.affirm_score)) ? "-" : parseFloat(laraData?.client?.affirm_score, 10) * 20} radius={70} color={"#568FF3"} textSize={"40px"} fillStopColor={"#568FF3"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Affirm
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.client ? (
                          !showAreasOfImprovement ? (
                            laraData?.client?.affirm_report
                            ) : (
                            laraData?.client?.affirm_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.client?.respond_score)) ? "-" : parseFloat(laraData?.client?.respond_score, 10) * 20} radius={70} color={"#e383eb"} textSize={"40px"} fillStopColor={"#e383eb"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Respond
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.client ? (
                          !showAreasOfImprovement ? (
                            laraData?.client?.respond_report
                            ) : (
                            laraData.client?.respond_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.client?.ask_score)) ? "-" : parseFloat(laraData?.client?.ask_score, 10) * 20} radius={70} color={"#8e33b8"} textSize={"40px"} fillStopColor={"#8e33b8"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Ask
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.client ? (
                          !showAreasOfImprovement ? (
                            laraData?.client?.ask_report
                            ) : (
                            laraData?.client?.ask_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </CustomTabPanel>

        {/* ------------ coach tab ------------ */}
        <CustomTabPanel value={tabIndex} index={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              gap: "10px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingRight: "20px",
                minWidth: "230px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Pie score={calculateOverallScore(laraData?.coach?.listen_score, laraData?.coach?.affirm_score, laraData?.coach?.respond_score, laraData?.coach?.ask_score)} radius={90} textSize={"60px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  minWidth: "200px",
                  textAlign: {xs: "left", md: "center"}
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "24px",
                    fontFamily: "Lexend",
                    fontWeight: 600,
                  }}
                >
                  Overall Score
                </Typography>
              </Box>
            </Box>
            {fullReportOpen && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-between"
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.coach?.listen_score)) ? "-" : parseFloat(laraData?.coach?.listen_score, 10) * 20} radius={70} color={"#62C1EB"} textSize={"40px"} fillStopColor={"#62C1EB"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Listen
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.coach ? (
                          !showAreasOfImprovement ? (
                            laraData?.coach?.listen_report
                            ) : (
                            laraData?.coach?.listen_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.coach?.affirm_score)) ? "-" : parseFloat(laraData?.coach?.affirm_score, 10) * 20} radius={70} color={"#568FF3"} textSize={"40px"} fillStopColor={"#568FF3"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Affirm
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.coach ? (
                          !showAreasOfImprovement ? (
                            laraData?.coach?.affirm_report
                            ) : (
                            laraData?.coach?.affirm_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.coach?.respond_score)) ? "-" : parseFloat(laraData.coach.respond_score, 10) * 20} radius={70} color={"#e383eb"} textSize={"40px"} fillStopColor={"#e383eb"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Respond
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.coach ? (
                          !showAreasOfImprovement ? (
                            laraData?.coach?.respond_report
                            ) : (
                            laraData?.coach?.respond_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: "100%", md: "20%" }
                      }}
                    >
                      <Pie score={isNaN(parseFloat(laraData?.coach?.ask_score, 10)) ? "-" : parseFloat(laraData?.coach?.ask_score, 10) * 20} radius={70} color={"#8e33b8"} textSize={"40px"} fillStopColor={"#8e33b8"} />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontFamily: "Lexend",
                          fontWeight: 600
                        }}
                      >
                        Ask
                      </Typography>
                      <Typography
                        sx={{
                          color: "#202226",
                          fontSize: "14px",
                        }}
                      >
                        {laraData?.coach ? (
                          !showAreasOfImprovement ? (
                            laraData?.coach?.ask_report
                            ) : (
                            laraData?.coach?.ask_area_of_improvement
                            )
                          ) : (
                            "N/A"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default LaraSessionPage;
