import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";

export const getCallsByClientID = createAsyncThunk(
  "coach/getCallsByClientID",
  async ({ clientId }) => {
    const response = await API.get("zoomapi", "/zoom/fetch-coach", {
      queryStringParameters: {
        client_id: clientId,
      },
    });
    return response.data;
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState: { data: null, status: "idle", error: null },
  reducers: {
    setCallsByClientID: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCallsByClientID.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCallsByClientID.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getCallsByClientID.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setCallsByClientID } = clientSlice.actions;
export default clientSlice.reducer;
