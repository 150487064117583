import { useState, useCallback } from 'react';
import { API } from 'aws-amplify';
import { fetchStripeCheckout } from 'graphql/queries';

export const useStripeCheckoutVirtualCoach = () => {
  const [productId, setProductIdVirtualCoach] = useState("price_1OXaQyLKxpQPkYt5x7DAHQl0");
  const [loadingStripeCheckoutVirtualCoach, setLoadingStripeCheckoutVirtualCoach] = useState(false);
  const [errorWithStripeCheckoutVirtualCoach, setErrorWithStripeCheckoutVirtualCoach] = useState(null);

  const handleStripeCheckoutVirtualCoach = useCallback(async () => {
    setLoadingStripeCheckoutVirtualCoach(true);
    const stringifiedInput = JSON.stringify({ selected_product_id: productId });
    try {
      const response = await API.graphql({
        query: fetchStripeCheckout,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: stringifiedInput,
        },
      });
      const { sessionId } = JSON.parse(response.data.fetchStripeCheckout);
      window.location.href = sessionId; // Redirect to Stripe Checkout
    } catch (error) {
      console.error("Error running AlterCall checkout session:", error);
      setErrorWithStripeCheckoutVirtualCoach(error);
    } finally {
      setLoadingStripeCheckoutVirtualCoach(false);
    }
  }, [productId]);

  return { handleStripeCheckoutVirtualCoach, setProductIdVirtualCoach, loadingStripeCheckoutVirtualCoach, errorWithStripeCheckoutVirtualCoach };
};
