/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://eaz5mxbkq5g2re2v3kfq4xi3q4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-x7t34kzp35cenatvgwhzp6u3nm",
    "aws_cloud_logic_custom": [
        {
            "name": "zoomapi",
            "endpoint": "https://r8gmcdo2d7.execute-api.us-east-1.amazonaws.com/vitucoach",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:79b15138-cdec-436a-8f7f-925fc5dd2be0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_31fzUX4dL",
    "aws_user_pools_web_client_id": "48n0jla0k4ksskt822ndarbrel",
    "oauth": {
        "domain": "aaibetaapp16aec132-16aec132-vitucoach.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,http://localhost:3000/authenticating/,https://altercall.ai/authenticating/,https://altercall.ai/",
        "redirectSignOut": "http://localhost:3000/,https://altercall.ai/,http://localhost:3000/signin/,https://altercall.ai/signin/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "acprofilepictures205648-vitucoach",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
