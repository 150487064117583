import React from "react";

function Actions({ color, size = 24 }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_342_3706)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.08272 26.139L9.98175 17.4851H2.75616C2.12652 17.4851 1.77369 16.7389 2.16248 16.2296L13.5597 1.29925C14.0279 0.68599 14.9845 1.08583 14.9039 1.86106L14.0055 10.4996H21.2438C21.8735 10.4996 22.2263 11.2457 21.8375 11.755L10.4269 26.7008C9.95873 27.314 9.00219 26.9142 9.08272 26.139ZM13.1233 4.385L12.3259 12.0519H19.6917L10.863 23.6158L11.6611 15.9327H4.3082L13.1233 4.385Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_342_3706">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Actions;
