import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, useFormik } from "formik";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import SessionPage from "scenes/SessionPage/SessionPage";
import ClientLimitReached from "components/ClientLimitReachedModal";

const CoachSessionPage = ({ user, userData }) => {


  return (
    <>

        <GlobalPageComponent user={user} accessTo={userData.accessTo}>
          <ClientLimitReached user={user} userData={userData}/>
          <SessionPage user={user} isProfessionalTier={userData.accessTo === 'platform_professional'} userData={userData}/>

        </GlobalPageComponent>
      
    </>
  );
};

export default CoachSessionPage;
