import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { signIn } from "slices/UserSlice";
import { Box } from "@mui/system";

const VerifyUser = ({ email, password }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);

  const sendCodeAgain = async () => {
    try {
      setLoading(true);
      await Auth.resendSignUp(email);
      setResent(true);
      toast.success("Verification code sent successfully");
      setLoading(false);
    } catch (err) {
      console.log("Error in sending code again", err);
      toast.error(err.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await Auth.confirmSignUp(email, verificationCode);
      //user verfiied successfully
      // localStorage.setItem("authRole", 1);
      await dispatch(signIn({ username: email, password })).unwrap();
      setSubmitting(false);
      //navigate to signin page upon succcess
      toast.success("User verified successfully.");
      window.location.reload();
    } catch (error) {
      console.log("Error in verify", error);
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
      toast.error(error.message);
    }
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        gap="20px"
        width="300px"
      >
        <TextField
          required
          fullWidth
          id="verificationCode"
          label="Verification Code"
          name="verificationCode"
          autoComplete="verification-code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          InputProps={{
            style: {
              color: "black",
            },
          }}
          InputLabelProps={{
            style: {
              color: "black",
            },
          }}
          sx={{
            "& fieldset": {
              borderColor: "black !important",
            },
          }}
        />
        <Button
          disabled={submitting}
          onClick={handleSubmit}
          sx={{
            display: "block",
            fontSize: "20px",
            fontWeight: 500,
            borderRadius: "10px",
            backdropFilter: "blur(25px)",
            textTransform: "none",
            padding: "12px 54px",
            color: "white",
            width: "100%",
            background:
              "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
            ":hover": {
              color: "black",
              background: "white",
            },
          }}
        >
          Verify
        </Button>
        <Box display="flex" alignItems="center" gap="5px">
          <span style={{ color: "black" }}>Didn't receive code?</span>
          <Box
            component="span"
            onClick={sendCodeAgain}
            sx={{
              textDecoration: "underline",
              color: "black",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Send again
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerifyUser;
