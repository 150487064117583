import { Box, Button, CircularProgress, Divider, Tooltip, Typography, tooltipClasses } from "@mui/material";
import ArrowForwardBlack from "components/Icons/ArrowForwardBlack.svg";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Error } from "@mui/icons-material";


const WelcomeCard = ({ loading, zoomStatus, user, coachCallData, lastFileName, handleOpen, modal, unprocessedCallsSum, unassignedCallsSum, totalActionsSum, accessTo }) => {
    const navigate = useNavigate();


    return(
        <Box
            sx={{
            width: "100%",
            height: "100%",
            borderRadius: "40px",
            padding: "30px",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            background:
                "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
            }}
        >
            {loading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    }}
                >
                 <CircularProgress color="inherit" />
               </Box>
            )}
            {!loading && (
                <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  zIndex: 1,
                  width: {
                    xs: "100%",
                    md: "100%",
                  },
                }}
              >
                <Typography
                    sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                    xs: "24px"   
                    },
                    fontWeight: 300,
                    color: "white",
                    marginBottom: "8px",
                    lineHeight: "1.2",
                }}
                >
                {zoomStatus && `Welcome back ${user?.attributes.given_name}!`}
                {!zoomStatus && `Welcome ${user?.attributes.given_name}!`}
                </Typography>
                <Typography
                    sx={{
                    color: "white",
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginBottom: "8px",
                    lineHeight: "1.2",
                }}
                >
                {zoomStatus &&
                    "Here’s what has happened in your recent activity!"}
                {!zoomStatus &&
                    "Finish setting up your account by logging into zoom using the button below"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    paddingY: "20px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px"
                    }}
                  >
                    <Typography
                      sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "56px",
                      fontWeight: 300,
                      lineHeight: "normal",
                      padding: "0px"
                  }}
                  >
                    {unprocessedCallsSum ?? 0}
                  </Typography>
                    <Box
                      display="flex"
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontFamily: "Lexend",
                          fontSize: "14px",
                          fontWeight: 700
                        }}
                      >
                        Available to Generate
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                    <Typography
                      sx={{
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "56px",
                      fontWeight: 300,
                      lineHeight: "normal",
                      padding: "0px"
                  }}
                  >
                    {unassignedCallsSum ?? 0}
                  </Typography>
                    <Box
                      display="flex"
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontFamily: "Lexend",
                          fontSize: "14px",
                          fontWeight: 700
                        }}
                      >
                        Available to Share
                      </Typography>
                    </Box>
                  </Box>

                </Box>
                {zoomStatus && (
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "auto",
                          }}
                        >
                          <Tooltip
                            title={
                              !lastFileName
                                ? "Please process a call to review it"
                                : ""
                            }
                          >
                            <Button
                              sx={{
                                display: " flex",
                                width: {
                                  xs: "100%",
                                  md: "220px",
                                },
                                padding: "10px 8px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: " 10px",
                                transition: "all 0.3s",
                                borderRadius: "52px",
                                border: "1px solid black",
                                marginBottom: "-15px",
                                borderColor:
                                  lastFileName === ""
                                    ? "rgba(255, 255, 255, 0.18)"
                                    : "#578FF2",
                                background: "white",
                                opacity:
                                  !lastFileName === ""
                                    ? 0.3
                                    : 1,
                                "&:hover": {
                                  cursor:
                                    !lastFileName === ""
                                      ? "not-allowed"
                                      : "pointer",
                                  background:
                                    !lastFileName === ""
                                      ? "rgba(255, 255, 255, 0.18)"
                                      : "black",
                                  borderColor: "black",
                                },
                                "&:hover *": {
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                if (lastFileName !== "")
                                  navigate("/platform/coach/session/" + lastFileName);
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  color: "black",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  letterSpacing: "2px",
                                  lineHeight: "1.5",
                                  textTransform: "uppercase",
                                }}
                              >
                                Review latest call
                              </Typography>
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                      {!zoomStatus && (
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "auto",
                          }}
                        >
                        <Button
                        sx={{
                          display: " flex",
                          width: {
                            xs: "100%",
                            md: "220px",
                          },
                          padding: "10px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: " 10px",
                          borderRadius: "52px",
                          border: "1px solid #578FF2",
                          borderColor: "#578FF2",
                          background: "white",
                          marginTop: "15px",
                          opacity: 1,
                          transition: "all 0.3s",
                          marginBottom: "-15px",
                          "&:hover": {
                            cursor: "pointer",
                            background: "black",
                            borderColor: "black",
                          },
                          "&:hover *": {
                            color: "white",
                          },
                        }}
                          onClick={handleOpen}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              color: "black",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              letterSpacing: "2px",
                              lineHeight: "1.5",
                              textTransform: "uppercase",
                            }}
                          >
                            Connect to Zoom
                          </Typography>
                        </Button>
                        </Box>
                      )}
              </Box>
            )}
            {modal}
        </Box>
    )

}

export default WelcomeCard;