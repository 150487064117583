import { Button, Grid, TextField, Box, Typography, CircularProgress } from "@mui/material"
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { signIn } from "slices/UserSlice";
import { useNavigate } from "react-router-dom";

const VerifyCode = ({ email, password, setShowVerifyCode }) => {
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);
  const navigate = useNavigate()
  const verifyCode = async () => {

    try {
        setLoading(true)
        await Auth.confirmSignUp(email, verificationCode);
        const response = await dispatch(
          signIn({ username: email, password: password })
        ).unwrap();
        if(response){
          navigate("/platform/coachee")
        }
        setLoading(false)
        setShowVerifyCode(false);
    } catch (err) {
      console.log("Error in verify code", err)
      toast.error(err.message)
      setLoading(false)
    }
  }
  const sendCodeAgain = async () => {
    try {
      setLoading(true)
      await Auth.resendSignUp(email);
      setResent(true);
      toast.success("Verification code sent successfully")
      setLoading(false)
    } catch (err) {
      console.log("Error in sending code again", err)
      toast.error(err.message)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} display="flex" flexDirection="column" gap='20px' width="300px">
        <Typography
            sx={{
              fontSize: "48px",
              lineHeight: "1.17",
              letterSpacing: "-0.02em",
              color: "#22272F",
              justifyContent: "center"
            }}
          >
            Verify Code
          </Typography>
        <Box display="flex" flexDirection="column" gap='10px' width="300px">
          <TextField
            fullWidth
            id="verificationCode"
            name="verificationCode"
            autoComplete="verification-code"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            InputProps={{
              style: {
                color: "black",
              },
            }}
            InputLabelProps={{
              style: {
                color: "black",
              },
            }}
            sx={{
              color: "#22272F",
              background: "white",
              borderRadius: "16px",
              width: "100%",
              border: "1px solid #C4CAD4",
              "&:before, &:after": {
                display: "none",
              },
              "& fieldset": {
                border: "none !important",
              },
            }}
          />
          <Box display="flex" alignItems="center" gap="5px">
            <span style={{ color: "black" }}>Didn't receive code?</span>
            <Box component="span" onClick={sendCodeAgain} sx={{
              textDecoration: "underline",
              color: "black",
              "&:hover": {
                cursor: "pointer"
              }
            }}>Send again</Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          sx={{ 
            width: "100%",
            padding: "18px 0",
            background: "white",
            border: "1px solid black",
            borderRadius: "43px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            boxShadow: "none",
            transition: "all 0.3s",
            "&:hover": {
              background: "black",
              boxShadow: "none",
              "& *": {
                color: "white",
              },
            },
          "&:disabled": {
            background: "#d3d3d3", 
            color: "rgba(0, 0, 0, 0.26)",
            border: "none",
            cursor: "not-allowed"
          },
          marginLeft: "10px"
        }}
          onClick={verifyCode}
          disabled={loading && resent}
        >
          {loading ? (
            <CircularProgress size={20} sx={{marginRight: "5px", marginBottom: "-3px", color: "black"}} /> 
          ) : (
            <Typography
            sx={{
              fontFamily: "Montserrat",
              color: "black",
              fontSize: "12px",
              fontWeight: "600",
              letterSpacing: "0.4px",
              textTransform: "uppercase",
            }}
          >

            Verify
          </Typography>
          )}

        </Button>
      </Grid>
    </Grid>
  )
}
export default VerifyCode