import { useEffect, useState } from "react";
import { Box, Typography, Input, Button, CircularProgress, TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import googleImg from "assets/google.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { signIn } from "slices/UserSlice";
import { setUser } from "slices/UserSlice";
import { API, Auth } from "aws-amplify";
import Lottie from "react-lottie";
import * as animationData from "data/dashboardAnimation.json";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "react-toastify/dist/ReactToastify.css";


export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
  headerImage: "",
};

export const theme = createTheme(themeOptions);

const ClientAcceptLoginForm = ({userLoggedIn, setUserLoggedIn}) => {
  const navigate = useNavigate(); // Get the navigate function from React Router
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loggingInUser, setLoggingInUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [token_data, setTokenData] = useState({
    email: "",
    coach_id: "",
    firstName: "",
    lastName: "",
  });
  const [isVerified, setIsVerified] = useState(false);
  const [info, setInfo] = useState("");


  const handleFormSubmit = async (values) => {
    const { email, coach_id, firstName, lastName } = token_data;
    try {
      setLoggingInUser(true);
      const username = values.email;
      const password = values.password;
      await Auth.signIn(username, password);
      setUserLoggedIn(true)
    } catch (err) {
      if (err.message === "User is not confirmed.") {
        setLoggingInUser(false);
        toast.error(err.message, {
          hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
        });
      }
    } finally {
      setLoggingInUser(false);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await API.get("zoomapi", "/zoom/verify-token", {
        queryStringParameters: {
          token: queryParams.get("token"),
        },
      });
      if (response?.decoded) {
        const {
          client_email,
          coach_id,
          info,
          client_first_name,
          client_last_name,
        } = response.decoded;
        setInfo(info);
        setTokenData({
          email: client_email,
          coach_id: coach_id,
          firstName: client_first_name,
          lastName: client_last_name,
        });
        setIsVerified(true);
      }
    })();
  }, []);


  return (
    <>
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            flex: {
              md: "50%",
            },
            marginLeft: {md: "40px"}
          }}
        >
          <Formik
              onSubmit={handleFormSubmit}
              initialValues={{ email: "", password: "" }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                submitForm,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
                  <Box
                    color="black"
                    position={"relative"}
                    zIndex={1}
                    maxWidth="400px"
                  >
                    <Box
                      sx={{
                        marginBottom:"20px"
                      }}
                    >
                    <Typography
                       sx={{
                        fontSize: "48px",
                        lineHeight: "1.17",
                        letterSpacing: "-0.02em",
                        color: "#22272F",
                      }}
                    >
                      Sign In
                    </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        marginBottom: "16px"
                      }}
                    >
                      <InputLabel
                        htmlFor="component-helper"
                        sx={{
                          color: "#454F5F",
                          fontSize: "12px",
                          lineHeight: "1.33",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        Email
                      </InputLabel>
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                      <TextField
                        name="email"
                        type="email"
                        required
                        onChange={handleChange}
                        value={values.email}
                        handleBlur={handleBlur}
                        error={touched.email && errors.email}
                        InputProps={{
                          style: {
                            color: "#22272F",
                            outline: "none"
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#454F5F",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                        placeholder="Enter your Email"
                      />
                      {touched.email && errors.email && (
                        <Typography
                          variant="caption"
                          color="error"
                        >
                          {errors.email}
                        </Typography>
                      )}
                      </Box>
                    </Box>
                     
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                    <InputLabel
                      htmlFor="component-helper"
                      sx={{
                        color: "#454F5F",
                        fontSize: "12px",
                        lineHeight: "1.33",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      Password
                    </InputLabel>
                    <Box
                        sx={{
                          position: "relative"
                        }}
                    >
                      <TextField
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        error={touched.password && errors.password}
                        value={values.password}
                        InputProps={{
                          style: {
                            color: "#22272F",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#454F5F",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                       {touched.password && errors.password && (
                          <Typography
                            variant="caption"
                            color="error"
                          >
                            {errors.password}
                          </Typography>
                        )}
                        {showPassword === true ? (
                          <VisibilityOffIcon
                            color="#454F5F"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: "16px",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPassword(false);
                              const passwordElement =
                                document.getElementById("password");
                              passwordElement.setAttribute(
                                "type",
                                "password"
                              );
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            color="#454F5F"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: "16px",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPassword(true);
                              const passwordElement =
                                document.getElementById("password");
                              passwordElement.setAttribute(
                                "type",
                                "text"
                              );
                            }}
                          />
                        )}
                    </Box>
                  </Box>

                    <Button
                      sx={{
                        marginTop: "32px",
                        width: "100%",
                        padding: "18px 0",
                        background: "white",
                        border: "1px solid black",
                        borderRadius: "43px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        transition: "all 0.3s",
                        "&:hover": {
                          background: "black",
                          "& *": {
                            color: "white",
                          },
                        },
                        "&:disabled": {
                          background: "#d3d3d3", 
                          color: "rgba(0, 0, 0, 0.26)",
                          border: "none",
                          cursor: "not-allowed"
                        },
                      }}
                      disabled={isSubmitting || !!errors.email || !!errors.password || !values.email || !values.password}
                      onClick={submitForm}
                    >
                      {loggingInUser ? (
                        <CircularProgress size={20} sx={{marginRight: "5px", marginBottom: "-3px", color: "black"}} /> 
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "600",
                            letterSpacing: "0.4px",
                            textTransform: "uppercase",
                          }}
                        >
                          Sign In
                        </Typography>
                      )}

                    </Button>
                    <Button
                      sx={{
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "600",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "32px",
                        borderRadius: "40px",
                        border: "1px solid black",
                        backdropFilter: "blur(25px)",
                        textTransform: "none",
                        color: "black",
                        background: "white",
                        transition: "all 0.4s",
                        width: "100%",
                        padding: "8px",
                        marginBottom: "24px",
                      }}
                      onClick={async () => {
                        try {
                          await Auth.federatedSignIn({ provider: "Google" });
                        } catch (error) {
                          toast.error("User is not registered");
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: "38px",
                          height: "38px",
                          backgroundImage: `url(${googleImg})`,
                          color: "#"
                        }}
                      />
                      Continue with Google
                    </Button>
                    <Box
                      fontSize={"14px"}
                      fontWeight={400}
                      marginTop={"25px"}
                      width={"100%"}
                      textAlign={"center"}
                    >
                      <Typography component={"span"}>
                        Don’t Have An Account? &nbsp;
                      </Typography>
                      <Typography
                        onClick={() => navigate("/signup")}
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        component={"span"}
                      >
                        Create Account
                      </Typography>
                    </Box>
                    <Box
                      width={"100%"}
                      fontSize={"14px"}
                      fontWeight={400}
                      textAlign={"center"}
                      marginTop="8px"
                    >
                      <Typography component={"span"}>Forgot Password?&nbsp;</Typography>
                      <Typography
                        component={"span"}
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => navigate("/resetpassword")}
                      >
                        Reset Password
                      </Typography>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
            flex: {
              lg: "50%",
            },
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width="100%"
            height="auto"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Box>
      </ThemeProvider>
    </>
  );
};

export default ClientAcceptLoginForm;
